import React, {useEffect} from "react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import MaterialTable from 'material-table';
import Alert from "react-s-alert";
import {primaryColor, secondaryColor} from "../../../assets/jss/material-dashboard-react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Checkbox,
    DialogTitle,
    FormControlLabel,
    TextField
} from "@material-ui/core";
import DataGrid, {
    Column, Export, FilterRow,
    GroupPanel, HeaderFilter,
    Pager, Paging, Editing,
    SearchPanel, Sorting
  } from "devextreme-react/data-grid";
import esMessages from 'devextreme/localization/messages/es.json';
import { locale, loadMessages } from 'devextreme/localization';
import QueueAnim from "rc-queue-anim";
import {TraceSpinner} from "react-spinners-kit";
import {addDepartment, getAllDepartmets, updateDepartment} from "../../../util/Contexts/DepartmentContext";
import {handleError} from "../../../util/Contexts/ErrorContext";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: primaryColor,
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const useStyles = makeStyles(styles);

export default function Departments() {

    const classes = useStyles();
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    const [policySystem, setPolicySystem] = React.useState(false);
    const [policySupport, setPolicySupport] = React.useState(false);
    const [policyupgrade, setPolicyUpgrade] = React.useState(false);

    const [open, setOpen] = React.useState(false);
    const [state, setState] = React.useState({
        stateSelected: 0,
        filter: "",
        name: "",
        description: "",
        enabled: true,
        systemPolicy: false,
        supportPolicy: false,
        upgradePolicy: false,
        edit: true
    });

    loadMessages(esMessages);
    locale("es");

    // const handleChangeCheckboxPolicySupport = (event: React.ChangeEvent<HTMLInputElement>) => {
    //   setPolicySupport(event.target.checked);
    // }
    // const handleChangeCheckboxPolicySystem = (event: React.ChangeEvent<HTMLInputElement>) => {
    //   setPolicySystem(event.target.checked);
    // }
    // const handleChangeCheckboxPolicyUpgrade = (event: React.ChangeEvent<HTMLInputElement>) => {
    //   setPolicyUpgrade(event.target.checked);
    // }

    const handleCheckboxChange = () => event => {
      setState({
          ...state,
          [event.target.name]: event.target.checked,
      })
  }

    function handleClickOpen(row) {
      var item = null;
      console.log(row.row)
      if(row.row != null){
        for(let i = 0; i < data.length; i++){
          if(row.row.data.id === data[i].id){
            item = data[i]
          }
        }
      }
        item ? setState({
            ...state,
            name: item.name,
            id: item.id,
            description: item.description,
            enabled: item.enabled,
            systemPolicy: item.systemPolicy,
            supportPolicy: item.supportPolicy,
            upgradePolicy: item.upgradePolicy,
            edit: true
        }) :  setState({
            ...state,
            name: "",
            description: "",
            enabled: true,
            systemPolicy: false,
            supportPolicy: false,
            upgradePolicy: false,
            edit: false
        });
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    const handleChange = event => {
        setState( {
            ...state,
            [event.target.name] : event.target.value
        });
    };

    function enable(rowData) {
      rowData.row.data.enabled = !rowData.row.data.enabled
      updateDepartment(rowData.row.data.id, rowData.row.data).then(respuesta => {
        Alert.success(respuesta.data.message);
        getAll();
      } ).catch(error => {
        handleError(error);
      });
    }

    const updateData = (event) => {
      event.preventDefault();
      let newData = {
        name: state.name,
        description: state.description,
        enabled: state.enabled,
        systemPolicy: state.systemPolicy,
        supportPolicy: state.supportPolicy,
        upgradePolicy: state.upgradePolicy,
      }
        updateDepartment(state.id, newData).then(respuesta => {
            Alert.success(respuesta.data.message);
            handleClose();
            getAll();
        } ).catch(error => {
            handleError(error);
        });
    };

    const getAll = async () => {
        setLoading(true);
        getAllDepartmets().then(respuesta => {
            setLoading(false);
            setData(respuesta.data.data);
        } ).catch(error => {
            setLoading(false);
            handleError(error);
        });
    };

    useEffect(value => {
        getAll();
    }, []);

    const addData = (event) => {
        event.preventDefault();
        console.log(state);
        addDepartment(state).then(respuesta => {
          if(respuesta.data.success){
            Alert.success(respuesta.data.message);
          } else {
            Alert.error(respuesta.data.message);
          }
            setState({
                ...state,
                name: "",
                description: ""
            });
            handleClose();
            getAll();
        } ).catch(error => {
            handleError(error);
        });
    };

    const renderStatus = (cellInfo) => {
      if(cellInfo.data.enabled){
        return(
          <div align={"center"}>
            Activo
          </div>
          );
      } else {
        return(
          <div align={"center"}>
            Inactivo
          </div>
          );
      }
    }

    function onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'plus',
          onClick: handleClickOpen.bind()
        }
      });
    }

    return (
      <div>
        {
          loading ? (
            <div style={{
              position: 'absolute', left: '50%', top: '50%',
              transform: 'translate(-50%, -50%)'
            }}>
              <TraceSpinner
                size={80}
                frontColor={primaryColor[0]}
                backColor={secondaryColor[0]}
                loading={loading}/>
            </div>
          ) : (
            <GridContainer>
              <Dialog open={open} onClose={handleClose}>
                <DialogTitle id="form-dialog-title">{state.edit? "Editar Área" : "Agregar Área"}</DialogTitle>
                <form onSubmit={state.edit? updateData: addData}>
                  <DialogContent>
                    <TextField
                      required={true}
                      autoFocus
                      margin="dense"
                      id="name"
                      name="name"
                      value={state.name}
                      onChange={handleChange}
                      label="Nombre"
                      type="text"
                      fullWidth
                    />
                    <TextField
                      autoFocus
                      margin="dense"
                      id="description"
                      name="description"
                      value={state.description}
                      onChange={handleChange}
                      label="Descripción"
                      type="text"
                      fullWidth
                    />
                    <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state.upgradePolicy}
                                    onChange={handleCheckboxChange()}
                                    name="upgradePolicy"
                                    color="primary"
                                />
                            }
                            label="Póliza de Actualización"
                        />
                    <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state.systemPolicy}
                                    onChange={handleCheckboxChange()}
                                    name="systemPolicy"
                                    color="primary"
                                />
                            }
                            label="Póliza de Sistemas"
                        />
                    <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state.supportPolicy}
                                    onChange={handleCheckboxChange()}
                                    name="supportPolicy"
                                    color="primary"
                                />
                            }
                            label="Póliza de Soporte"
                        />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                      Cancelar
                    </Button>
                    <Button type={"submit"} color="primary">
                      Guardar
                    </Button>
                  </DialogActions>
                </form>
              </Dialog>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="secondary">
                    <h4 className={classes.cardTitleWhite}>Listado de áreas</h4>
                    <p className={classes.cardCategoryWhite}>

                    </p>
                  </CardHeader>
                  <CardBody>
                  <QueueAnim>
                    <DataGrid
                      dataSource={data}
                      showBorders={true}
                      key="id"
                      wordWrapEnabled={true}
                      allowColumnReordering={true}
                      allowColumnResizing={true}
                      onToolbarPreparing={onToolbarPreparing}>

                      {/* <Export enabled={true} fileName={"Administradores"} texts={{exportAll: "Exportar todos lo datos"}}/>   */}
                            
                      <FilterRow visible={true} applyFilter={"auto"} />
                            
                      <HeaderFilter visible={true} />
                            
                      <GroupPanel visible={true} emptyPanelText={"Arrastre una columna para agrupar"} />
                            
                      <SearchPanel visible={true}
                        width={240}
                        placeholder="Buscar..." />
                            
                      <Paging defaultPageSize={10} />
                            
                      <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 20]}
                        showInfo={true} />
                              
                      <Sorting mode="multiple" />

                      <Column
                        dataField="name"
                        caption="Nombre"/>

                      <Column
                        dataField="description"
                        caption="Descripción"/>

                      <Column
                        dataField="enabled"
                        cellRender={renderStatus}
                        caption="Estatus"/>

                      <Column type="buttons"
                        caption="Acciones"
                        width={80}
                        buttons={[
                          {
                            icon: 'isblank',
                            onClick: enable
                          }
                          // {
                          //   hint: 'Editar',
                          //   icon: 'edit',
                          //   onClick: handleClickOpen
                          // }
                        ]}/>
                    </DataGrid>
                  </QueueAnim>
                                {/* <MaterialTable
                                    columns={[
                                        { title: 'Nombre', field: 'name' },
                                        { title: 'Descripción', field: 'description' },
                                        { title: 'Estatus', field: 'enabled', render: rowData => rowData.enabled ? "Activo" : "Inactivo", editable: 'never' }

                                    ]}
                                    data={data}
                                    title=""
                                    localization={{
                                        body: {
                                            emptyDataSourceMessage: "No se encontraron área",
                                            editRow: {
                                                saveTooltip: "Guardar",
                                                cancelTooltip: "Cancelar"
                                            },
                                            editTooltip: "Editar"
                                        },
                                        header: {
                                            actions: "Acciones"
                                        },
                                        toolbar:{
                                            searchPlaceholder:"Buscar"
                                        },
                                        pagination: {
                                            firstTooltip: "Primera página",
                                            firstAriaLabel: "Primera página",
                                            previousTooltip: "Página anterior",
                                            previousAriaLabel: "Página anterior",
                                            nextTooltip: "Siguiente página",
                                            nextAriaLabel: "Siguiente página",
                                            labelDisplayedRows: "{from}-{to} de {count}",
                                            labelRowsPerPage: "Registros por página",
                                            lastTooltip: "Última página",
                                            lastAriaLabel: "Última página",
                                            labelRowsSelect: "Registros por página",
                                        }

                                    }}

                                    editable={{
                                        onRowUpdate: (newData, oldData) =>
                                            new Promise(resolve => {
                                                updateData(oldData.id, newData);
                                                resolve();
                                            })
                                    }}
                                    actions={[
                                        rowData =>  (rowData.enabled ?
                                                ({
                                                    icon: 'visibility_off',
                                                    tooltip: 'Deshabilitar',
                                                    onClick: (event, rowData) => disable(rowData.id, rowData)
                                                }) :
                                                ({
                                                    icon: 'visibility',
                                                    tooltip: 'Habilitar',
                                                    onClick: (event, rowData) => enable(rowData.id, rowData)
                                                })
                                        ),
                                        {
                                            icon: 'add',
                                            tooltip: 'Agregar área',
                                            isFreeAction: true,
                                            onClick: (event) => handleClickOpen()
                                        }
                                    ]}

                                    options={{
                                        actionsColumnIndex: -1,
                                        sorting: true,
                                        showTitle: true,
                                    }}

                                /> */}
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>

            )
        }

        </div>


    );
}