import React, { Component } from 'react';
import {
    Route,
    Switch,
    Redirect
} from 'react-router-dom';

import Login from './views/user/login/Login';
import SendEmailForPassword from './views/user/resetPassword/SendEmail';
import RecoverPassword from './views/user/resetPassword/RecoverPassword';
import NotFound from '../common/NotFound';
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import './App.css';
import {AplicationConsumer, AplicationProvider} from "../util/Contexts/AplicationContext";
import Admin from "./layouts/Admin";
import {ACCESS_TOKEN} from "../assets/constants";
import esMessages from "devextreme/localization/messages/es.json";
import { locale, loadMessages } from "devextreme/localization";
import {hasRole} from "../util/Contexts/UserService";

class App extends Component {
  constructor(props) {
    super(props);
    this.dataSource = [{
        'ticketId': '1',
        'name': 'Alonzo',
        'lastName': 'Kuang'
    }];
    this.state = {
    };
      loadMessages(esMessages);
      locale("es");
  }


    render() {
    return (

        <AplicationProvider>

            <AplicationConsumer>{(value) => {
                return(
        
                    localStorage.getItem(ACCESS_TOKEN) ? (
                        <Switch>
                            <Route path="/admin" component={Admin} />
                            {
                                hasRole("CORPORATIVE") ? (
                                    <Redirect push from="/" to="/admin/request"/>
                                ) : (
                                    <Redirect push from="/" to="/admin/dashboard"/>
                                )

                            }

                            <Route component={NotFound}></Route>
                        </Switch>
                        ) : (
                            <div className="app">
                                {/*<div className="app-top-box">*/}
                                {/*    <AppHeader />*/}
                                {/*</div>*/}
                                <div className="app-body">
                                    <Switch>
                                    <Route exact path="/" component={Login} ></Route>
                                    <Route path="/login" component={Login} ></Route>
                                    <Route path="/sendEmail" component={SendEmailForPassword}></Route>
                                    <Route path="/recover-password/:token" component={RecoverPassword}></Route>
                                    {/*<Route path="/signup" component={Signup}></Route>*/}
                                    {/*<Route path="/oauth2/redirect" component={OAuth2RedirectHandler}></Route>*/}
                                    <Redirect push from="/**" to="/login"/>
                                    </Switch>
                                </div>
                            </div>
                    ));}}
            </AplicationConsumer>
        
        
        
            <Alert stack={{limit: 3}}
                   timeout = {4000}
                   position='top-right' effect='slide' offset={65} />
        </AplicationProvider>
    );
  }
}

export default App;
