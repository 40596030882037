import React from 'react';
import MessageList from '../MessageList';
import './Messenger.css';

export default function Messenger({mesagges, sendAction, idAdmin, studentView}) {
    return (
      <div className="messenger">
        <div className="scrollable content">
           <MessageList messagesList={mesagges} sendAction={sendAction} idOwner={idAdmin} studentView={studentView}/>

        </div>
      </div>
    );
}