import firebase from 'firebase';
import {DEVICE_ID} from "./assets/constants";

export const initializeFirebase = () => {
    firebase.initializeApp({
        apiKey: "AIzaSyDBsJytlpdeBiVnjuJok9nDX0EZzdzT_HA",
        authDomain: "sw-certuit-base.firebaseapp.com",
        databaseURL: "https://sw-certuit-base.firebaseio.com",
        projectId: "sw-certuit-base",
        storageBucket: "",
        messagingSenderId: "999772197752",
        appId: "1:999772197752:web:1d2518f8e3a2994e43ae8d",
        measurementId: "G-ZZJVNW23SZ"
    });

};

export const askForPermissioToReceiveNotifications = async () => {
    try {
        const messaging = firebase.messaging();
        await Notification.requestPermission();
        const token = await messaging.getToken();
        localStorage.setItem(DEVICE_ID,token);

        return token;
    } catch (error) {
        console.error(error);
    }
};

