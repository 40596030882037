import {
    API_AUTENTICATION_HEADERS,
    API_BASE_URL,
    API_BASIC_HEADERS,
    API_MULTIPART_HEADERS
} from "../../assets/constants";
import axios from "axios";

const headers = API_AUTENTICATION_HEADERS;
const headersBasic = API_MULTIPART_HEADERS;

function getAllPrioritys() {
    const url = API_BASE_URL + "/api/prioritys";
    return axios.get(url, {headers});
}

function updatePriority (state) {
    const url = API_BASE_URL + "/api/priority/" + state.id;
    console.log(state)
    return axios.put(url,Object.assign({}, {name: state.name, description: state.description, lowHour: state.hourSilder[0], highHour: state.hourSilder[1], enabled: state.enabled, color: state.color, emails: state.emails}),{ headers } );
}

function enablePriority(state){
    const url = API_BASE_URL + "/api/priority/enable/" + state.id;
    console.log(state)
    var bodyFormData = new FormData();
    bodyFormData.append("enabled", state.enabled);
    return axios.put(url, bodyFormData, { headers } );
}

function addPriority (state) {
    const url = API_BASE_URL + "/api/priority";
    return axios.post(url, Object.assign({}, {name: state.name, description: state.description, lowHour: state.hourSilder[0], highHour: state.hourSilder[1], enabled: true, color: state.color, emails: state.emails})  ,{ headers } );
}

function getAllPrioritysEnabled (){
    const url = API_BASE_URL + "/api/prioritys/enabled";
    const headers = API_AUTENTICATION_HEADERS;
    return axios.get(url, {headers})
}

function changePriority (id, priority){
    var bodyFormData = new FormData();
    bodyFormData.append("priority", priority);
    const url = API_BASE_URL + "/api/ticket/" + id +"/priority";
    const headers = headersBasic;
    return axios.put(url, bodyFormData,{headers})
}

export {  getAllPrioritys, updatePriority, addPriority, getAllPrioritysEnabled, changePriority, enablePriority};