import {API_AUTENTICATION_HEADERS, API_BASE_URL} from "../../assets/constants";
import axios from "axios";
import {trackPromise} from "react-promise-tracker";

const headers = API_AUTENTICATION_HEADERS;

function getQuestions(){
    const url = API_BASE_URL + "/api/questions";
    let result;
    trackPromise(
        result = axios.get(url, {headers} )
    )
    return result;

}

function sendAnswers(id, data){
    const url = API_BASE_URL + "/api/qualify/ticket/" + id;
    let result;
    trackPromise(
        result = axios.post(url,  data, {headers} )
    )
    return result;

}

function getQualifyTickets () {
    const url = API_BASE_URL + "/api/qualified/tickets";
    let result;
    trackPromise(
        result = axios.get(url,{ headers } )
    )
    return result;
}


export {  getQuestions, sendAnswers, getQualifyTickets};