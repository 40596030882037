import React, {useEffect} from "react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import MaterialTable from 'material-table';
import axios from 'axios';
import Alert from "react-s-alert";
import { API_AUTENTICATION_HEADERS } from 'assets/constants';
import {API_BASE_URL} from "../../../../assets/constants";
import {primaryColor, secondaryColor} from "../../../../assets/jss/material-dashboard-react";
import {TraceSpinner} from "react-spinners-kit";
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {
    Button, CircularProgress, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl
} from "@material-ui/core";
import DataGrid, {
  Column, Export, FilterRow,
  GroupPanel, HeaderFilter,
  Pager, Paging, Editing,
  SearchPanel, Sorting
} from "devextreme-react/data-grid";
import QueueAnim from "rc-queue-anim";
import {handleError} from "../../../../util/Contexts/ErrorContext";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: primaryColor,
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const useStyles = makeStyles(styles);


export default function Users() {

    const classes = useStyles();
    const [data, setData] = React.useState([]);
    const [clientsSelect, setClientsSelect] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [loadingAutocomplete, setLoadingAutocomplete] = React.useState(false);
    const [options, setOptions] = React.useState([]);

    const [state, setState] = React.useState({
        id: 0,
        clients: []
    });

    const getAutocompleteSugestion = (query) => {
      setLoadingAutocomplete(true);
      if(!(/^\s*$/m.test(query))){
      const url = API_BASE_URL + "/api/user/clients?query=" + query ;
      const headers = API_AUTENTICATION_HEADERS;
      axios.get(url, { headers } ).then(respuesta => {
          setOptions(respuesta.data.data.clients);
          setLoadingAutocomplete(false);
      } ).catch(error => {
          setLoadingAutocomplete(false);
      });
      } else {
          setOptions(state.clients);
          setLoadingAutocomplete(false)
          return undefined;
      }

    };

    function handleClose() {
        setOptions([]);
        setClientsSelect([]);
        setOpen(false);
    }

    function handleOpen(rowData) {
        setState({
            ...state,
            id : rowData.row.data.id,
            clients : rowData.row.data.clients
        });
        setClientsSelect(rowData.row.data.clients);
        setOpen(true);
    }

    const handleFormChange = (event, value) => {
      setClientsSelect(value);
      setOptions([])
    };

    const setClientsUser = async () => {
      setLoading(true);
      const url = API_BASE_URL + "/api/user/" + state.id + "/clients";
      const headers = API_AUTENTICATION_HEADERS;
      if(clientsSelect != state.clients){
        axios.put(url, Object.assign({}, {clients: clientsSelect })  ,{ headers } ).then(respuesta => {
        Alert.success(respuesta.data.message);
          setOpen(false);
          getAllData();
      } ).catch(error => {
          handleError(error);
        });
      }
      setLoading(false);
      setOpen(false);
    };

    const enabledUser = async (enabled, id) => {
        const url = API_BASE_URL + "/api/user/" + id + "/enabled?enabled=" + enabled;
        const headers = API_AUTENTICATION_HEADERS;

        axios.put(url, {} ,{ headers } ).then(respuesta => {
            Alert.success(respuesta.data.message);
            getAllData();
        } ).catch(error => {
            handleError(error);
        });
    };

    const renderStatus = (cellInfo) => {
      if(cellInfo.data.enabled){
        return(
          <div align={"center"}>
            Activo
          </div>
          );
      } else {
        return(
          <div align={"center"}>
            Inactivo
          </div>
          );
      }
    }

    function enable(rowData) {
      enabledUser(!rowData.row.data.enabled, rowData.row.data.id)
    }


    const handleChange = event => {
        getAutocompleteSugestion(event.target.value);
    };

    const getAllData = async () => {
        setLoading(true);
        const url = API_BASE_URL + "/api/users/users";
        const headers = API_AUTENTICATION_HEADERS;
        axios.get(url, { headers } ).then(respuesta => {
            setData(respuesta.data.data);
            setLoading(false);
        } ).catch(error => {
            setLoading(false);
            handleError(error);
        });
    };

    useEffect(value => {

        getAllData();
    },[]);

    return (
      <div>
        {loading ? (
          <div style={{
            position: 'absolute', left: '50%', top: '50%',
            transform: 'translate(-50%, -50%)'
          }}>
            <TraceSpinner
              size={80}
              frontColor={primaryColor[0]}
              backColor={secondaryColor[0]}
              loading={loading}/>
          </div>
        ) : (
          <GridContainer>
            <Dialog open={open} onClose={handleClose} fullWidth={true} >
              <DialogTitle id="form-dialog-title">Agregar razón social</DialogTitle>
              <DialogContent >
                <FormControl required={true} fullWidth>
                  <Autocomplete
                    loading={loadingAutocomplete}
                    multiple
                    noOptionsText={"No se encontraron coincidencias"}
                    options={options}
                    loadingText={"Buscando..."}
                    onKeyUp={handleChange}
                    value={clientsSelect}
                    getOptionLabel={option => (option.fiscalName + " (" + (option.tradeName) + ")")}
                    renderTags={(value, { className, onDelete }) =>
                    value.map((option, index) => (
                      <Chip
                        key={index}
                        data-tag-index={index}
                        tabIndex={-1}
                        label={option.fiscalName}
                        className={className}
                        onDelete={onDelete}
                      />
                    ))
                    }
                    onChange={handleFormChange}
                    renderInput={params => (
                      <TextField
                        {...params}
                        margin="normal"
                        placeholder="Razón social"
                        fullWidth
                        required={true}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                              <React.Fragment>
                                  {loadingAutocomplete ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                        }}
                      />
                    )}
                  />
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cancelar
                </Button>
                <Button onClick={setClientsUser} color="secondary">
                  Guardar
                </Button>
              </DialogActions>
            </Dialog>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="secondary">
                  <h4 className={classes.cardTitleWhite}>Listado de clientes</h4>
                  <p className={classes.cardCategoryWhite}>

                  </p>
                </CardHeader>
                <CardBody>
                  <QueueAnim>
                    <DataGrid
                      dataSource={data}
                      showBorders={true}
                      key="id"
                      wordWrapEnabled={true}
                      allowColumnReordering={true}
                      allowColumnResizing={true}>

                      {/* <Export enabled={true} fileName={"Administradores"} texts={{exportAll: "Exportar todos lo datos"}}/>   */}
                            
                      <FilterRow visible={true} applyFilter={"auto"} />
                            
                      <HeaderFilter visible={true} />
                            
                      <GroupPanel visible={true} emptyPanelText={"Arrastre una columna para agrupar"} />
                            
                      <SearchPanel visible={true}
                        width={240}
                        placeholder="Buscar..." />
                            
                      <Paging defaultPageSize={10} />
                            
                      <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 20]}
                        showInfo={true} />
                              
                      <Sorting mode="multiple" />

                      <Column
                        dataField="name"
                        caption="Nombre"/>

                      <Column
                        dataField="email"
                        caption="Correo electrónico"/>

                      <Column
                        dataField="enabled"
                        cellRender={renderStatus}
                        caption="Estatus"/>

                      <Column
                        dataField="lastAccess"
                        dataType="Timestamp"
                        caption="Último acceso"/>

                      <Column
                        dataField="deviceType"
                        caption="Plataforma de creación"/>

                      <Column type="buttons"
                        visible={state.displayRole}
                        caption="Acciones"
                        width={80}
                        buttons={[{
                          hint: 'Habilitar/Deshabilitar',
                          icon: 'isblank',
                          onClick: enable
                        }, {
                          hint: 'Editar',
                          icon: 'edit',
                          onClick: handleOpen
                        }]}/>
                      </DataGrid>
                    </QueueAnim>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          )}
      </div>
    );
}