import React, { Component } from 'react';
import {
    // GOOGLE_AUTH_URL,
    // FACEBOOK_AUTH_URL,
    ACCESS_TOKEN,
    USER_ID_SESSION,
    USER_ROLES, DEVICE_ID,
    COMPLETE_NAME
} from '../../../../assets/constants';
import { login } from '../../../../util/Contexts/UserService';
import { recoverPassword } from '../../../../util/Contexts/UserService';
import {  Redirect } from 'react-router-dom'
import Alert from 'react-s-alert';
import { TraceSpinner } from "react-spinners-kit";
import {primaryColor, secondaryColor} from "../../../../assets/jss/material-dashboard-react";
import logo from "assets/img/sierra logo 1.svg";
import Swal from "sweetalert2";
// import fbLogo from '../../../../assets/img/fb-logo.png';
// import googleLogo from '../../../../assets/img/google-logo.png';
var loading = false;
class RecoverPassword extends Component {

    setLoading(loadingState) {
        loading = loadingState;
    }


    
    render() {
        if(this.props.authenticated) {
            return <Redirect
                to={{
                pathname: "/",
                state: { from: this.props.location }
            }}/>;            
        }

        return (
            <div>
                {loading ? (
                        <div style={{
                            position: 'absolute', left: '50%', top: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}>
                            <TraceSpinner

                        size={80}
                        frontColor={primaryColor[0]}
                        backColor={secondaryColor[0]}
                        loading={loading}/>
                        </div>
                    ) : (
                        <div className="login-container">
                            <div className="login-content">
                                 <RecoverPasswordForm {...this.props} setLoading={this.setLoading} />
                                 {console.log(this.props.location.pathname.split("/recover-password/")[1])}
                                 
                            </div>
                        </div>
                    ) }
            </div>
        );
    }
}

class RecoverPasswordForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newPassword: '',
            confirmNewPassword: '',
            token: this.props.location.pathname.split("/recover-password/")[1],
            checked: false,
            redirect: false
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;

        this.setState({
            [inputName] : inputValue
        });        
    }

    handleCheckedChange(checked) {
        this.setState({ checked });
    }

    handleGoToLogin(){
        return(
            <Redirect push to={"/"} />
        )
        
    }

    handleSubmit(event) {
        event.preventDefault();
        this.props.setLoading(true);
        console.log("Hola");
        if (this.state.confirmNewPassword != "" && this.state.newPassword !=""){
            if (this.state.confirmNewPassword != this.state.newPassword) {
                Alert.error("Las contraseñas deben de ser iguales");
                var pass1 = document.getElementById("pass1")
                var pass2 = document.getElementById("pass2")
                pass1.value="";
                pass2.value="";
            } else {
                recoverPassword(this.state.newPassword, this.state.token).then(response => {
                    if (response.data.success) {
                        this.handleCheckedChange(true);
                    } else {
                        Alert.error(response.data.message);
                    }
                })
            }
        } else {
            Alert.error("Se deben de llenar los dos campos");
        }
        
    }
    
    render() {
        return (
            <div>
                    <img src={logo} alt="logo" className="img" />
                    {this.state.checked ? (
                            <div>
                                <div className="form-item">
                                    <p>La recuperación de contraseña se realizó exitosamente, cerrar esta pestaña y volver a la página principal para iniciar sesión.</p>
                                </div>
                            </div>
                        ) :
                        <div>
                            <p>Ingresar la nueva contraseña</p>
                            <form onSubmit={this.handleSubmit}>
                        <div className="form-item">
                            <input type="password" name="newPassword" id='pass1'
                                   className="form-control" placeholder="Nueva contraseña"
                                   value={this.state.newPassword} onChange={this.handleInputChange} required/>
                        </div>
                        <div className="form-item">
                            <input type="password" name="confirmNewPassword" id='pass2'
                                   className="form-control" placeholder="Confirmar nueva contraseña"
                                   value={this.state.confirmNewPassword} onChange={this.handleInputChange} required/>
                        </div>
                        <div className="form-item">
                            <button type="submit" className="btn btn-block btn-primary">Enviar</button>
                        </div>
                    </form>
                        </div>
                    }

            </div>
        );
    }
}

export default RecoverPassword
