import React, {useEffect} from "react";
// @material-ui/core
import {makeStyles} from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Group from "@material-ui/icons/Group";
import EqualizerIcon from '@material-ui/icons/Equalizer';
import DescriptionIcon from '@material-ui/icons/Description';
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import {Link} from 'react-router-dom';
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import {getDataDashboard} from "../../../util/Contexts/DashboardService";
import {handleError} from "../../../util/Contexts/ErrorContext";
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import DatetimeRangePicker from "react-bootstrap-datetimerangepicker";
import {CHART_DATA, CHART_OPTION, PICKER_DATE_LOCALE} from "../../../assets/constants";
import {TextField, Chip} from "@material-ui/core";
import moment from "moment";

import CardBody from "../../../components/Card/CardBody";
import AnswerChart from "./AnswerChart";

const useStyles = makeStyles(styles);

export default function Dashboard() {
    const classes = useStyles();
    const [state, setState] = React.useState({
        allUsers: 0,
        allTickets: 0,
        ticketsActive: 0,
        ticketsClose: 0,
        serviceSheets: 0,
        serviceSheetsPendients: 0,
        startDate: moment().subtract(1, 'month').startOf('month'),
        endDate: moment().endOf('month'),
        ranges: {
            'Hoy': [moment(), moment()],
            'Ayer': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Útimos 7 días': [moment().subtract(6, 'days'), moment()],
            'Últimos 30 días': [moment().subtract(29, 'days'), moment()],
            'Este Mes': [moment().startOf('month'), moment().endOf('month')],
            'Ultimo Mes': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            'Desde el inicio': [moment("01-06-2020", "DD-MM-YYYY"), moment()]
        }
    });


    function getData() {
        getDataDashboard(state).then(({data}) => {
            setState({
                ...state,
                allUsers: data.data.userCount,
                allTickets: data.data.allTicketCount,
                ticketsActive: data.data.ticketsActive,
                ticketsClose: data.data.ticketClose,
                serviceSheets: data.data.serviceSheets,
                serviceSheetsPendients: data.data.serviceSheetsPendients
            });
        }).catch(
            error => {
                handleError(error);
            }
        );
    }

    useEffect(value => {
        getData();
    }, [state.startDate, state.endDate]);

    function handleEvent(event, picker) {
        event.preventDefault()
        setState({
            ...state,
            startDate: picker.startDate,
            endDate: picker.endDate,
        });
    }

    let start = state.startDate.format('DD/MMM/YYYY');
    let end = state.endDate.format('DD/MMM/YYYY');
    let label = start + ' - ' + end;
    if (start === end) {
        label = start;
    }

    return (
        <div align={"right"}>
            <div style={{width: "50%"}}>
                <DatetimeRangePicker

                    startDate={state.startDate}
                    endDate={state.endDate}
                    locale={PICKER_DATE_LOCALE}
                    ranges={state.ranges}
                    onApply={handleEvent}
                >
                    <TextField
                        type={"text"}
                        size={"small"}
                        fullWidth
                        variant="outlined"
                        value={label}
                        InputProps={{
                            style: {color: "black"}
                        }}
                        inputProps={{
                            style: {textAlign: "right"}
                        }}
                    />
                </DatetimeRangePicker>
            </div>
            <GridContainer>
                <GridItem xs={12} sm={6} md={4}>
                    <Link to="/admin/users">
                        <Card>
                            <CardHeader color="info" stats icon>
                                <CardIcon color="info">
                                    <Icon>
                                        <Group/>
                                    </Icon>
                                </CardIcon>
                                <p className={classes.cardCategory}>Total de Clientes</p>
                                <h5 className={classes.cardTitle}>
                                    {state.allUsers}
                                </h5>
                            </CardHeader>
                        </Card>
                    </Link>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <Link to={`/admin/request?status=2&start=${state.startDate}&end=${state.endDate}`}>
                        <Card>
                            <CardHeader color="primary" stats icon>
                                <CardIcon color="primary">
                                    <Icon>
                                        <LockIcon/>
                                    </Icon>
                                </CardIcon>
                                <p className={classes.cardCategory}>Solicitudes cerradas</p>
                                <h5 className={classes.cardTitle}>{state.ticketsClose}</h5>
                            </CardHeader>
                        </Card>
                    </Link>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <Link to={`/admin/request?status=4&start=${state.startDate}&end=${state.endDate}`}>
                        <Card>
                            <CardHeader color="primary" stats icon>
                                <CardIcon color="primary">
                                    <Icon>
                                        <DescriptionIcon/>
                                    </Icon>
                                </CardIcon>
                                <p className={classes.cardCategory}>Hojas de Servicio Generadas</p>
                                <h5 className={classes.cardTitle}>{state.serviceSheets}</h5>
                            </CardHeader>
                        </Card>
                    </Link>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <Link to={`/admin/request?status=0&start=${state.startDate}&end=${state.endDate}`}>
                        <Card>
                            <CardHeader color="success" stats icon>
                                <CardIcon color="success">
                                    <Icon>
                                        <ConfirmationNumberIcon/>
                                    </Icon>
                                </CardIcon>
                                <p className={classes.cardCategory}>Total de solicitudes</p>
                                <h5 className={classes.cardTitle}>{state.allTickets}</h5>
                            </CardHeader>
                        </Card>
                    </Link>
                </GridItem>

                <GridItem xs={12} sm={6} md={4}>
                    <Link to={`/admin/request?status=1&start=${state.startDate}&end=${state.endDate}`}>
                        <Card>
                            <CardHeader color="warning" stats icon>
                                <CardIcon color="warning">
                                    <Icon>
                                        <LockOpenIcon/>
                                    </Icon>
                                </CardIcon>
                                <p className={classes.cardCategory}>Solicitudes activas</p>
                                <h5 className={classes.cardTitle}>{state.ticketsActive}</h5>
                            </CardHeader>
                        </Card>
                    </Link>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                    <Link to={`/admin/request?status=5&start=${state.startDate}&end=${state.endDate}`}>
                        <Card>
                            <CardHeader color="primary" stats icon>
                                <CardIcon color="primary">
                                    <Icon>
                                        <DescriptionIcon/>
                                    </Icon>
                                </CardIcon>
                                <p className={classes.cardCategory}>Hojas de Servicio Pendientes</p>
                                <h5 className={classes.cardTitle}>{state.serviceSheetsPendients}</h5>
                            </CardHeader>
                        </Card>
                    </Link>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Divider/>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <AnswerChart/>

                </GridItem>
                <GridItem xs={12} sm={12} md={12}>

                    <Typography variant="h6" align={"center"}>BUENAS PRÁCTICAS PARA USAR EL SERVICIO SIERRA</Typography>
                    <Typography variant="body1" align={"justify"}>
                        <ol>
                            <li>Evita resolver solicitudes por vía telefónica, documenta todo en el sistema de
                                servicio.
                            </li>
                            <li>Registra las solicitudes de clientes en sistema si no tienen cuenta, podrás generar tu
                                hoja de servicio y les ayudas a incorporarse.
                            </li>
                            <li>Ya puedes generar hojas de servicio físicas o digitales desde este sistema.</li>
                            <li>Si tienes una hoja de servicio física podrás capturarla al hacer clic en "Generar Hoja
                                de Servicio" al resolver la solicitud.
                            </li>
                            <li>Revisa diariamente el estatus de tus solicitudes por si te perdiste alguna
                                notificación.
                            </li>
                            <li>Si te equivocaste al ingresar las horas dedicadas puedes editarlas. Al hacerlo se genera
                                un historial de cambio.
                            </li>
                            <li>Notifica y actualiza al cliente sobre lo que está pasando con su solicitud, incluso si
                                se detiene, pausa o toma más tiempo. ¡No lo dejes en suspenso!
                            </li>
                            <li>Si no encuentras la razón social de tu cliente, pide a un jefe de área que te ayude a
                                asignarlo a tu solicitud.
                            </li>
                            <li>De vez en cuando revisa las evaluaciones de servicio que recibes, puede ser de ayuda
                                para mejora interna.
                            </li>
                            <li>¡Promueve el sistema de Servicio Sierra con los clientes!</li>
                            <li>Genera una solicitud única por cada petición general diferente. No mezcles
                                temas/pendientes en una sola solicitud.
                            </li>
                            <li>Recuerda que TODO lo que escribas en una solicitud lo leerá el cliente, no mezcles temas
                                internos ni sobre otras personas. ¡Escribe profesional y objetivamente!
                            </li>
                            <li>Los comentarios son exclusivamente para comunicarte con el cliente sobre el servicio que
                                requiere.
                            </li>
                            <li>Una solicitud de servicio debe registrarse en el día que el cliente lo solicita, no
                                después.
                            </li>
                            <li>Cuando registres un cliente, ¡notifícale que ya tiene un usuario!</li>
                            <li>Las horas deben registrarse solamente en horas completas o en medias horas (0.5, 1,
                                1.5...).
                            </li>
                        </ol>

                    </Typography>
                </GridItem>

            </GridContainer>
        </div>
    )
}
