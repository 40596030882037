import React from 'react';
import './ToolbarButton.css';
import IconButton from '@material-ui/core/IconButton';

export default function ToolbarButton(props) {
    const { icon } = props;
    const { onClick } = props;
    return (
      <IconButton onClick={onClick}>
          {icon}
      </IconButton>
    );
}