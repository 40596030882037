import React, {useContext} from "react";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
// core components
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import {Link} from "react-router-dom";
import {AplicationContext} from "../../util/Contexts/AplicationContext";
import {USER_ID_SESSION} from "../../assets/constants";

const useStyles = makeStyles(styles);


export default function AdminNavbarLinks() {
  const classes = useStyles();
  const [openProfile, setOpenProfile] = React.useState(null);

  const handleClickProfile = event => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };
  const value = useContext(AplicationContext);
  return (
          <div>
            <div className={classes.manager}>
              <Button
                  color={window.innerWidth > 959 ? "transparent" : "white"}
                  justIcon={window.innerWidth > 959}
                  simple={!(window.innerWidth > 959)}
                  aria-owns={openProfile ? "profile-menu-list-grow" : null}
                  aria-haspopup="true"
                  onClick={handleClickProfile}
                  className={classes.buttonLink}
              >
                <Person className={classes.icons}/>
                <Hidden mdUp implementation="css">
                  <p className={classes.linkText}>Perfil</p>
                </Hidden>
              </Button>
              <Poppers
                  open={Boolean(openProfile)}
                  anchorEl={openProfile}
                  transition
                  disablePortal
                  className={
                    classNames({[classes.popperClose]: !openProfile}) +
                    " " +
                    classes.popperNav
                  }
              >
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        id="profile-menu-list-grow"
                        style={{
                          transformOrigin:
                              placement === "bottom" ? "center top" : "center bottom"
                        }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleCloseProfile}>
                          <MenuList role="menu">
                              <Link style={{ textDecoration: 'none', color: 'white' }} to={"/admin/profile/" + localStorage.getItem(USER_ID_SESSION)}>
                            <MenuItem
                                href={"/admin/profile/" + localStorage.getItem(USER_ID_SESSION) }
                                onClick={handleCloseProfile}
                                className={classes.dropdownItem}
                            >
                              Perfil
                            </MenuItem>
                              </Link>
                            <Divider light/>
                                      <MenuItem
                                          onClick={() => ( value.logout() )}
                                          className={classes.dropdownItem}
                                      >

                                          Salir
                                      </MenuItem>

                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                )}
              </Poppers>
            </div>
          </div>
  );
}
