import React from 'react'
import {usePromiseTracker} from 'react-promise-tracker'
import './style.css'
import {Modal} from '@material-ui/core'
import {TraceSpinner} from "react-spinners-kit";
import {primaryColor, secondaryColor} from "../../assets/jss/material-dashboard-react";

export const Spinner = props => {
    const {promiseInProgress} = usePromiseTracker()
    return (
        promiseInProgress && (
            <Modal
                open style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 99999
            }}
                disablePortal
                disableEnforceFocus
                disableAutoFocus
            >
                <div style={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    zIndex: 13001,
                    transform: 'translate(-50%, -50%)'
                }}
                >
                    <TraceSpinner
                        size={80}
                        frontColor={primaryColor[0]}
                        backColor={secondaryColor[0]}
                        loading={promiseInProgress}/>
                </div>
            </Modal>
        )
    )
}
