import React, {useEffect} from "react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import MaterialTable from 'material-table';
import axios from 'axios';
import Alert from "react-s-alert";
import {API_AUTENTICATION_HEADERS, API_BASE_URL_LOCAL} from 'assets/constants';
import {API_BASE_URL, USER_ID_SESSION} from "../../../../assets/constants";
import {primaryColor, secondaryColor} from "../../../../assets/jss/material-dashboard-react";
import {
    Button, Dialog, DialogActions,
    DialogContent, DialogTitle,
    Input, InputLabel, NativeSelect,
    Select, FormControl, TextField
} from "@material-ui/core";
import DataGrid, {
  Column, Export, FilterRow,
  GroupPanel, HeaderFilter,
  Pager, Paging, Editing,
  SearchPanel, Sorting
} from "devextreme-react/data-grid";
import QueueAnim from "rc-queue-anim";
import MenuItem from '@material-ui/core/MenuItem';
import {TraceSpinner} from "react-spinners-kit";
import {handleError} from "../../../../util/Contexts/ErrorContext";
import esMessages from 'devextreme/localization/messages/es.json';
import { locale, loadMessages } from 'devextreme/localization';
import Checkbox from "@material-ui/core/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {hasRole} from "../../../../util/Contexts/UserService";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: primaryColor,
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const useStyles = makeStyles(styles);


export default function Admins() {

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [roles, setRoles] = React.useState([]);
    const [offices, setOffices] = React.useState([]);
    const [departments, setDepartments] = React.useState([]);
    const [companyNames, setCompanyNames] = React.useState([]);
    const [loadingAutocomplete, setLoadingAutocomplete] = React.useState(false);
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const [state, setState] = React.useState({
        columns: [
            { title: 'Nombre', field: 'completeName' },
            { title: 'Correo electrónico', field: 'email' },
            { title: 'Áreas', field: 'admin', render: rowData => rowData.admin.departments.length === 0 ? "Sin departamentos" : rowData.admin.departments.map(({name}) => `\n${name}`).join(', '), editable: 'never' },
            { title: 'Rol', field: 'roles', render: rowData => rowData.roles.length === 0 ? "Sin rol" : rowData.roles.map(({showName}) => `\n${showName}`).join(','), editable: 'never' },
            { title: 'Estatus', field: 'enabled', render: rowData => rowData.enabled ? "Activo" : "Inactivo", editable: 'never' }

        ],
        users: [],
        filter: "",
        rolSelected: 0,
        stateSelected: 0,
        officeAsigned: [],
        departmentsSelected: [],
        id: 0,
        name: "",
        lastname: "",
        password: "",
        confirmPassword: "",
        phone: "",
        email: "",
        assesorId: 0,
        companyNamesSelected: [],
        edit: false
    });

    loadMessages(esMessages);
    locale("es");

    function handleClickOpen(row) {
      var item = null;
      if(row.row != null){
        for(let i = 0; i < state.users.length; i++){
          if(row.row.data.id === state.users[i].id){
            item = state.users[i]
          }
        }
      }
        item ? setState({
            ...state,
            id: item.id,
            name: item.name,
            phone: item.phone,
            lastname: item.lastName,
            email: item.email,
            rolSelected: item.roles[0].id,
            officeAsigned: item.admin.office ? item.admin.office.map(({id}) => id) : [],
            assesorId: item.assesorId,
            departmentsSelected: item.admin.departments ? item.admin.departments.map(({id}) => id) : [],
            edit: true,
            companyNamesSelected: item.companyNames ? item.companyNames.map((object) => (
                {
                    "id":object.clienteId,
                    "clienteId":null,
                    "rfc":object.rfc,
                    "fiscalName":object.fiscalName,
                    "tradeName":object.tradeName,
                    "city":object.city,
                    "office":object.office,
                    "email":object.email,
                    "number":object.phone,
                    "systemPolicy":object.systemPolicy,
                    "supportPolicy":object.supportPolicy,
                    "upgradePolicy":object.upgradePolicy
                }
            )) : []

        }) :  setState({
            ...state,
            id: 0,
            name: "",
            lastname: "",
            email: "",
            phone: "",
            officeAsigned: [],
            stateSelected: 0,
            rolSelected: 0,
            assesorId: 0,
            password: "",
            confirmPassword: "",
            departmentsSelected: [],
            edit: false,
            companyNamesSelected: []

        });
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    const handleChange = event => {
        setState( {
            ...state,
            [event.target.name] : event.target.value
        });
    };

    const renderStatus = (cellInfo) => {
      if(cellInfo.data.enabled){
        return(
          <div align={"center"}>
            Activo
          </div>
          );
      } else {
        return(
          <div align={"center"}>
            Inactivo
          </div>
          );
      }
    }

    const customRoles = (cellInfo) =>{
      var roles = ""
        if (cellInfo.row.data.admin) {
            cellInfo.row.data.roles.forEach(element => {
                roles = roles + " " + element.showName
            });
        }
      return (
        <div>
          {roles}
        </div>
      )
    }

    const customOffice = (cellInfo) =>{
        var areas = ""
        if (cellInfo.row.data.admin) {
        cellInfo.row.data.admin.office.forEach(element => {
            areas = areas + "\n" + element.name
        });
        }
        return (
            <div>
                {areas}
            </div>
        )
    }

    const customDepartments = (cellInfo) =>{
      var areas = ""
        if (cellInfo.row.data.admin) {
            cellInfo.row.data.admin.departments.forEach(element => {
                areas = areas + "\n" + element.name
            });
        }
      return (
        <div>
          {areas}
        </div>
      )
    }

    function enable(rowData) {
      enabledUser(!rowData.row.data.enabled, rowData.row.data.id)
    }

    const getAllOffices = () => {
        const url = API_BASE_URL + "/api/offices/enabled";
        const headers = API_AUTENTICATION_HEADERS;

        axios.get(url, { headers } ).then(respuesta => {
          console.log(respuesta.data.data)
            setOffices(respuesta.data.data);
        } ).catch(error => {
            handleError(error);
        });
    };

    function onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'plus',
          onClick: handleClickOpen.bind()
        }
      });
    }

    const enabledUser = async (enabled, id) => {
        const url = API_BASE_URL + "/api/user/" + id + "/enabled?enabled=" + enabled;
        const headers = API_AUTENTICATION_HEADERS;
        console.log(url)
        axios.put(url, {} ,{ headers } ).then(respuesta => {
            Alert.success(respuesta.data.message);
            getAllUsers();
        } ).catch(error => {
            handleError(error);
        });
    };

    const getAllUsers= async () => {
        setLoading(true);
        const url = API_BASE_URL + "/api/users/admins";
        const headers = API_AUTENTICATION_HEADERS;

        axios.get(url, { headers } ).then(respuesta => {
            setState({
               ...state,
               users:  respuesta.data.data
            });
            setLoading(false);
        } ).catch(error => {
            setLoading(false);
            handleError(error);
        });
    };

    const getAllRoles = () => {

        const url = API_BASE_URL + "/api/roles";
        const headers = API_AUTENTICATION_HEADERS;

        axios.get(url, { headers } ).then(respuesta => {
            // respuesta.data.data.push({id:5,name:'CORPORATIVE',showName:'Corporativo'})
           setRoles(respuesta.data.data);
        } ).catch(error => {
            handleError(error);
        });
    };

    const getAllDepartmets = () => {

        const url = API_BASE_URL + "/api/departments/enabled";
        const headers = API_AUTENTICATION_HEADERS;

        axios.get(url, { headers } ).then(respuesta => {
            setDepartments(respuesta.data.data);
        } ).catch(error => {
            handleError(error);
        });
    };

    function getAllData(){

        getAllRoles();
        getAllDepartmets();
        getAllUsers();
        getAllOffices();
    }

    useEffect(value => {
       getAllData();
    },[]);

    const addUser = (event) => {
        event.preventDefault();
        if (state.password.localeCompare(state.confirmPassword) !== 0) {
            Alert.warning("Las contraseñas no coinciden")
            return
        }
        const url = API_BASE_URL + "/api/user/admin";
        const headers = API_AUTENTICATION_HEADERS;
        let assesorId = state.rolSelected == 7 ? null : state.assesorId //SI ES ROL CORPORATIVO MANDA NULL
        axios.post(url, Object.assign({}, {name: state.name, lastname: state.lastname, enabled: true, phone: state.phone, password: state.password, email: state.email, departments: state.departmentsSelected, rol: state.rolSelected, office: state.officeAsigned, assesorId: assesorId, clients: state.companyNamesSelected})  ,{ headers } ).then(respuesta => {
          if(respuesta.data.success){
            Alert.success(respuesta.data.message);
              setState({
                  ...state,
                  name: "",
                  phone: "",
                  departmentsSelected: [],
                  officeAsigned: [],
                  stateSelected:  0,
                  lastname: "",
                  password: "",
                  confirmPassword: "",
                  email: "",
                  rolSelected: 0,
                  clients: []
              });
              handleClose();
              getAllData();
          } else {
            Alert.warning(respuesta.data.message);
          }

        } ).catch(error => {
            handleError(error);
        });
    };

    const updateUser = (event) => {
        event.preventDefault();

        setLoading(true);
        const url = API_BASE_URL + "/api/user/admin/" + state.id;
        const headers = API_AUTENTICATION_HEADERS;
        let assesorId = state.rolSelected == 7 ? null : state.assesorId //SI ES ROL CORPORATIVO MANDA NULL
        axios.put(url, Object.assign({}, {name: state.name, lastname: state.lastname, enabled: true, phone: state.phone, email: state.email, departments: state.departmentsSelected, rol: state.rolSelected, office: state.officeAsigned, assesorId: assesorId, clients: state.companyNamesSelected})  ,{ headers } ).then(respuesta => {
          if(respuesta.data.success){
            Alert.success(respuesta.data.message);
              setState({
                  ...state,
                  name: "",
                  phone: "",
                  departmentsSelected: [],
                  officeAsigned: [],
                  stateSelected:  0,
                  lastname: "",
                  password: "",
                  confirmPassword: "",
                  email: "",
                  rolSelected: 0,
                  clients: []
              });
              handleClose();
              getAllData();
              setLoading(false);
          } else {
            Alert.warning(respuesta.data.message);
          }

        } ).catch(error => {
            handleError(error);
            setLoading(false);
        });
    };

    const handleAutocompleteChange = event => {
        getAutocompleteSugestion(event.target.value);
    };

    const getAutocompleteSugestion = (query) => {
        setLoadingAutocomplete(true);
        if(!(/^\s*$/m.test(query))){
            const url = API_BASE_URL + "/api/user/clients?query=" + query ;
            const headers = API_AUTENTICATION_HEADERS;
            axios.get(url, { headers } ).then(respuesta => {
                if (respuesta.data.success) {
                    setCompanyNames(respuesta.data.data.clients);
                }else {
                    Alert.warning(respuesta.data.data);
                }
                setLoadingAutocomplete(false);
            } ).catch(error => {
                setLoadingAutocomplete(false);
            });
        } else {
            setCompanyNames([]);
            setLoadingAutocomplete(false)
            return undefined;
        }

    };

    return (
      <div>
        { loading ? (
          <div style={{
            position: 'absolute', left: '50%', top: '50%',
            transform: 'translate(-50%, -50%)'
          }}>
          <TraceSpinner
            size={80}
            frontColor={primaryColor[0]}
            backColor={secondaryColor[0]}
            loading={loading}/>
          </div>
        ) : (
          <GridContainer>
            <Dialog open={open} onClose={handleClose}>
              <form onSubmit={state.edit ? updateUser : addUser} autoComplete={"off"}>
                <DialogTitle id="form-dialog-title">{state.edit? "Editar Usuario" : "Agregar Usuario"}</DialogTitle>
                <DialogContent dividers="paper">
                  <FormControl required="true" fullWidth>
                    <InputLabel htmlFor="state-native-simple">Rol</InputLabel>
                    <NativeSelect
                      native="true"
                      fullWidth
                      disabled={state.id === localStorage.getItem(USER_ID_SESSION)}
                      required={true}
                      value={state.rolSelected}
                      onChange={handleChange}
                      inputProps={{
                        name: 'rolSelected',
                        id: 'state-native-simple'
                      }}
                    >
                      <option value="" />
                      {roles.map(function(value) { return (<option key={value.id} value={value.id} > {value.showName}</option> );})}
                    </NativeSelect>
                  </FormControl>
                    { parseInt(state.rolSelected) === 7 &&
                        <div>
                            <br/><br/>
                            <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                options={companyNames}
                                disableCloseOnSelect
                                /*renderTags={(selected) => console.log(selected)}*/
                                getOptionLabel={option => option.fiscalName + " (" + (option.tradeName) + ")"}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.fiscalName}
                                    </React.Fragment>
                                )}
                                fullWidth
                                renderInput={(params) => (
                                    <TextField {...params} label="Razón social" placeholder="Razón social" />
                                )}
                                value={state.companyNamesSelected}
                                onChange={(event, newValue) => {
                                    setState({...state, companyNamesSelected: newValue});
                                }}
                                getOptionSelected={(option, value) => option.id === value.id}
                                loading={loadingAutocomplete}
                                noOptionsText={"No se encontraron coincidencias"}
                                loadingText={"Buscando..."}
                                onKeyUp={handleAutocompleteChange}
                            />
                        </div>
                    }
                    { parseInt(state.rolSelected) !== 7 &&
                        <div>
                            <br/><br/>
                            <InputLabel htmlFor="select-multiple-officeAsigned">Oficina</InputLabel>
                            <Select
                                multiple
                                fullWidth
                                required
                                value={state.officeAsigned}
                                disabled={state.id === localStorage.getItem(USER_ID_SESSION)}
                                onChange={handleChange}
                                input={<Input id="select-multiple-officeAsigned"/>}
                                inputProps={{
                                    name: 'officeAsigned'
                                }}
                            >
                                {offices.map(value => (
                                    <MenuItem key={value.id} value={value.id}>
                                        {value.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    }
                    {parseInt(state.rolSelected) !== 7 &&
                        <div>
                            <br/><br/>
                            <InputLabel htmlFor="select-multiple-departmens">Áreas</InputLabel>
                            <Select
                                multiple
                                fullWidth
                                required
                                value={state.departmentsSelected}
                                disabled={state.id === localStorage.getItem(USER_ID_SESSION)}
                                onChange={handleChange}
                                input={<Input id="select-multiple-departmens" />}
                                inputProps={{
                                    name: 'departmentsSelected'
                                }}
                            >
                                {departments.map(value => (
                                    <MenuItem key={value.id} value={value.id} >
                                        {value.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    }

                    {   parseInt(state.rolSelected) !== 7 &&
                        <TextField
                            autoFocus
                            required={parseInt(state.rolSelected) !== 7}
                            margin="dense"
                            id="assesorId"
                            name="assesorId"
                            value={state.assesorId}
                            onChange={handleChange}
                            label="Clave de cuenta"
                            type="number"
                            fullWidth
                        />
                    }

                  <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="name"
                    name="name"
                    value={state.name}
                    onChange={handleChange}
                    label="Nombre"
                    type="text"
                    fullWidth
                  />
                  <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="lastname"
                    name="lastname"
                    value={state.lastname}
                    onChange={handleChange}
                    label="Apellidos"
                    type="text"
                    fullWidth
                  />
                  <TextField
                    fullWidth
                    required
                    id="email-input"
                    label="Correo electónico"
                    type="email"
                    name="email"
                    value={state.email}
                    onChange={handleChange}
                    margin="normal"
                  />
                  <TextField
                    fullWidth
                    required
                    id="phone-input"
                    label="Teléfono"
                    type="text"
                    name="phone"
                    value={state.phone}
                    onChange={handleChange}
                    margin="normal"
                  />
                  {
                  !state.edit && (
                    <div>
                      <TextField
                        fullWidth
                        required
                        id="password-input"
                        label="Contraseña"
                        type="password"
                        name="password"
                        value={state.password}
                        onChange={handleChange}
                        autoComplete="current-password"
                        margin="normal"
                      />
                      <TextField
                          fullWidth
                          required
                          id="confirm-password-input"
                          label="Confirmar contraseña"
                          type="password"
                          name="confirmPassword"
                          value={state.confirmPassword}
                          onChange={handleChange}
                          autoComplete="current-password"
                          margin="normal"
                        />
                      </div>
                    )
                  }
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                      Cancelar
                    </Button>
                    <Button  type={"submit"} color="primary">
                      Guardar
                    </Button>
                  </DialogActions>
              </form>
            </Dialog>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="secondary">
                  <h4 className={classes.cardTitleWhite}>Listado de administradores</h4>
                  <p className={classes.cardCategoryWhite}>

                  </p>
                </CardHeader>
                <CardBody>
                  <QueueAnim>
                    <DataGrid
                      dataSource={state.users}
                      showBorders={true}
                      key="id"
                      wordWrapEnabled={true}
                      allowColumnReordering={true}
                      allowColumnResizing={true}
                      onToolbarPreparing={onToolbarPreparing}>

                      {/* <Export enabled={true} fileName={"Administradores"} texts={{exportAll: "Exportar todos lo datos"}}/>   */}
                            
                      <FilterRow visible={true} applyFilter={"auto"} />
                            
                      <HeaderFilter visible={true} />
                            
                      <GroupPanel visible={true} emptyPanelText={"Arrastre una columna para agrupar"} />
                            
                      <SearchPanel visible={true}
                        width={240}
                        placeholder="Buscar..." />
                            
                      <Paging defaultPageSize={10} />
                            
                      <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 20]}
                        showInfo={true} />
                              
                      <Sorting mode="multiple" />

                      <Column
                        dataField="assesorId"
                        caption="Clave"/>

                      <Column
                        dataField="name"
                        caption="Nombre"/>

                      <Column
                        dataField="email"
                        caption="Correo electrónico"/>

                      <Column
                        dataField="admin.departments.roles"
                        cellRender={customDepartments}
                        caption="Area"/>

                        <Column
                            dataField="admin.office"
                            cellRender={customOffice}
                            caption="Oficinas"/>
                      
                      <Column
                        dataField="roles"
                        cellRender={customRoles}
                        caption="Rol"/>

                      <Column
                        dataField="enabled"
                        cellRender={renderStatus}
                        caption="Estatus"/>

                      <Column type="buttons"
                        visible={state.displayRole}
                        caption="Acciones"
                        width={80}
                        buttons={[{
                          hint: 'Habilitar/Deshabilitar',
                          icon: 'isblank',
                          onClick: enable
                        }, {
                          hint: 'Editar',
                          icon: 'edit',
                          onClick: handleClickOpen
                      }]}/>
                    </DataGrid>
                  </QueueAnim>

                  {/* <MaterialTable
                    columns={state.columns}
                    data={state.users}
                    title=""
                    localization={{
                    body: {
                    emptyDataSourceMessage: "No se encontraron administradores",
                    editRow: {
                    saveTooltip: "Guardar",
                    cancelTooltip: "Cancelar"
                    },
                    editTooltip: "Editar"
                    },
                    header: {
                    actions: "Acciones"
                    },
                    toolbar:{
                    searchPlaceholder:"Buscar"
                    },
                    pagination: {
                    firstTooltip: "Primera página",
                    firstAriaLabel: "Primera página",
                    previousTooltip: "Página anterior",
                    previousAriaLabel: "Página anterior",
                    nextTooltip: "Siguiente página",
                    nextAriaLabel: "Siguiente página",
                    labelDisplayedRows: "{from}-{to} de {count}",
                    labelRowsPerPage: "Registros por página",
                    lastTooltip: "Última página",
                    lastAriaLabel: "Última página",
                    labelRowsSelect: "Registros por página",
                    }
                    }}
                    actions={[
                      rowData =>  (rowData.enabled ?
                                                ({
                                                    icon: 'visibility_off',
                                                    tooltip: 'Deshabilitar',
                                                    onClick: (event, rowData) => disable(rowData.id, rowData)
                                                }) :
                                                ({
                                                    icon: 'visibility',
                                                    tooltip: 'Habilitar',
                                                    onClick: (event, rowData) => enable(rowData.id, rowData)
                                                })
                                        ),

                                        {
                                            icon: 'add',
                                            tooltip: 'Agregar administrador',
                                            isFreeAction: true,
                                            onClick: (event) => handleClickOpen()
                                        },
                                        rowData => ({
                                            icon: 'edit',
                                            tooltip: 'Editar administrador',
                                            onClick: (event) => handleClickOpen(rowData)
                                        })
                                    ]}

                                    options={{
                                        actionsColumnIndex: -1,
                                        sorting: true,
                                        showTitle: true,
                                    }}

                                /> */}
                            </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        )}

        </div>





    );
}
