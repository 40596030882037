import {API_AUTENTICATION_HEADERS, API_BASE_URL, API_MULTIPART_HEADERS} from "../../assets/constants";
import axios from "axios";
import {trackPromise} from "react-promise-tracker";
import imageCompression from "browser-image-compression";


const headers = API_AUTENTICATION_HEADERS;
const headersMultipart = API_MULTIPART_HEADERS;


function getActivitysFiles(id) {
    const url = API_BASE_URL + "/api/ticket/activity/" + id + "/files";
    const headers = API_AUTENTICATION_HEADERS;
    let result;
    trackPromise(
        result = axios.get(url, { headers })
    )
    return result;
}

function getTicketInfo(id) {
    const url = API_BASE_URL + "/api/ticketImage/"+ id;

    return axios.get(url, { headers } );
}

function getTicketsQualify() {
    const url = API_BASE_URL + "/api/qualified/tickets";

    let result;
    trackPromise(
        result = axios.get(url, {headers} )
    )
    return result;
}

function getOwnersList(id) {
    const url = API_BASE_URL + "/api/users/department/" + id;

    let result;
    trackPromise(
        result = axios.get(url, {headers} )
    )
    return result;
}

function getTicketFile(id) {
    const url = API_BASE_URL + "/api/ticket/"+ id + "/image";
    let result;
    trackPromise(
        result = axios.get(url, {headers} )
    )
    return result;
}

function commentTicket(body, id) {
    const url = API_BASE_URL + "/api/ticket/"+ id + "/comment";
    return axios.put(url, body ,{headersMultipart});
}

function openTicket(id) {
    const url = API_BASE_URL + "/api/ticket/" + id + "/reopen";
    let result;
    trackPromise(
        result = axios.put(url , {},{headers})
    )
    return result;
}
function assignedTicket(state) {
    const url = API_BASE_URL + "/api/ticket/" + state.ticket.id + "/data";

    return axios.put(url,  Object.assign({}, {priority: state.priorityAsigned, category: state.categoryAsigned, userAssigned: state.ownerAsigned, department: state.officeAsigned}),{ headers } );

}
async function createTicket(state, userSelect, newUser, files, departments, clientsSelect) {
    var bodyFormData = new FormData();
    var json = JSON.stringify({newUser: newUser,authorId: userSelect.id,title: departments.find((data) => data.id === Number(state.departmentSelected)).name, content: state.content, departamentId: state.departmentSelected,
        name: state.name, lastName: state.lastname, email: state.email, phone: state.phone, client: clientsSelect,
        priority: state.prioritySelected, category: state.categoryAsigned, location: state.branch_office, userAssigned: state.ownerSelected});
    var blob = new Blob([json] , { type: 'application/json' });
    bodyFormData.append("request", blob);

    if (files !== null) {
        for (const element of Array.from(files)) {
            let finalFile = await handleImageUpload(element);
            bodyFormData.append("file", finalFile, element.name);
        }
    }

    const url = API_BASE_URL + "/api/ticket/customer";
    const headers = headersMultipart;
    let result;
    trackPromise(
        result = axios.post(url, bodyFormData, {headers})
    )
    return result;
}

function getAllTicketsFilterBy (status, startDate, finishDate, cancelTokenSource) {
    const url = API_BASE_URL + "/api/tickets/filter";
    const headers = API_AUTENTICATION_HEADERS;

    let result;
    trackPromise(
        result = axios.post(url, Object.assign({}, {status: status, startDate: startDate, finishDate: finishDate}), {headers: headers, cancelToken: cancelTokenSource.token})
    )
    return result;
}
function getTicketActivitys(id) {
    const url = API_BASE_URL + "/api/ticket/" + id + "/activity";
    let result;
    trackPromise(
        result = axios.get(url, {headers} ), "none"
    )
    return result;
}

function getTicketDetail (id) {
    const url = API_BASE_URL + "/api/ticket/" + id;
    const headers = API_AUTENTICATION_HEADERS;
    let result;
    trackPromise(
        result = axios.get(url, { headers })
    )
    return result;
}
function validateSeleNumber(data) {

    var bodyFormData = new FormData();
    bodyFormData.append("saleNumber", data.saleNumber);
    bodyFormData.append("clientNumber", data.clientNumber);
    const url = API_BASE_URL + "/api/ticket/ " + data.ticket.id + "/validateSaleNumber";
    const headers = API_AUTENTICATION_HEADERS;


    let result;
    trackPromise(
        result = axios.post(url, bodyFormData, {headers})
    )
    return result;
}

function getTikcetPDF(id) {
    const url = `${API_BASE_URL}/api/serviceSheetReport/${id}`;
    let result;
    trackPromise(
        result = axios.get(url, {headers})
    );
    return result
}

async function commentTicketService(state, id, files) {
    var bodyFormData = new FormData();
    bodyFormData.append("comment",state.comment);

    if (files.length !== 0) {
        for (const element of Array.from(files)) {
            let finalFile = await handleImageUpload(element);
            bodyFormData.append("files", finalFile, element.name);
        }
    }

    const url = API_BASE_URL + "/api/ticket/"+ id + "/comment";
    let result;
    trackPromise(
        result = axios.put(url, bodyFormData ,{headers: headersMultipart})
    )
    return result;
}


export { getTikcetPDF, getTicketInfo, validateSeleNumber, getTicketFile, commentTicket, openTicket ,assignedTicket, getTicketsQualify, createTicket, getOwnersList, getAllTicketsFilterBy, getActivitysFiles, getTicketActivitys, getTicketDetail, commentTicketService};



export async function handleImageUpload(imageFile) {

    //const imageFile = event.target.files[0];
    // console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
    // console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

    const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
    }
    try {
        const compressedFile = await imageCompression(imageFile, options);
        // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
        // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
        // console.log(compressedFile.type)

        return (compressedFile); // write your own logic
    } catch (error) {
        // console.log("ERROR IN COMPRESS")
        // console.log(error);
        return imageFile
    }

}


