import React, { useState} from 'react';
import Compose from '../Compose';
import ToolbarButton from '../ToolbarButton';
import Message from '../Message';
import moment from 'moment';
import SendIcon from '@material-ui/icons/Send';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import {
  Dialog, DialogContent, DialogActions, Button, Badge
} from "@material-ui/core";
import './MessageList.css';
import 'moment/locale/es'
import {FilePond} from "react-filepond";  // without this line it didn't work
moment.locale('es');

export default function MessageList({messagesList, sendAction, idOwner, studentView}) {
  const [valueCommnet, setValueComment] = useState("");
  const [files, setFiles] = useState([]);
  const [openFiles, setOpenFiles] = useState(false);
  const renderMessages = () => {

    let i = 0;
    let messageCount = messagesList.length;
    let tempMessages = [];

    while (i < messageCount) {
      let previous = messagesList[i - 1];
      let current = messagesList[i];
      let next = messagesList[i + 1];
      let isMine = studentView ? current.user.id === idOwner : current.user.id !== idOwner ;
      let currentMoment = moment(new Date(current.dateActivity).getTime());
      let prevBySameAuthor = false;
      let nextBySameAuthor = false;
      let startsSequence = true;
      let endsSequence = true;
      let showTimestamp = true;

      if (previous) {
        let previousMoment = moment(new Date(previous.dateActivity).getTime());
        let previousDuration = moment.duration(currentMoment.diff(previousMoment));
        prevBySameAuthor = previous.author === current.author;
        
        if (prevBySameAuthor && previousDuration.as('minutes') < 2) {
          startsSequence = false;
        }

        if (previousDuration.as('minutes') < 2) {
          showTimestamp = false;
        }
      }

      if (next) {
        let nextMoment = moment(new Date(next.dateActivity).getTime());
        let nextDuration = moment.duration(nextMoment.diff(currentMoment));
        nextBySameAuthor = next.user === current.user;

        if (nextBySameAuthor && nextDuration.as('minutes') < 2) {
          endsSequence = false;
        }
      }

      tempMessages.push(
        <Message
          key={i}
          isMine={isMine}
          startsSequence={startsSequence}
          endsSequence={endsSequence}
          showTimestamp={showTimestamp}
          data={current}
          studentView={studentView}
        />
      );

      // Proceed to the next message.
      i += 1;
    }

    return tempMessages;
  };

  const handleChange = event => {
    setValueComment(event.target.value);
  };

  function onclickAction(event) {
    event.preventDefault();
    if(valueCommnet !== "") {
      sendAction(valueCommnet, files);
      setFiles([])
      setValueComment("");
    }

  }
  function onclickAttatchAction(event) {
    event.preventDefault();
setOpenFiles(true)

  }

    return(
      <div className="message-list">
        <Dialog open={openFiles} onClose={() => setOpenFiles(false)} fullWidth maxWidth={"sm"}>
          <DialogContent>
            <FilePond
                id="contained-button-file"
                files={files}
                allowMultiple={true}
                onupdatefiles={(files) => setFiles(files.map(fileItem => fileItem.file))}
                labelIdle='Arrastre y suelte sus archivos o <span class="filepond--label-action">Adjunte desde su dispositivo</span>'
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenFiles(false)}>
              Cancelar
            </Button>
            <Button onClick={() => setOpenFiles(false)}>
            Aceptar
          </Button>

          </DialogActions>
        </Dialog>

        {
          messagesList.length === 0 ? "Sin respuestas" : <div className="message-list-container">{renderMessages()}</div>
        }

        {
          sendAction &&  <Compose handleChange={handleChange} value={valueCommnet} rightItems={[<ToolbarButton key="photo" icon={<Badge badgeContent={files.length} color="primary"><AttachFileIcon fontSize={"large"} color={"primary"}/></Badge>} onClick={onclickAttatchAction}/>,
            <ToolbarButton key="message" icon={ <SendIcon fontSize={"large"} color={"primary"}/> } onClick={onclickAction}/>]}/>
        }





      </div>
    );
}