import React, {useState} from 'react';
import moment from 'moment';
import './Message.css';
import 'moment/locale/es'
import JSZip from "jszip";  // without this line it didn't work

import {Link, Typography} from '@material-ui/core';
import {getActivitysFiles} from "../../util/Contexts/TicketContext";
import {saveAs} from 'file-saver';
import Carousel, {Modal, ModalGateway} from "react-images";

moment.locale('es');

export default function Message(props) {

    const {
        data,
        isMine,
        startsSequence,
        endsSequence,
        showTimestamp,
        studentView
    } = props;
    moment.locale('es-us');
    const [images, setImages] = useState([])
    const [openModal, setOpenModal] = useState(false)

    const friendlyTimestamp = moment(new Date(data.dateActivity).getTime()).format('LLLL');

    function dowloadFiles() {
        if (images.length <= 0) {
            getActivitysFiles(data.id).then(({data}) => {
                setImages(data.data)
                setOpenModal(true)
            })
        }else {
            setOpenModal(true)
        }

    }

    return (
        <div className={[
            'message',
            `${isMine ? 'mine' : ''}`,
            `${startsSequence ? 'start' : ''}`,
            `${endsSequence ? 'end' : ''}`
        ].join(' ')}>
            {
                showTimestamp &&
                <div className="timestamp">
                    <strong>{friendlyTimestamp}</strong>

                </div>
            }
            {
                data.activityType.type.id !== 3 &&
                <div className="system">
                    {data.content}
                </div>
            }

            {
                data.activityType.type.id === 3 &&
                <div className="bubble-container">
                    <div className="bubble" title={friendlyTimestamp}>
                        <div dangerouslySetInnerHTML={{__html: data.content || ""}}/>
                        <br/>
                        {data.hasFiles &&
                        <Link  style={{color: "red"}} onClick={() => dowloadFiles()}>
                            Ver adjuntos
                        </Link>
                        }
                    </div>

                </div>
            }

            {
                data.activityType.type.id === 3 &&
                <Typography align={isMine ? "right" : "left"} variant={"caption"}
                            style={{textTransform: "uppercase"}}>{ !isMine && studentView ? "Responsable" : data.user.completeName}</Typography>
            }
            {
                openModal &&
                <ModalGateway>
                    <Modal
                        styles={{
                            positioner: base => ({
                                ...base,
                                zIndex: 10,
                            }),
                        }}
                        allowFullscreen={true}
                        onClose={() => setOpenModal(false)}>
                        <Carousel
                            currentIndex={0}
                            views={
                                images.map( i => ({src: "data:image/" + i.fileType + ";base64," + i.data}))

                            }
                        />
                    </Modal>
                </ModalGateway>
            }


        </div>
    );
}