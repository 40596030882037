import React, { Component } from 'react';
import {ACCESS_TOKEN, USER_ID_SESSION, USER_ROLES} from '../../assets/constants';
import { getCurrentUser, login}  from 'util/Contexts/UserService';
import Alert from 'react-s-alert';


export const AplicationContext = React.createContext();
export const AplicationConsumer = AplicationContext.Consumer;

export class AplicationProvider extends Component {
    state = {
        authenticated: false,
        currentUser: null,
        loading: true
    };
    componentDidMount() {
        this.loadCurrentlyLoggedInUser();
    }

    loadCurrentlyLoggedInUser() {
      this.setState({
        loading: true
      });

        getCurrentUser()
        .then(response => {
            localStorage.setItem(USER_ID_SESSION, response.data.id);
            localStorage.setItem(USER_ROLES, response.data.roles.map(value => value.name));
            this.setState({
                loading: false,
                authenticated: true
            });
        }).catch(error => {
            localStorage.removeItem(ACCESS_TOKEN);
            localStorage.removeItem(USER_ID_SESSION);
            localStorage.clear();

            this.setState({
                loading: false,
                authenticated: false
            });
        });
      }

      loginAction(loginRequest) {
        login(loginRequest)
        .then(response => {
            if (response.user.roles.map((value => value.name)).includes("USER")){

                localStorage.setItem(ACCESS_TOKEN, null);
                Alert.error('El usuario y/o la contraseña no coincide.');
            } else {
                Alert.success("Inicio de sesión exitoso");
                console.log(response.user.roles.map((value => value.name)));
                localStorage.setItem(ACCESS_TOKEN, response.accessToken);
                localStorage.setItem(USER_ID_SESSION, response.user.id);
                localStorage.setItem(USER_ROLES, response.user.roles.map(value => value.name));
                this.props.history.push("/");
                window.location.reload();
            }

        }).catch(error => {
            localStorage.setItem(ACCESS_TOKEN, null);
            Alert.error((error && error.message) || '');
        });
    }

    logout() {
        localStorage.removeItem(ACCESS_TOKEN);
        localStorage.removeItem(USER_ROLES);
        localStorage.removeItem(USER_ID_SESSION);
        window.location.reload();
      }

    render() {
        return (
            <AplicationContext.Provider
            value = {{
                aplicationCurrentUser: this.state.currentUser,
                userIsAuthenticated: this.state.authenticated,
                loading: this.loading,
                login: this.loginAction,
                logout: this.logout,
                loadCurrentlyLoggedInUser: this.loadCurrentlyLoggedInUser
            }}
            >
                {this.props.children}
            </AplicationContext.Provider>
        );
    }


}

export default AplicationProvider;