import {API_AUTENTICATION_HEADERS, API_BASE_URL, USER_ID_SESSION} from "../../assets/constants";
import axios from "axios";
import {trackPromise} from "react-promise-tracker";

const headers = API_AUTENTICATION_HEADERS;


function getAllDepartmets () {
    const url = API_BASE_URL + "/api/departments/all";
    return axios.get(url, { headers } );
}

function getMyDepartmets () {
    const url = API_BASE_URL + "/api/user/ " + localStorage.getItem(USER_ID_SESSION) + "/departments";
    let result;
    trackPromise(
        result = axios.get(url, {headers} )
    )
    return result;
}

function updateDepartment(id, newData) {
    const url = API_BASE_URL + "/api/department/" + id;

    return axios.put(url,newData,{ headers } );
}

function addDepartment (state) {
    const url = API_BASE_URL + "/api/department";
    return axios.post(url, Object.assign({}, {name: state.name, description: state.description, enabled: true, systemPolicy: state.systemPolicy,
        supportPolicy: state.supportPolicy, upgradePolicy: state.upgradePolicy})  ,{ headers } );

}

export { getAllDepartmets, updateDepartment, addDepartment, getMyDepartmets};