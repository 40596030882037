import {API_AUTENTICATION_HEADERS, API_BASE_URL} from "../../assets/constants";
import axios from "axios";
import {trackPromise} from "react-promise-tracker";


export function getDataDashboard(filter) {
    const url = API_BASE_URL + "/api/dashboard";
    const headers = API_AUTENTICATION_HEADERS;
    let result;
    trackPromise(
        result = axios.post(url, Object.assign({}, {
            startDate: filter.startDate,
            finishDate: filter.endDate
        }), {headers})
    )
    return result;
}
export function getDataAnswer(type) {
    const url = API_BASE_URL + "/api/dashboard/chart/" + type;
    const headers = API_AUTENTICATION_HEADERS;
    let result;
    trackPromise(
        result = axios.get(url, {headers})
    )
    return result;
}