import {useEffect, useRef} from "react";

export  function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest function.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}
export  function getAllItems(items, array) {
    items.forEach(i => {
        console.log(i)
        if (i.hasOwnProperty('items')) {
            getAllItems(i.items, array)
        }else {
            array.push(i)
        }
    })
    return array
}