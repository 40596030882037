import React from 'react';
import './Compose.css';
import TextField from '@material-ui/core/TextField';

export default function Compose(props) {

    return (
      <div className="compose">
        <TextField
          onChange={props.handleChange}
          value={props.value}
          fullWidth
          autoFocus
          multiline
          placeholder={"Escribe aquí"}
        />

        {
          props.rightItems
        }
      </div>
    );
}