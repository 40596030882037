import {ACCESS_TOKEN, USER_ID_SESSION} from "../../assets/constants";
import Alert from "react-s-alert";

function handleError(error) {
    if(error.response !== undefined){
    if (error.response.status === 401) {
        localStorage.removeItem(ACCESS_TOKEN);
        localStorage.removeItem(USER_ID_SESSION);
        localStorage.clear();
        window.location.reload();
    }else {
        Alert.error(error.message);
    }

    }
}
export { handleError };