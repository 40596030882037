import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/App';
import { createBrowserHistory } from "history";
import registerServiceWorker from './registerServiceWorker';
import { Router } from "react-router-dom";
import { CircularProgress } from '@material-ui/core';
import { usePromiseTracker } from "react-promise-tracker";
import { initializeFirebase } from './push-notification';
import { askForPermissioToReceiveNotifications } from './push-notification';
import {Spinner} from "./components/spinner";
import 'bootstrap-daterangepicker/daterangepicker.css';


const hist = createBrowserHistory();


initializeFirebase();
askForPermissioToReceiveNotifications();
ReactDOM.render(
        <Router history={hist} basename={'/'}>
            <Spinner/>
            <App/>
        </Router>,
    document.getElementById('root')
);

