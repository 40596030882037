import React, {useCallback, useEffect} from "react";
// @material-ui/core
import {makeStyles} from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
// Import React FilePond
import {FilePond, registerPlugin} from 'react-filepond'
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
// Import FilePond styles
import 'filepond/dist/filepond.min.css'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import EditIcon from '@material-ui/icons/Edit';
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import TimerIcon from '@material-ui/icons/Timer';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import axios from 'axios';
import Alert from "react-s-alert";
import {API_AUTENTICATION_HEADERS} from 'assets/constants';
import {API_BASE_URL, API_MULTIPART_HEADERS} from "../../../assets/constants";
import {
    primaryColor,
    secondaryColor,
    tertiaryColor,
    whiteColor
} from "../../../assets/jss/material-dashboard-react";
import {
    Button, Checkbox,
    ButtonGroup, CardContent,
    Dialog, FormControlLabel,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControl, Icon, InputAdornment, InputLabel, Paper, Select, TextField,
    Typography, ButtonBase, CircularProgress, Divider, Link, TablePagination
} from "@material-ui/core";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import clsx from "clsx";
import PersonIcon from '@material-ui/icons/Person';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import DoneIcon from '@material-ui/icons/Done';
import Grid from '@material-ui/core/Grid';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import {TraceSpinner} from "react-spinners-kit";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import GridItem from "../../../components/Grid/GridItem";
import {useInterval} from "../../../util/util";
import Gallery from "react-photo-gallery";
import Carousel, {Modal, ModalGateway} from "react-images";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import Chip from "@material-ui/core/Chip";
import {hasRole} from "../../../util/Contexts/UserService";
import {changePriority, getAllPrioritysEnabled} from "../../../util/Contexts/PriorityContext";
import {handleError} from "../../../util/Contexts/ErrorContext";
import {getAllCategoryByDepartment} from "../../../util/Contexts/CategoryContext";
import NativeSelect from '@material-ui/core/NativeSelect'
import Messenger from "../../../components/Messenger";
import {
    commentTicketService,
    getTicketActivitys,
    getTicketDetail,
    getTicketFile, validateSeleNumber
} from "../../../util/Contexts/TicketContext";
import Pagination from 'rc-pagination';
import '../../../assets/css/pagination.less'


// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType);


const styles = {
    root: {
        width: '100%',
        overflow: 'auto',
        backgroundColor: primaryColor,
    },
    inline: {
        display: 'inline',
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: primaryColor,
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    input: {
        display: 'none',
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    button: {
        margin: "auto",
    },
    leftIcon: {
        marginRight: "10px",
    },
    iconSmall: {
        fontSize: 20,
    },
    paper: {
        padding: "10px",
        margin: 'auto',
        width: "100%",
    },
    image: {
        width: 128,
        height: 128,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    rightIcon: {
        marginLeft: "10px",
    },
    avatarIcon: {
        color: whiteColor,
        backgroundColor: tertiaryColor[0]
    },
    card: {
        margin: 'auto',
        width: "100%",
        padding: "10px",
    },
    gallery: {
        zIndex: 10
    },
    form: {
        width: '100%',
    },
    boldText: {
        fontWeight: "bold"
    },
};

const useStyles = makeStyles(styles);


var refreshActivity = true
export default function TicketDetail(...props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [openOwners, setOpenOwners] = React.useState(false);
    const [openAutocomplete, setOpenAutocomplete] = React.useState(false);
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [openHasPhysicalSheet, setHasPhysicalSheet] = React.useState(false);
    const [openConfirmOpen, setOpenConfirmOpen] = React.useState(false);
    const [image, setImage] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [owners, setOwners] = React.useState([]);
    const [offices, setOffices] = React.useState([]);
    const [prioritys, setPrioritys] = React.useState([]);
    const [categorys, setCategorys] = React.useState([]);
    const [activitys, setActivitys] = React.useState([]);
    const [currentImage, setCurrentImage] = React.useState(0);
    const [viewerIsOpen, setViewerIsOpen] = React.useState(false);
    const [loadingAutocomplete, setLoadingAutocomplete] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [clientsSelect, setClientsSelect] = React.useState(null);
    const [defaultClient, setDefaultClient] = React.useState([]);
    const [editPriority, setEditPriority] = React.useState(false);
    const [editWorkedHours, setEditWorkedHours] = React.useState(false);
    const [serviceSheet, setServiceSheet] = React.useState(false);
    const [saleNumber, setSaleNumber] = React.useState(false);
    const [validatedClient, setValidatedClient] = React.useState(false);
    const [messageValidation, setMessageValidation] = React.useState("");
    const [serviceSheetTypeSelect, setServiceSheetTypeSelect] = React.useState(null);
    const [openReassingned, setOpenReassingned] = React.useState(false);
    const [services, setServices] = React.useState([]);
    const [systems, setSystems] = React.useState([]);
    const [files, setFiles] = React.useState([]);
    const [state, setState] = React.useState({
        ticket: null,
        modalIsOpen: false,
        files: [],
        comment: "",
        photoIndex: 0,
        isOpen: false,
        client: "",
        classification: "",
        hours: "",
        ownerAsigned: 0,
        department: 0,
        officeAsigned: 0,
        priorityAsigned: 0,
        categoryAsigned: 0,
        workDescription: "",
        serviceTypeSelected: "",
        assignServiceSheet: false,
        itHasPServiceShee: false,
        service: 0,
        system: 0,
        amount: 0,
        serviceSheetType: null,
        amountError: false,
        checkedF: true,
        checkedPSS: false,
        checkedIntern: false,
        reason: "",
        serviceSheetGenerated: false,
        physicalServiceSheetFolio: "",
        saleNumber: "",
        hasSaleNumber: false,
        clientNumber: ""
    });

    const getAutocompleteSugestion = (query) => {
        setLoadingAutocomplete(true);
        if (!(/^\s*$/m.test(query))) {
            const url = API_BASE_URL + "/api/user/clients?query=" + query;
            const headers = API_AUTENTICATION_HEADERS;
            axios.get(url, {headers}).then(respuesta => {
                if (respuesta.data.success) {
                    setOptions(respuesta.data.data.clients);
                } else {
                    Alert.warning(respuesta.data.data)
                }
                setLoadingAutocomplete(false);
            }).catch(error => {
                setLoadingAutocomplete(false);
            });
        } else {
            setOptions(defaultClient);
            setLoadingAutocomplete(false);
            return undefined;
        }
    };

    const handleFormChange = (event, value) => {
        setClientsSelect(value);
        setOptions([]);
    };


    const handleServiceSheetTypeChange = (event, value) => {
        setServiceSheetTypeSelect(value);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
        setImage(null)
    };

    function handleClickOpenConfirm(row) {
        setOpenConfirm(true);
    }

    function handleCloseConfirm() {
        setOpenConfirm(false);
    }

    function handleServiceSheetClick() {
        if (state.ticket.client === null) {

            state.assignServiceSheet = true;
            setOpenAutocomplete(true)
        } else {
            setHasPhysicalSheet(false);
            setServiceSheet(true)
        }
    }

    function handleCloseServiceSheetClose() {
        setServiceSheet(false)
    }

    function handleClickOpenAutocomplete() {
        setOpenAutocomplete(true);
    }

    function handleCloseAutocomplete() {
        setOpenAutocomplete(false);
    }


    function handleClickOpenConfirmOpen(row) {
        setOpenConfirmOpen(true);
    }

    function handleCloseConfirmOpen() {
        setOpenConfirmOpen(false);
    }

    function handleClickOpen(row) {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    function handleCloseItHasPhysicalServiceSheet() {
        setState({
            ...state,
            checkedPSS: false
        })
        setHasPhysicalSheet(false);
        setServiceSheet(true);
    }

    function handleOpenItHasPhysicalServiceSheet() {
        if (state.ticket.client === null) {
            setState({
                ...state,
                assignServiceSheet: true
            });
            setOpenAutocomplete(true)
        } else {
            setHasPhysicalSheet(true);
        }
    };

    function handleItHasPhysicalServiceSheet() {
        setState({
            ...state,
            checkedPSS: true
        })
        setHasPhysicalSheet(false);
        setServiceSheet(true);
    };


    function getActivity(id) {
        getTicketActivitys(id).then(respuesta => {
            setActivitys(respuesta.data.data);
        })
    }

    function handleClickOpenReasingnedOwners() {

        getAllCategory(state.ticket.department.id);

        getOwners2(state.ticket.department.id, 1);

    }

    function validateClientAndSaleNumber() {
        validateSeleNumber(state).then(respuesta => {
            console.log(respuesta.data);
            if (respuesta.data.success) {
                setSaleNumber(false);
                setValidatedClient(false);
                setMessageValidation("");
                Alert.success(respuesta.data.message);
            } else {
                setSaleNumber(true);
                setValidatedClient(true);
                setMessageValidation(respuesta.data.message);
            }
        })
    }


    const getOwners2 = (id, type) => {
        const url = API_BASE_URL + "/api/users/department/" + id;
        const headers = API_AUTENTICATION_HEADERS;
        axios.get(url, {headers}).then(respuesta => {
            setOwners(respuesta.data.data);
            type === 1 ? setOpenOwners(true) : setOpenReassingned(true);

        }).catch(error => {
            handleError(error);
        });
    };


    function reassignedReport(e) {
        e.preventDefault()
        var bodyFormData = new FormData();
        bodyFormData.append("priority", state.ticket.priority.id);
        const url = API_BASE_URL + "/api/ticket/" + state.ticket.id + "/assigned/" + state.ownerAsigned;
        const headers = API_AUTENTICATION_HEADERS;

        axios.put(url, bodyFormData, {headers}).then(respuesta => {
            setLoading(false);
            setOpenOwners(false);
            Alert.success(respuesta.data.message);
            getTicket();
        })
    }

    function handleCloseOwners() {
        setOpenOwners(false);
    }


    function assingnedTicket(e) {
        e.preventDefault()
        setLoading(true);
        const url = API_BASE_URL + "/api/ticket/" + state.ticket.id + "/assigned";
        const headers = API_AUTENTICATION_HEADERS;
        axios.put(url, {}, {headers}).then(respuesta => {
            setLoading(false);
            setOpen(false);
            Alert.success(respuesta.data.message);
            getTicket();
        }).catch(error => {
            setLoading(false);
            handleError(error);
        });
    }

    function generateServiceSheet(event) {
        event.preventDefault();
        if (!state.checkedF && parseFloat(state.amount ? state.amount : "0") <= 0) {
            Alert.info("El costo de la hoja de servicio no debe estar en ceros");
            return;
        }


        if (state.reason.localeCompare("") === 0 && state.checkedF) {
            Alert.info("La razón es requerida");
            return;
        }
        setLoading(true);
        if (!state.checkedPSS) {

            var bodyFormData = new FormData();
            bodyFormData.append("serviceSheetType", serviceSheetTypeSelect.id)
            bodyFormData.append("amount", state.amount);
            bodyFormData.append("reason", state.reason);
            bodyFormData.append("folioVenta", state.saleNumber);
            bodyFormData.append("clienteId", state.clientNumber);
            const url = API_BASE_URL + "/api/ticket/" + state.ticket.id + "/generateServiceSheet";
            const headers = API_AUTENTICATION_HEADERS;
            axios.post(url, bodyFormData, {headers}).then(respuesta => {
                if (respuesta.data.success) {
                    Alert.success(respuesta.data.message);
                    window.location.reload(true);
                } else {
                    Alert.warning(respuesta.data.message);
                }
                setServiceSheet(false);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                handleError(error);
            });
        } else {
            var bodyFormData = new FormData();
            bodyFormData.append("serviceSheetFolio", state.physicalServiceSheetFolio);
            bodyFormData.append("serviceSheetType", serviceSheetTypeSelect.id)
            bodyFormData.append("amount", state.amount);
            bodyFormData.append("reason", state.reason);
            const url = API_BASE_URL + "/api/ticket/" + state.ticket.id + "/generatePhysicalServiceSheet";
            const headers = API_AUTENTICATION_HEADERS;
            axios.post(url, bodyFormData, {headers}).then(respuesta => {
                if (respuesta.data.success) {
                    Alert.success(respuesta.data.message);
                    window.location.reload(true);
                } else {
                    Alert.warning(respuesta.data.message);
                }
                setServiceSheet(false);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                handleError(error);
            });
        }

    }

    function closeTicket(event) {
        event.preventDefault();

        setLoading(true);
        if (state.hours <= 0) {
            Alert.info("Es necesario ingresar las horas dedicadas.");
            setLoading(false);
            return;
        }


        var bodyFormData = new FormData();
        console.log(state.system)
        bodyFormData.append("isInternal", state.checkedIntern.toString());
        bodyFormData.append("hours", state.hours);
        bodyFormData.append("description", state.workDescription);
        bodyFormData.append("services", state.service);
        bodyFormData.append("system", state.system);
        const url = API_BASE_URL + "/api/ticket/" + state.ticket.id + "/close";
        const headers = API_AUTENTICATION_HEADERS;
        axios.put(url, bodyFormData, {headers}).then(respuesta => {
            Alert.success(respuesta.data.message);
            setOpenConfirm(false);
            setLoading(false);
            getTicket();
        }).catch(error => {
            setLoading(false);
            handleError(error);
        });

    }

    function openTicket() {
        const url = API_BASE_URL + "/api/ticket/" + state.ticket.id + "/reopen";
        const headers = API_AUTENTICATION_HEADERS;
        setOpenConfirmOpen(false);
        setLoading(true)
        axios.put(url, {}, {headers}).then(respuesta => {
            getTicket();
        }).catch(error => {
            handleError(error);
        });
    }


    const handleCheckboxChange = () => event => {
        console.log(event.target);
        setState({
            ...state,
            [event.target.name]: event.target.checked,
            physicalServiceSheetFolio: ""
        })
    }

    const handleCheckboxChangeValidateSaleNumber = () => event => {
        setSaleNumber(!saleNumber);
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        })
    }

    function handleChange(event) {
        event.preventDefault()
        if (event.target.name === 'clientNumber' || event.target.name === 'saleNumber') {
            setSaleNumber(true);

        }
        setState({
            ...state,
            [event.target.name]: event.target.value
        });


    }

    const amountChange = () => event => {

        setState({
            ...state,
            amountError: false,
            [event.target.name]: event.target.value
        });


    };


    function handleDepartamentChange(event) {
        event.preventDefault();
        setState({
            ...state,
            officeAsigned: event.target.value
        });
        getAllCategory(event.target.value);
        getOwners(event.target.value);
    }

    const handleChangeKey = event => {
        getAutocompleteSugestion(event.target.value);
    };

    const imageRenderer = useCallback(
        ({key, photo}) => (
            <div style={{width: "200px", height: "100%"}}>
                <img
                    key={key}
                    src={photo.src}
                    width={200}
                    style={{height: "auto", padding: 10}}
                />
            </div>
        ),
        []
    );

    function commentTicket() {
        setLoading(true);
        refreshActivity = false
        commentTicketService(state, state.ticket.id, files).then(respuesta => {
            Alert.success(respuesta.data.message);
            setLoading(false);
            refreshActivity = true
        })
    }

    function getTicketId(id) {
        getTicketDetail(id).then(respuesta => {
            console.log(respuesta.data.data.client); //Para entrar a los datos del cliente
            console.log(respuesta.data.data.department); //Para entrar a los datos del area
            console.log(respuesta.data.data);
            // console.log(respuesta.data.data.department.systemPolicy == respuesta.data.data.client.systemPolicy);
            // console.log((respuesta.data.data.department.supportPolicy == true && respuesta.data.data.client.supportPolicy == true));
            setState({
                ...state,
                filesShow: [],
                comment: "",
                ticket: respuesta.data.data,
                officeAsigned: respuesta.data.data.department.id,
                serviceSheetGenerated: respuesta.data.data.ticketStatus.id == 7,
                system: respuesta.data.data.department.supportPolicy ? 37 : "",
                checkedF: respuesta.data.data.client ? ((respuesta.data.data.department.systemPolicy == true && respuesta.data.data.client.systemPolicy == true) || (respuesta.data.data.department.supportPolicy == true && respuesta.data.data.client.supportPolicy == true)) : false,
                reason: respuesta.data.data.client ? (((respuesta.data.data.department.systemPolicy == true && respuesta.data.data.client.systemPolicy == true) || (respuesta.data.data.department.supportPolicy == true && respuesta.data.data.client.supportPolicy == true)) ? "La póliza cubre el costo del servicio" : "") : ""
            });
            setFiles([])
            setDefaultClient(respuesta.data.data.author.clients);
            setOptions(respuesta.data.data.author.clients)
            getAllCategory(respuesta.data.data.department.id);
            if (!respuesta.data.data.isDataComplete) {
                getOwners(respuesta.data.data.department.id, respuesta.data.data.campus.id);
            } else {
                getActivity(respuesta.data.data.id);
            }
        })
    }

    const getTicket = () => {
        const {match: {params}} = props[0];
        getTicketId(params.id)
    };

    const getTicketImage = (e) => {
        e.preventDefault()
        getTicketFile(state.ticket.id).then(respuesta => {
            setImage(respuesta.data.data);
            setCurrentImage(0);
            setViewerIsOpen(true);
        })
    };

    function assignedReport(event) {
        event.preventDefault();
        setLoading(true);
        const url = API_BASE_URL + "/api/ticket/" + state.ticket.id + "/data";
        const headers = API_AUTENTICATION_HEADERS;
        axios.put(url, Object.assign({}, {
            priority: state.priorityAsigned,
            category: state.categoryAsigned,
            userAssigned: state.ownerAsigned,
            department: state.officeAsigned
        }), {headers}).then(respuesta => {
            setLoading(false);
            getTicket();
            setState({
                ...state,
                officeAsigned: 0,
                priorityAsigned: 0,
                categoryAsigned: 0,
                ownerAsigned: 0,
            });
        }).catch(error => {
            setLoading(false);
            Alert.error(error.message);
        });
    }

    const getAllPrioritys = async () => {
        getAllPrioritysEnabled().then(respuesta => {
            setPrioritys(respuesta.data.data);
        }).catch(error => {
            handleError(error);
        });
    };

    const changePriorityAction = async () => {
        if (state.priorityAsigned != 0) {
            setLoading(true)
            changePriority(state.ticket.id, state.priorityAsigned).then(respuesta => {
                getTicket();
                setLoading(false);
                setEditPriority(false);
                Alert.success(respuesta.data.message);
            }).catch(error => {
                setLoading(false);
                handleError(error);
            });
        } else {
            setEditPriority(false);
        }
    };

    function changeWorkedHours() {
        var bodyFormData = new FormData();
        bodyFormData.append("newWorkedHours", state.hours);
        const url = API_BASE_URL + "/api/ticket/" + state.ticket.id + "/workedHours";
        const headers = API_AUTENTICATION_HEADERS;
        return axios.put(url, bodyFormData, {headers})
    }

    const changeWorkedHoursAction = async () => {
        if (state.hours != 0) {
            setLoading(true)
            changeWorkedHours().then(respuesta => {
                getTicket();
                setLoading(false);
                setEditWorkedHours(false);
                Alert.success(respuesta.data.message);
            }).catch(error => {
                setLoading(false);
                handleError(error);
            });
        } else {
            setEditWorkedHours(false);
        }
    };


    const handleAutocompleteChange = event => {
        console.log('true')
    };

    const getAllCategory = async (id) => {
        getAllCategoryByDepartment(id).then(respuesta => {
            setCategorys(respuesta.data.data);

        }).catch(error => {
            handleError(error);
        });
    };

    const getOwners = (id) => {
        const url = API_BASE_URL + "/api/users/department/" + id;
        const headers = API_AUTENTICATION_HEADERS;
        axios.get(url, {headers}).then(respuesta => {
            setOwners(respuesta.data.data);
        }).catch(error => {
            handleError(error);
        });
    };

    function createButtons() {
        switch (state.ticket?.ticketStatus?.id ?? 0) {
            case 3:
                if (state.ticket.isInternalTicket) {
                    return (
                        <ButtonGroup color="primary" variant="contained" size="small"
                                     aria-label="small contained button group">
                            {
                                <Button onClick={handleClickOpenConfirmOpen} color="secondary">
                                    <LockOpenIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                    Abrir
                                </Button>
                            }
                        </ButtonGroup>
                    )
                } else {
                    return (
                        <ButtonGroup color="primary" variant="contained" size="small"
                                     aria-label="small contained button group">
                            {
                                (hasRole("SUPER_USER") || hasRole("ADMIN") || hasRole("OPERATOR")) && (
                                    <Button onClick={handleClickOpenAutocomplete}>
                                        <PersonIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                        Asignar Razón social
                                    </Button>
                                )
                            }
                            {
                                <Button onClick={handleClickOpenConfirmOpen} color="secondary">
                                    <LockOpenIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                    Abrir
                                </Button>
                            }
                            {

                                (hasRole("SUPER_USER") || hasRole("ADMIN") || hasRole("OPERATOR")) && (
                                    <Button onClick={handleOpenItHasPhysicalServiceSheet}
                                            style={{backgroundColor: "#789D5B"}}>
                                        <DoneIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                        Generar hoja de servicio
                                    </Button>
                                )
                            }
                        </ButtonGroup>
                    )
                }
            case 7:
                return (
                    <div>
                        Hoja de servicio generado
                    </div>
                )
            case 8:
                return (
                    <div>
                        Hoja de servicio física generada
                    </div>
                )
            default:
                return (
                    <ButtonGroup color="primary" variant="contained" size="small"
                                 aria-label="small contained button group">
                        {
                            state.ticket.closed && state.ticket.ticketStatus.id === 9 &&
                            <Button onClick={handleClickOpenConfirmOpen} color="secondary">
                                <LockOpenIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                Abrir
                            </Button>
                        }
                        {
                            (hasRole("SUPER_USER") || hasRole("ADMIN") || hasRole("OPERATOR")) && !state.ticket.closed && (
                                <Button onClick={handleClickOpenAutocomplete}>
                                    <PersonIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                    Asignar Razón social
                                </Button>
                            )
                        }
                        {
                            !state.ticket.closed &&
                            <Button color="secondary" onClick={handleClickOpenConfirm}>
                                <LockIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                Cerrar
                            </Button>
                        }
                        {
                            (hasRole("SUPER_USER") || hasRole("ADMIN")) && !state.ticket.closed && (
                                <Button style={{backgroundColor: "#789D5B"}}
                                        onClick={handleClickOpen}>
                                    <AssignmentIndIcon
                                        className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                    Asignármelo
                                </Button>
                            )
                        }
                        {
                            (hasRole("SUPER_USER") || hasRole("ADMIN")) && !state.ticket.closed && (
                                <Button style={{backgroundColor: "#e3b536"}}
                                        onClick={() => handleClickOpenReasingnedOwners()}>
                                    <AssignmentIndIcon
                                        className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                    Reasignar Asesor
                                </Button>
                            )
                        }
                    </ButtonGroup>
                )
        }
    }

    // function hardware(){
    //     if (state.ticket.department.supportPolicy){
    //         setIsHardware(true);
    //         console.log(systems[27].Des);        }
    //     //falta probar
    // }

    function showClosedTicketData(option) {
        if (option === 1) {
            for (let i = 0; services.length; i++) {
                if (state.ticket.serviceId === services[i].Cve) {
                    return services[i].Des;
                }
            }
        } else if (option === 2) {
            for (let i = 0; systems.length; i++) {
                if (state.ticket.systemId === systems[i].Cve) {
                    return systems[i].Des;
                }
            }
        } else if (option === 3) {
            if (state.ticket.serviceSheetFolio === null) {
                return 0
            }
            return state.ticket.serviceSheetFolio
        } else {
            if (!state.ticket.isPhysicalServiceSheet) {
                return 0
            }
            return state.ticket.physicalServiceSheetFolio
        }
    }


    const setClientsUser = async () => {
        console.log(clientsSelect)
        if (clientsSelect != null) {
            console.log(clientsSelect != null)
            setLoading(true);
            const url = API_BASE_URL + "/api/ticket/" + state.ticket.id + "/client";
            const headers = API_AUTENTICATION_HEADERS;
            axios.put(url, clientsSelect, {headers}).then(respuesta => {
                Alert.success(respuesta.data.message);
                setLoading(false);
                setHasPhysicalSheet(false);
                setOpenAutocomplete(false);
                getTicket();
                if (state.assignServiceSheet) {
                    setHasPhysicalSheet(true);
                }
            }).catch(error => {
                setLoading(false);
                handleError(error);
            });
        } else {
            setOpenAutocomplete(false);
            Alert.warning("Se debe de ingresar un razón social para guardarlo")
        }
    };

    const getAllDepartmets = async () => {
        const url = API_BASE_URL + "/api/departments";
        const headers = API_AUTENTICATION_HEADERS;
        axios.get(url, {headers}).then(respuesta => {
            setOffices(respuesta.data.data);
        }).catch(error => {
            handleError(error);
        });
    };

    const serviceSheetType = [
        {name: 'Asesoría', id: 1},
        {name: 'Hoja de Servicio', id: 2},
        {name: 'Reparación', id: 3},
        {name: 'Asesoría Telefónica', id: 4},
        {name: 'Ticket', id: 5},
    ];
    const getServiceSheetData = async () => {
        const url = API_BASE_URL + "/api/serviceSheetData";
        const headers = API_AUTENTICATION_HEADERS;
        axios.get(url, {headers}).then(respuesta => {
            setServices(respuesta.data.data.servicios);
            setSystems(respuesta.data.data.sistemas);
        }).catch(error => {
            handleError(error);
        });
    }

    useEffect(value => {
        getTicket();
        getAllPrioritys();
        getAllDepartmets();
        getServiceSheetData();
    }, []);


    useInterval(() => {
        if (state.ticket != null && refreshActivity) {
            getActivity(state.ticket.id)
        }
    }, 5000);

    if (loading) {
        return (
            <div style={{
                position: 'absolute', left: '50%', top: '50%',
                transform: 'translate(-50%, -50%)'
            }}>
                <TraceSpinner
                    size={80}
                    frontColor={primaryColor[0]}
                    backColor={secondaryColor[0]}
                    loading={loading}/>
            </div>
        );
    }

    if (!state.ticket?.isDataComplete && !hasRole("CORPORATIVE")) {
        return (
            <div>
                {
                    state.ticket && (
                        <>
                            <Card>
                                <CardHeader color="secondary">
                                    <Grid container={true} direction="row" alignItems="flex-start">
                                        <Grid item={true} xs={12} sm={12} md={7}>
                                            <h4 className={classes.cardTitleWhite}>#{state.ticket.ticketNumber}</h4>
                                        </Grid>
                                    </Grid>
                                </CardHeader>
                                <CardBody>
                                    <Grid container={true} spacing={1} direction="row">
                                        <Grid item={true} xl={6} md={6} xs={6}>
                                            <Typography style={{display: 'inline-block'}}
                                                        className={classes.boldText}>Estatus: </Typography> <Typography
                                            style={{display: 'inline-block'}}
                                            gutterBottom>{state.ticket.ticketStatus ? (state.ticket.ticketStatus.name) : ""}</Typography>
                                        </Grid>
                                        <Grid item={true} xl={6} md={6} xs={6}>
                                            <Typography style={{display: 'inline-block'}}
                                                        className={classes.boldText}>Enviado: </Typography> <Typography
                                            style={{display: 'inline-block'}} gutterBottom>{state.ticket.createDate}</Typography>
                                        </Grid>

                                        <Grid item={true} xl={6} md={6} xs={6}>
                                            <Typography style={{display: 'inline-block'}}
                                                        className={classes.boldText}>Solicitante: </Typography> <Typography
                                            style={{display: 'inline-block'}}
                                            gutterBottom>{state.ticket.author ? (state.ticket.author.completeName) : ""}</Typography>
                                        </Grid>

                                        <Grid item={true} xl={6} md={6} xs={6}>
                                            <Typography style={{display: 'inline-block'}}
                                                        className={classes.boldText}>Desde: </Typography> <Typography
                                            style={{display: 'inline-block'}}
                                            gutterBottom>{state.ticket.location ? state.ticket.location : "Sin registrar"}</Typography>
                                        </Grid>

                                        <Grid item={true} xl={6} md={6} xs={6}>
                                            <Typography style={{display: 'inline-block'}}
                                                        className={classes.boldText}>Email: </Typography> <Typography
                                            style={{display: 'inline-block'}}
                                            gutterBottom>{state.ticket.author ? (state.ticket.author.email) : "Sin correo"}</Typography>
                                        </Grid>

                                        <Grid item={true} xl={6} md={6} xs={6}>
                                            <Typography style={{display: 'inline-block'}}
                                                        className={classes.boldText}>Teléfono: </Typography> <Typography
                                            style={{display: 'inline-block'}}
                                            gutterBottom>{state.ticket.author ? (state.ticket.author.phone) : "Sin teléfono"}</Typography>
                                        </Grid>

                                        <Grid item={true} xl={6} md={6} xs={6}>
                                            <Typography style={{display: 'inline-block'}}
                                                        className={classes.boldText}>Área: </Typography> <Typography
                                            style={{display: 'inline-block'}}
                                            gutterBottom>{state.ticket.department ? (state.ticket.department.name) : ""}</Typography>
                                        </Grid>

                                        <Grid item={true} xl={6} md={6} xs={6}>
                                            <Typography style={{display: 'inline-block'}} className={classes.boldText}>Razón
                                                social: </Typography> <Typography style={{display: 'inline-block'}}
                                                                                  gutterBottom>{state.ticket.client ? state.ticket.client.fiscalName : "Sin asignar"}</Typography>
                                        </Grid>
                                    </Grid>
                                </CardBody>
                            </Card>
                            <GridContainer>
                                <GridItem xs={12} sm={6} md={6}>
                                    <Paper className={classes.paper}>
                                        <Typography component={'div'} variant="body2" gutterBottom>
                                            {state.ticket.content}
                                            <br/>
                                            {state.ticket.hasFile &&
                                                <Link color={"initial"} onClick={getTicketImage}>
                                                    Ver Imagen


                                                </Link>
                                            }
                                        </Typography>
                                    </Paper>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6}>
                                    <div align="center">
                                        <Card className={classes.card}>
                                            <CardHeader color="secondary">
                                                <div align="center">
                                                    Antes de poder atender la solicitud debe completar la siguiente información
                                                </div>
                                            </CardHeader>
                                            <CardContent>
                                                <form className={classes.form} autoComplete="off" onSubmit={assignedReport}>
                                                    <GridContainer>
                                                        <GridItem xs={12} sm={12} md={12}>
                                                            <FormControl fullWidth required>
                                                                <InputLabel htmlFor="officeAsigned">Área</InputLabel>
                                                                <Select
                                                                    required
                                                                    native
                                                                    value={state.officeAsigned}
                                                                    onChange={handleDepartamentChange}
                                                                    inputProps={{
                                                                        name: 'officeAsigned',
                                                                        id: 'officeAsigned',
                                                                    }}
                                                                >
                                                                    <option value=""/>
                                                                    {offices.map(function (value) {
                                                                        return (<option key={value.name}
                                                                                        value={value.id}> {value.name}</option>);
                                                                    })}
                                                                </Select>
                                                            </FormControl>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={12} md={12}>
                                                            <FormControl fullWidth required>
                                                                <InputLabel htmlFor="ownerAsigned">Responsable</InputLabel>
                                                                <Select
                                                                    required
                                                                    native
                                                                    value={state.ownerAsigned}
                                                                    onChange={(e) => handleChange(e)}
                                                                    inputProps={{
                                                                        name: 'ownerAsigned',
                                                                        id: 'ownerAsigned',
                                                                    }}
                                                                >
                                                                    <option value=""/>
                                                                    {owners.map(function (value) {
                                                                        return (<option key={value.completeName}
                                                                                        value={value.id}> {value.completeName}</option>);
                                                                    })}
                                                                </Select>
                                                            </FormControl>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={6} md={6}>
                                                            <FormControl fullWidth required>
                                                                <InputLabel htmlFor="priorityAsigned">Prioridad</InputLabel>
                                                                <Select
                                                                    required
                                                                    native
                                                                    value={state.priorityAsigned}
                                                                    onChange={(e) => handleChange(e)}
                                                                    inputProps={{
                                                                        name: 'priorityAsigned',
                                                                        id: 'priorityAsigned',
                                                                    }}
                                                                >
                                                                    <option value=""/>
                                                                    {prioritys.map(function (value) {
                                                                        return (<option key={value.name}
                                                                                        value={value.id}> {value.name}</option>);
                                                                    })}
                                                                </Select>
                                                            </FormControl>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={6} md={6}>
                                                            <FormControl required fullWidth>
                                                                <InputLabel htmlFor="categoryAsigned">Categoría</InputLabel>
                                                                <Select
                                                                    required
                                                                    native
                                                                    value={state.categoryAsigned}
                                                                    onChange={(e) => handleChange(e)}
                                                                    inputProps={{
                                                                        name: 'categoryAsigned',
                                                                        id: 'categoryAsigned',
                                                                    }}>
                                                                    <option value=""/>
                                                                    {categorys.map(function (value) {
                                                                        return (<option key={value.id}
                                                                                        value={value.id}> {value.name}</option>);
                                                                    })}
                                                                </Select>
                                                            </FormControl>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={12} md={12}>
                                                            <br/>
                                                            <Button variant="contained" fullWidth={true} type="submit"
                                                                    color="primary">Enviar información</Button>
                                                        </GridItem>
                                                    </GridContainer>
                                                </form>
                                            </CardContent>
                                        </Card>
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </>
                    )
                }

            </div>
        );
    }

    return (
        <div>

            {
                state.ticket && (
                    <>

                        {
                            viewerIsOpen &&
                            <ModalGateway>
                                <Modal
                                    styles={{
                                        positioner: base => ({
                                            ...base,
                                            zIndex: 10,
                                        }),
                                    }}
                                    allowFullscreen={true}
                                    onClose={closeLightbox}>
                                    <Carousel
                                        currentIndex={currentImage}
                                        views={
                                            [{src: "data:image/" + image.fileType + ";base64," + image.data}]
                                        }
                                    />
                                </Modal>
                            </ModalGateway>
                        }
                        <Dialog open={openAutocomplete} onClose={handleCloseAutocomplete} fullWidth={true}>
                            <DialogTitle id="form-dialog-title">Agregar razón social</DialogTitle>
                            <DialogContent>
                                <FormControl required={true} fullWidth>
                                    <Autocomplete
                                        loading={loadingAutocomplete}
                                        noOptionsText={"No se encontraron coincidencias"}
                                        options={options}
                                        loadingText={"Buscando..."}
                                        onKeyUp={handleChangeKey}
                                        value={clientsSelect}
                                        getOptionLabel={option => option.fiscalName + " (" + (option.tradeName) + ")"}
                                        renderTags={(value, {className, onDelete}) =>
                                            value.map((option, index) => (
                                                <Chip
                                                    key={option}
                                                    data-tag-index={index}
                                                    tabIndex={-1}
                                                    label={option.fiscalName}
                                                    className={className}
                                                    onDelete={onDelete}
                                                />
                                            ))
                                        }
                                        onChange={handleFormChange}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                placeholder="Razón social"
                                                fullWidth
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {loadingAutocomplete ? <CircularProgress size={20}/> : null}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseAutocomplete} color="primary">
                                    Cancelar
                                </Button>
                                <Button onClick={setClientsUser} color="secondary">
                                    Guardar
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog open={openConfirmOpen} onClose={handleCloseConfirmOpen}
                                keepMounted
                                aria-labelledby="alert-dialog-slide-title"
                                aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogTitle id="alert-dialog-slide-title">{"Mensaje de confirmación"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-slide-description">
                                    ¿Está seguro que desea abrir esta solicitud?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseConfirmOpen} color="primary">
                                    Cancelar
                                </Button>
                                <Button onClick={openTicket} color="primary">
                                    Aceptar
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            open={openHasPhysicalSheet}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Aviso"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    ¿La solicitud cuenta con hoja de servicio física?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseItHasPhysicalServiceSheet} color="primary">
                                    No
                                </Button>
                                <Button onClick={handleItHasPhysicalServiceSheet} color="primary" autoFocus>
                                    Si
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog open={openConfirm} onClose={handleCloseConfirm}
                                keepMounted
                                aria-labelledby="alert-dialog-slide-title"
                                aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogTitle id="alert-dialog-slide-title">{"Mensaje de confirmación"}</DialogTitle>
                            <form onSubmit={closeTicket}>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-slide-description">
                                        ¿Está seguro que desea cerrar esta solicitud? Antes de hacerlo debe llenar la siguiente
                                        información.
                                    </DialogContentText>


                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={state.checkedIntern}
                                                onChange={handleCheckboxChange()}
                                                name="checkedIntern"
                                                color="primary"
                                            />
                                        }
                                        label="Solicitud interna (asesor-asesor)"
                                    />

                                    <TextField
                                        autoFocus
                                        required
                                        margin="dense"
                                        id="hours"
                                        name="hours"
                                        value={state.hours}
                                        onChange={(e) => handleChange(e)}
                                        label="Horas dedicadas"
                                        type="number"
                                        fullWidth
                                        InputProps={{
                                            name: "hours",
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <TimerIcon/>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <TextField
                                        required
                                        margin="dense"
                                        id="workDescription"
                                        name="workDescription"
                                        value={state.workDescription}
                                        onChange={(e) => handleChange(e)}
                                        label="Descripción del trabajo"
                                        fullWidth
                                        type="text"
                                        InputProps={{
                                            name: "workDescription",
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <VpnKeyIcon/>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <FormControl fullWidth required>
                                        <InputLabel htmlFor="system">Tipo de Sistema</InputLabel>
                                        <Select
                                            native
                                            fullWidth
                                            value={state.system}
                                            onChange={(e) => handleChange(e)}
                                            required={true}
                                            inputProps={{
                                                required: true,
                                                name: 'system',
                                                id: 'system'
                                            }}>
                                            <option value=""></option>
                                            {systems.map(function (value) {
                                                return (<option key={value.Cve} value={value.Cve} > {value.Des}</option>);
                                                // El numero del hardware es Cve 37
                                            })}
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth required>
                                        <InputLabel htmlFor="service">Tipo de Servicio</InputLabel>
                                        <NativeSelect
                                            native="true"
                                            fullWidth
                                            value={state.service}
                                            onChange={(e) => handleChange(e)}
                                            required={true}
                                            inputProps={{
                                                required: true,
                                                name: 'service',
                                                id: 'service'
                                            }}>
                                            <option value=""/>
                                            {services.map(function (value) {
                                                return (<option key={value.Cve} value={value.Cve}> {value.Des}</option>);
                                            })}
                                        </NativeSelect>
                                    </FormControl>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleCloseConfirm} color="primary">
                                        Cancelar
                                    </Button>
                                    <Button type={"submit"} color="primary">
                                        Aceptar
                                    </Button>
                                </DialogActions>
                            </form>
                        </Dialog>

                        {/* Aqui termina el dialog para cerrar la solicitud */}

                        <Dialog open={openOwners} onClose={handleCloseOwners}>
                            <form onSubmit={reassignedReport} className={classes.root} autoComplete="off">
                                <DialogTitle id="form-dialog-title">Reasignar responsable</DialogTitle>
                                <DialogContent>
                                    <FormControl fullWidth required>
                                        <InputLabel htmlFor="ownerAsigned">Responsable</InputLabel>
                                        <Select
                                            required
                                            native
                                            value={state.ownerAsigned}
                                            onChange={(e) => handleChange(e)}
                                            inputProps={{
                                                name: 'ownerAsigned',
                                                id: 'ownerAsigned',
                                            }}
                                        >
                                            <option value=""/>
                                            {owners.map(function (value) {
                                                return (<option key={value.id} value={value.id}> {value.completeName}</option>);
                                            })}
                                        </Select>
                                    </FormControl>

                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleCloseOwners} color="primary">
                                        Cancelar
                                    </Button>
                                    <Button type={"submit"} color="primary">
                                        Aceptar
                                    </Button>
                                </DialogActions>
                            </form>
                        </Dialog>

                        <Dialog open={open} onClose={handleClose}
                                keepMounted
                                aria-labelledby="alert-dialog-slide-title"
                                aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogTitle id="alert-dialog-slide-title">{"Mensaje de confirmación"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-slide-description">
                                    ¿Está seguro que desea asignarse esta solicitud?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="primary">
                                    Cancelar
                                </Button>
                                <Button onClick={assingnedTicket} color="primary">
                                    Aceptar
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog open={serviceSheet} onClose={handleCloseServiceSheetClose}>
                            <DialogTitle id="form-dialog-title">
                                <Typography variant={"h6"} align={"center"}>Generar hoja de servicio</Typography>

                    <Divider style={{marginTop: "10px", marginBottom: "10px"}}/>
                    {(state.ticket.client ? state.ticket.client.systemPolicy : false) && !(state.ticket.client ? state.ticket.client.supportPolicy : false) && !(state.ticket.client ? state.ticket.client.upgradePolicy : false) &&
                        <Typography gutterBottom color={"primary"}>Cliente con póliza de Sistemas</Typography>}
                    {(state.ticket.client ? state.ticket.client.supportPolicy : false) && !(state.ticket.client ? state.ticket.client.systemPolicy : false) && !(state.ticket.client ? state.ticket.client.upgradePolicy : false) &&
                        <Typography gutterBottom color={"primary"}>Cliente con póliza de Soporte</Typography>}
                    {(state.ticket.client ? state.ticket.client.supportPolicy : false) && (state.ticket.client ? state.ticket.client.systemPolicy : false) && !(state.ticket.client ? state.ticket.client.upgradePolicy : false) &&
                        <Typography gutterBottom color={"primary"}>Cliente con póliza de Soporte y
                            Sistemas</Typography>}
                    {!(state.ticket.client ? state.ticket.client.supportPolicy : false) && !(state.ticket.client ? state.ticket.client.systemPolicy : false) && !(state.ticket.client ? state.ticket.client.upgradePolicy : false) &&
                        <Typography gutterBottom color={"primary"}>Cliente sin póliza</Typography>}
                    {!(state.ticket.client ? state.ticket.client.systemPolicy : false) && !(state.ticket.client ? state.ticket.client.supportPolicy : false) && (state.ticket.client ? state.ticket.client.upgradePolicy : false) &&
                        <Typography gutterBottom color={"primary"}>Cliente con póliza de Actualización</Typography>}
                    {(state.ticket.client ? state.ticket.client.systemPolicy : false) && !(state.ticket.client ? state.ticket.client.supportPolicy : false) && (state.ticket.client ? state.ticket.client.upgradePolicy : false) &&
                        <Typography gutterBottom color={"primary"}>Cliente con póliza de Sistemas y
                            actualización</Typography>}
                    {!(state.ticket.client ? state.ticket.client.systemPolicy : false) && (state.ticket.client ? state.ticket.client.supportPolicy : false) && (state.ticket.client ? state.ticket.client.upgradePolicy : false) &&
                        <Typography gutterBottom color={"primary"}>Cliente con póliza de Soporte y
                            Actualización</Typography>}
                    {(state.ticket.client ? state.ticket.client.systemPolicy : false) && (state.ticket.client ? state.ticket.client.supportPolicy : false) && (state.ticket.client ? state.ticket.client.upgradePolicy : false) &&
                        <Typography gutterBottom color={"primary"}>Cliente con póliza de Soporte, Sistemas y
                            Actualización</Typography>}

                                {/* Antes de la comparacion entre las polizas de area vs las polizas de cliente */}
                                {/* {(state.ticket.client ? state.ticket.department.systemPolicy : false) && !(state.ticket.client ? state.ticket.department.supportPolicy : false) && !(state.ticket.client ? state.ticket.department.upgradePolicy : false) &&
                        <Typography gutterBottom color={"primary"}>Cliente con póliza de Sistemas</Typography>}
                    {(state.ticket.client ? state.ticket.department.supportPolicy : false) && !(state.ticket.client ? state.ticket.department.systemPolicy : false) && !(state.ticket.client ? state.ticket.department.upgradePolicy : false) &&
                        <Typography gutterBottom color={"primary"}>Cliente con póliza de Soporte</Typography>}
                    {(state.ticket.client ? state.ticket.department.supportPolicy : false) && (state.ticket.client ? state.ticket.department.systemPolicy : false) && !(state.ticket.client ? state.ticket.department.upgradePolicy : false) &&
                        <Typography gutterBottom color={"primary"}>Cliente con póliza de Soporte y
                            Sistemas</Typography>}
                    {!(state.ticket.client ? state.ticket.department.supportPolicy : false) && !(state.ticket.client ? state.ticket.department.systemPolicy : false) && !(state.ticket.client ? state.ticket.department.upgradePolicy : false) &&
                        <Typography gutterBottom color={"primary"}>Cliente sin póliza</Typography>}
                    {!(state.ticket.client ? state.ticket.department.systemPolicy : false) && !(state.ticket.client ? state.ticket.department.supportPolicy : false) && (state.ticket.client ? state.ticket.department.upgradePolicy : false) &&
                        <Typography gutterBottom color={"primary"}>Cliente con póliza de Actualización</Typography>}
                    {(state.ticket.client ? state.ticket.department.systemPolicy : false) && !(state.ticket.client ? state.ticket.department.supportPolicy : false) && (state.ticket.client ? state.ticket.department.upgradePolicy : false) &&
                        <Typography gutterBottom color={"primary"}>Cliente con póliza de Sistemas y
                            actualización</Typography>}
                    {!(state.ticket.client ? state.ticket.department.systemPolicy : false) && (state.ticket.client ? state.ticket.department.supportPolicy : false) && (state.ticket.client ? state.ticket.department.upgradePolicy : false) &&
                        <Typography gutterBottom color={"primary"}>Cliente con póliza de Soporte y
                            Actualización</Typography>}
                    {(state.ticket.client ? state.ticket.department.systemPolicy : false) && (state.ticket.client ? state.ticket.department.supportPolicy : false) && (state.ticket.client ? state.ticket.department.upgradePolicy : false) &&
                        <Typography gutterBottom color={"primary"}>Cliente con póliza de Soporte, Sistemas y
                            Actualización</Typography>} */}

                            </DialogTitle>

                            <DialogContent>

                                <form className={classes.root} autoComplete="off" onSubmit={generateServiceSheet}>
                                    <FormControl fullWidth required>
                                        {
                                            ((state.ticket.client ? state.ticket.department.systemPolicy : false) || (state.ticket.client ? state.ticket.department.supportPolicy : false)) &&
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        disabled={state.checkedF}
                                                        checked={state.checkedF}
                                                        onChange={handleCheckboxChange()}
                                                        name="checkedF"
                                                        color="primary"
                                                    />
                                                }
                                                label="Generar sin Costo"
                                            />

                            }
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={state.checkedPSS}
                                        disabled={state.checkedIntern}
                                        onChange={handleCheckboxChange()}
                                        name="checkedPSS"
                                        color="primary"
                                    />
                                }
                                label="Tiene Hoja de Servicio Física"
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={state.hasSaleNumber}
                                        // disabled={state.hasSaleNumber}
                                        onChange={handleCheckboxChangeValidateSaleNumber()}
                                        name="hasSaleNumber"
                                        color="primary"
                                    />
                                }
                                label="Tiene Folio de Venta"
                            />
                            {state.hasSaleNumber ?
                                <TextField
                                    autoFocus
                                    required
                                    error={validatedClient}
                                    helperText={validatedClient ? messageValidation : messageValidation}
                                    margin="dense"
                                    id="saleNumber"
                                    name="saleNumber"
                                    value={state.saleNumber}
                                    onChange={(e) => handleChange(e)}
                                    label="Folio de venta"
                                    type="text"
                                    fullWidth
                                /> : null}
                            {state.hasSaleNumber ?
                                <TextField
                                    autoFocus
                                    error={validatedClient}
                                    helperText={validatedClient ? messageValidation : messageValidation}
                                    required
                                    margin="dense"
                                    id="clientNumber"
                                    name="clientNumber"
                                    value={state.clientNumber}
                                    onChange={(e) => handleChange(e)}
                                    label="Número de cliente"
                                    type="text"
                                    fullWidth
                                /> : null}
                            {state.hasSaleNumber ?
                                <Button onClick={validateClientAndSaleNumber} color="primary">
                                    Validar
                                </Button> : null}
                            <FormControl fullWidth required={true}>
                                <Autocomplete
                                    loading={loadingAutocomplete}
                                    noOptionsText={"No se encontraron coincidencias"}
                                    options={serviceSheetType}
                                    loadingText={"Buscando..."}
                                    onKeyUp={handleAutocompleteChange}
                                    value={serviceSheetTypeSelect}
                                    getOptionLabel={option => option.id + " " + option.name}
                                    onChange={handleServiceSheetTypeChange}
                                    autoSelect

                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        required={true}
                                                        margin="normal"
                                                        placeholder="Tipo de hoja de servicio"
                                                        fullWidth
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {loadingAutocomplete ?
                                                                        <CircularProgress color="inherit" size={20}/> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </FormControl>

                                        <TextField
                                            autoFocus
                                            disabled={!state.checkedPSS}
                                            required
                                            margin="dense"
                                            id="physicalServiceSheetFolio"
                                            name="physicalServiceSheetFolio"
                                            value={state.physicalServiceSheetFolio}
                                            onChange={(e) => handleChange(e)}
                                            label="Folio de Hoja de Servicio Física"
                                            type="number"
                                            InputProps={{
                                                startAdornment: <InputAdornment
                                                    position="start">{serviceSheetTypeSelect && serviceSheetTypeSelect.id}</InputAdornment>,
                                            }}
                                            fullWidth
                                        />

                                        <TextField
                                            autoFocus
                                            disabled={!state.checkedF}
                                            required
                                            margin="dense"
                                            id="reason"
                                            name="reason"
                                            value={state.reason}
                                            onChange={(e) => handleChange(e)}
                                            label="Razón"
                                            type="text"
                                            fullWidth
                                        />
                                        <TextField
                                            autoFocus
                                            disabled={state.checkedF}
                                            required={!state.checkedF}
                                            margin="dense"
                                            id="amount"
                                            name="amount"
                                            value={state.amount}
                                            onChange={amountChange()}
                                            error={state.amountError}
                                            label="Importe"
                                            type="number"
                                            fullWidth
                                            InputProps={{
                                                name: "amount",
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <AttachMoneyIcon/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </FormControl>

                        <DialogActions>
                            <Button onClick={handleCloseServiceSheetClose} color="primary">
                                Cancelar
                            </Button>
                            <Button disabled={saleNumber} type={"submit"} color="primary">
                                Aceptar
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent>

            </Dialog>
            <TablePagination onPageChange={(e, page) => getTicketId(props[0].location?.state.ids[page])}
                             nextIconButtonText={"Siguente solicitud"} backIconButtonText={"Anterior solicitud"}
                             component={"div"} rowsPerPageOptions={[]} labelRowsPerPage={''}
                             count={props[0].location?.state.ids.length}
                             page={props[0].location?.state.ids.indexOf(state.ticket.id)} labelDisplayedRows={({
                                                                                                                   from,
                                                                                                                   to,
                                                                                                                   count,
                                                                                                                   page
                                                                                                               }) => `${page + 1} / ${count}`}/>


                        <GridContainer>
                            <Grid container={true}>
                                <Card>
                                    <CardHeader color="secondary">
                                        <Grid container={true} direction="row" alignItems="flex-start">
                                            <Grid item={true} xs={12} sm={12} md={5}>
                                                <h4 className={classes.cardTitleWhite}>#{state.ticket.ticketNumber}</h4>
                                            </Grid>
                                            <Grid item={true} xs={12} sm={12} md={7}>
                                                <div align="right">
                                                    { !hasRole("CORPORATIVE") && createButtons()}
                                                    {/* <ButtonGroup color="primary" variant="contained" size="small" aria-label="small contained button group">
                        {
                          (hasRole("SUPER_USER") || hasRole("ADMIN")) && !state.ticket.closed && (
                            <Button onClick={handleClickOpenAutocomplete} >
                              <PersonIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                              Asignar Razón social
                            </Button>
                          )
                        }
                        {
                          createButtons()
                        }
                        {
                          state.ticket.closed ? (
                            <Button onClick={handleClickOpenConfirmOpen} color="secondary">
                              <LockOpenIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                              Abrir
                            </Button>
                          ) : (
                            <Button color="secondary" onClick={handleClickOpenConfirm}>
                              <LockIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                              Cerrar
                            </Button>
                          )
                        }{
                          state.ticket.closed ? (
                            <Button onClick={handleServiceSheetClick} color="primary">
                              <LockOpenIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                              Generar Hoja deServicio
                            </Button>
                          ) : (
                            <div></div>
                          )
                        }
                        {
                          hasRole("ADMIN") && !state.ticket.closed && (
                            <Button style={{backgroundColor: "#789D5B"}}
                              onClick={handleClickOpen}>
                              <PersonIcon
                              className={clsx(classes.leftIcon, classes.iconSmall)}/>
                              Asignármelo
                            </Button>
                          )
                        }
                        {
                          hasRole("SUPER_USER") && !state.ticket.closed && (
                            <Button style={{backgroundColor: "#789D5B"}}
                              onClick={handleClickOpen}>
                              <PersonIcon
                              className={clsx(classes.leftIcon, classes.iconSmall)}/>
                              Asignármelo
                            </Button>
                          )
                        }
                      </ButtonGroup> */}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </CardHeader>
                                    <CardBody>
                                        <Grid container={true} spacing={1} direction="row">
                                            <Grid item={true} xl={6} md={6} xs={6}>
                                                <Typography style={{display: 'inline-block'}}
                                                            className={classes.boldText}>Estatus: </Typography> <Typography
                                                style={{display: 'inline-block'}}
                                                gutterBottom>{state.ticket.ticketStatus ? (state.ticket.ticketStatus.name) : ""}</Typography>
                                            </Grid>
                                            <Grid item={true} xl={6} md={6} xs={6}>
                                                <Typography style={{display: 'inline-block'}}
                                                            className={classes.boldText}>Enviado: </Typography> <Typography
                                                style={{display: 'inline-block'}}
                                                gutterBottom>{state.ticket.createDate}</Typography>
                                            </Grid>

                                            <Grid item={true} xl={6} md={6} xs={6}>
                                                <Typography style={{display: 'inline-block'}}
                                                            className={classes.boldText}>Solicitante: </Typography> <Typography
                                                style={{display: 'inline-block'}}
                                                gutterBottom>{state.ticket.author ? (state.ticket.author.completeName) : ""}</Typography>
                                            </Grid>

                                            <Grid item={true} xl={6} md={6} xs={6}>
                                                <Typography style={{display: 'inline-block'}}
                                                            className={classes.boldText}>Desde: </Typography> <Typography
                                                style={{display: 'inline-block'}}
                                                gutterBottom>{state.ticket.location ? state.ticket.location : "Sin registrar"}</Typography>
                                            </Grid>

                                            <Grid item={true} xl={6} md={6} xs={6}>
                                                <Typography style={{display: 'inline-block'}}
                                                            className={classes.boldText}>Email: </Typography> <Typography
                                                style={{display: 'inline-block'}}
                                                gutterBottom>{state.ticket.author ? (state.ticket.author.email) : "Sin correo"}</Typography>
                                            </Grid>

                                            <Grid item={true} xl={6} md={6} xs={6}>
                                                <Typography style={{display: 'inline-block'}}
                                                            className={classes.boldText}>Asesor: </Typography> <Typography
                                                style={{display: 'inline-block'}}
                                                gutterBottom>{state.ticket.owner ? (state.ticket.owner.name) : "Sin asignar"}</Typography>
                                            </Grid>

                                            <Grid item={true} xl={6} md={6} xs={6}>
                                                <Typography style={{display: 'inline-block'}}
                                                            className={classes.boldText}>Teléfono: </Typography> <Typography
                                                style={{display: 'inline-block'}}
                                                gutterBottom>{state.ticket.author ? (state.ticket.author.phone) : "Sin teléfono"}</Typography>
                                            </Grid>

                                            <Grid item={true} xl={6} md={6} xs={6}>
                                                <Typography style={{display: 'inline-block'}}
                                                            className={classes.boldText}>Prioridad: </Typography> <Typography
                                                style={{display: 'inline-block'}} className={classes.boldText}> </Typography>
                                                {editPriority ?
                                                    <div>
                                                        <Select
                                                            required
                                                            native
                                                            value={state.priorityAsigned}
                                                            onChange={(e) => handleChange(e)}
                                                            inputProps={{
                                                                name: 'priorityAsigned',
                                                                id: 'priorityAsigned',
                                                            }}
                                                        >
                                                            <option value=""/>
                                                            {prioritys.map(function (value) {
                                                                return (<option key={value.name}
                                                                                value={value.id}> {value.name}</option>);
                                                            })}
                                                        </Select>
                                                        <ButtonBase><SaveIcon onClick={() => changePriorityAction()}/></ButtonBase>
                                                        <ButtonBase
                                                            onClick={() => setEditPriority(false)}><CancelIcon/></ButtonBase>
                                                    </div>
                                                    :
                                                    <Typography style={{display: 'inline-block'}} gutterBottom>
                                                        {state.ticket?.priority?.name ?? ''}
                                                        {hasRole("SUPER_USER") && !state.serviceSheetGenerated && !state.ticket.isPhysicalServiceSheet &&
                                                            <ButtonBase onClick={() => setEditPriority(true)}>
                                                                <EditIcon fontSize={"small"}/>
                                                            </ButtonBase>
                                                        } </Typography>}
                                            </Grid>
                                            <Grid item={true} xl={6} md={6} xs={6}>
                                                <Typography style={{display: 'inline-block'}}
                                                            className={classes.boldText}>Área: </Typography> <Typography
                                                style={{display: 'inline-block'}}
                                                gutterBottom>{state.ticket.department ? (state.ticket.department.name) : ""}</Typography>
                                            </Grid>

                                            {state.ticket.closedDate && (
                                                <Grid item={true} xl={6} md={6} xs={6}>
                                                    <Typography style={{display: 'inline-block'}}
                                                                className={classes.boldText}>Cerrado: </Typography> <Typography
                                                    style={{display: 'inline-block'}}
                                                    gutterBottom>{state.ticket.closedDate}</Typography>
                                                </Grid>
                                            )}
                                            <Grid item={true} xl={6} md={6} xs={6}>
                                                <Typography style={{display: 'inline-block'}} className={classes.boldText}>Razón
                                                    social: </Typography> <Typography style={{display: 'inline-block'}}
                                                                                      gutterBottom>{state.ticket.client ? state.ticket.client.fiscalName : "Sin asignar"}</Typography>
                                            </Grid>

                                            {state.ticket.closed && (
                                                <Grid item={true} xl={6} md={6} xs={6}>
                                                    <Typography style={{display: 'inline-block'}} className={classes.boldText}>Horas
                                                        dedicadas: </Typography> <Typography style={{display: 'inline-block'}}
                                                                                             gutterBottom></Typography>
                                                    {editWorkedHours ?
                                                        <div>
                                                            <TextField
                                                                autoFocus
                                                                required
                                                                margin="dense"
                                                                id="hours"
                                                                name="hours"
                                                                value={state.hours}
                                                                onChange={(e) => handleChange(e)}
                                                                label="Horas dedicadas"
                                                                type="number"
                                                                width={100}
                                                                InputProps={{
                                                                    name: "hours",
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <TimerIcon/>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                            <ButtonBase><SaveIcon
                                                                onClick={() => changeWorkedHoursAction()}/></ButtonBase>
                                                            <ButtonBase
                                                                onClick={() => setEditWorkedHours(false)}><CancelIcon/></ButtonBase>
                                                        </div>
                                                        :
                                                        <Typography style={{display: 'inline-block'}} gutterBottom>
                                                            {state.ticket.timeWork}
                                                            {hasRole("SUPER_USER") && !state.serviceSheetGenerated && !state.ticket.isPhysicalServiceSheet &&
                                                                <ButtonBase onClick={() => setEditWorkedHours(true)}>
                                                                    <EditIcon fontSize={"small"}/>
                                                                </ButtonBase>
                                                            } </Typography>}
                                                </Grid>
                                            )}

                                            {state.ticket.closed && (
                                                <Grid item={true} xl={6} md={6} xs={6}>
                                                    <Typography style={{display: 'inline-block'}}
                                                                className={classes.boldText}>Servicio: </Typography> <Typography
                                                    style={{display: 'inline-block'}}
                                                    gutterBottom> {showClosedTicketData(1)} </Typography>
                                                </Grid>
                                            )}

                                            {state.ticket.closed && (
                                                <Grid item={true} xl={6} md={6} xs={6}>
                                                    <Typography style={{display: 'inline-block'}}
                                                                className={classes.boldText}>Sistema: </Typography> <Typography
                                                    style={{display: 'inline-block'}}
                                                    gutterBottom> {showClosedTicketData(2)} </Typography>
                                                </Grid>
                                            )}

                                            {state.ticket.closed && !state.ticket.isPhysicalServiceSheet && (
                                                <Grid item={true} xl={6} md={6} xs={6}>
                                                    <Typography style={{display: 'inline-block'}} className={classes.boldText}>Folio
                                                        de Hoja de Servicio: </Typography> <Typography
                                                    style={{display: 'inline-block'}}
                                                    gutterBottom> {showClosedTicketData(3)} </Typography>
                                                </Grid>
                                            )}

                                {state.ticket.closed && state.ticket.isPhysicalServiceSheet && (
                                    <Grid item={true} xl={6} md={6} xs={6}>
                                        <Typography style={{display: 'inline-block'}} style={{color: 'red'}}
                                                    className={classes.boldText}>Folio de Hoja de Servicio
                                            Física: </Typography> <Typography style={{display: 'inline-block'}}
                                                                              style={{color: 'red'}}
                                                                              gutterBottom> {showClosedTicketData(4)} </Typography>
                                    </Grid>
                                )}

                                <Grid item={true} xl={6} md={6} xs={6}>
                                    <Typography style={{display: 'inline-block'}}
                                                className={classes.boldText}>Cuenta con folio de venta: </Typography>
                                    <Typography
                                        style={{display: 'inline-block'}}
                                        gutterBottom>{state.ticket.hasSaleNumber ? "SÍ" : "NO"}</Typography>
                                </Grid>

                                {state.ticket.closed && state.ticket.hasSaleNumber && (
                                    <Grid item={true} xl={6} md={6} xs={6}>
                                        <Typography style={{display: 'inline-block'}} className={classes.boldText}>Folio
                                            de venta: </Typography> <Typography
                                        style={{display: 'inline-block'}}
                                        gutterBottom> {state.ticket.saleNumber} </Typography>
                                    </Grid>
                                )}

                                        </Grid>
                                    </CardBody>
                                </Card>
                                <Paper className={classes.paper}>
                                    <Typography component={'div'} variant="body2" gutterBottom>
                                        {state.ticket.content}
                                        <br/>
                                        {state.ticket.hasFile &&
                                            <Link color={"primary"} onClick={getTicketImage}>
                                                Ver Imagen

                                            </Link>
                                        }
                                    </Typography>
                                </Paper>
                                <Card>
                                    <CardHeader color="primary">
                                        <h4 className={classes.cardTitleWhite}>Comentarios</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <Messenger mesagges={activitys}
                                                   idAdmin={state.ticket.author ? (state.ticket?.author?.id ?? false) : false}
                                                   studentView={false}/>

                                        {
                                            state.ticket.ticketStatus.id !== 7 && <Paper className={classes.paper}>
                                                <form onSubmit={() => commentTicket()}>
                                                    <GridContainer>
                                                        <GridItem xs={12} sm={12} md={3}>
                                                            <FilePond
                                                                id="contained-button-file"
                                                                files={files}
                                                                allowMultiple={true}
                                                                maxFiles={3}
                                                                allowImagePreview={false}
                                                                imagePreviewHeight={170}
                                                                onwarning={() => Alert.info("Solo puedes adjuntar 3 imagenes al comentario")}
                                                                onupdatefiles={(filesInput) => {
                                                                    setFiles(filesInput.map(fileItem => fileItem.file))
                                                                }}
                                                                acceptedFileTypes={['image/*']}
                                                                labelIdle='<span class="filepond--label-action">Adjuntar archivos</span>'
                                                            />
                                                        </GridItem>
                                                        <GridItem xs={12} sm={12} md={8}>
                                                            <InputLabel htmlFor="comment">Comentario</InputLabel>
                                                            <TextField
                                                                required={true}
                                                                id="comment"
                                                                name="comment"
                                                                value={state.comment}
                                                                onChange={(e) => handleChange(e)}
                                                                style={{margin: 8}}
                                                                placeholder="Escriba su comentario"
                                                                fullWidth
                                                                multiline
                                                                margin="normal"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        </GridItem>
                                                    </GridContainer>
                                                    <div align="right">
                                                        <Button type={"submit"} variant="contained" color="primary"
                                                                className={classes.button}>
                                                            Enviar
                                                            <Icon className={classes.rightIcon}>send</Icon>
                                                        </Button>
                                                    </div>
                                                </form>
                                            </Paper>
                                        }

                                    </CardBody>
                                </Card>
                            </Grid>
                            {window.scrollTo(0, window.innerWidth)}
                        </GridContainer>
                    </>
                )
            }
        </div>
    );
}

