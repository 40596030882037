import React, {useEffect} from "react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import {primaryColor, secondaryColor} from "../../../assets/jss/material-dashboard-react";
import {TraceSpinner} from "react-spinners-kit";
import {FilePond, registerPlugin} from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import {
    Button, CircularProgress, FormControl,
    Icon,
    InputLabel,
    NativeSelect, TextField,
} from "@material-ui/core";
import {
    API_AUTENTICATION_HEADERS,
    API_BASE_URL,
    API_BASIC_HEADERS,
} from "../../../assets/constants";
import axios from "axios";
import Alert from "react-s-alert";
import { Redirect } from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import {getCurrentUser, hasRole} from "../../../util/Contexts/UserService";
import {handleError} from "../../../util/Contexts/ErrorContext";
import {getAllCategoryByDepartment} from "../../../util/Contexts/CategoryContext";
import {createTicket} from "../../../util/Contexts/TicketContext";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType);
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: primaryColor,
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    button: {
        margin: "dense",
    },
    rightIcon: {
        marginLeft: "10px",
    }
};


const useStyles = makeStyles(styles);


export default function CreateTicket() {

    const classes = useStyles();
    const [departments, setDepartments] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [newUser, setNewUser] = React.useState(false);
    const [owners, setOwners] = React.useState([]);
    const [clientsSelect, setClientsSelect] = React.useState(null);
    const [prioritys, setPrioritys] = React.useState([]);
    const [categorys, setCategorys] = React.useState([]);
    const [files, setFiles] = React.useState([]);
    const [options, setOptions] = React.useState([]);
    const [userSelect, setUserSelect] = React.useState( {});
    const [users, setUsers] = React.useState([]);
    const [loadingAutocomplete, setLoadingAutocomplete] = React.useState(false);



    const [state, setState] = React.useState({
        name: "",
        lastname: "",
        phone: "",
        email: "",
        content: "",
        branch_office: "",
        ownerSelected: 0,
        department: 0,
        prioritySelected: 0,
        departmentSelected: 0,
        categoryAsigned: 0,
        redirect: false
    });

    const getAllDepartmets = () => {

        const url = API_BASE_URL + "/api/departments/enabled";
        const headers = API_AUTENTICATION_HEADERS;

        axios.get(url, { headers } ).then(respuesta => {
            setDepartments(respuesta.data.data);
        } ).catch(error => {
            handleError(error);
        });
    };

    const getAllPrioritys = async () => {
        const url = API_BASE_URL + "/api/prioritys/enabled";
        const headers = API_AUTENTICATION_HEADERS;
        axios.get(url, {headers}).then(respuesta => {
            setPrioritys(respuesta.data.data);
        }).catch(error => {
            handleError(error);
        });
    };

    const getAllCategory= async (id) => {
        getAllCategoryByDepartment(id).then(respuesta => {
            setCategorys(respuesta.data.data);
        }).catch(error => {
            handleError(error);
        });
    };

    const getOwners = (id) => {
        const url = API_BASE_URL + "/api/users/department/" + id;
        const headers = API_AUTENTICATION_HEADERS;
        axios.get(url, {headers}).then(respuesta => {
            setOwners(respuesta.data.data);

        }).catch(error => {
            handleError(error);
        });
    };

    const handleDepartementChange = () => event => {
        setState({
            ...state,
            [event.target.name] : event.target.value
        });
        getAllCategory(event.target.value);
        getOwners(event.target.value);
    };

    const handleChange = event => {
        event.stopPropagation();
        setState({
            ...state,
            [event.target.name] : event.target.value
        });
    };

    function getData(){
        getAllDepartmets();
        getAllPrioritys();
       if (hasRole("CORPORATIVE")){
           getAutocompleteUserSugestion("");
           getCurrentUser().then(({data}) => {
               console.log(data)
               setUserSelect(data)
               setOptions(data.companyNames)
           })
       }
    }

    useEffect(value => {
        getData();
    }, []);

    const handleAutocompleteChange = event => {
        getAutocompleteSugestion(event.target.value);
    };

    const handleAutocompleteUserChange = event => {
        getAutocompleteUserSugestion(event.target.value);
    };


    function createReport(event) {
        event.preventDefault();
        createTicket(state, userSelect, newUser, files, departments, clientsSelect).then(respuesta => {
            if (respuesta.data.success) {
                Alert.success(respuesta.data.message);
                setState({
                    ...state,
                    redirect: true
                });
            }else {
                Alert.warning(respuesta.data.message || "Error al conectarse al servidor");
            }
        });



    }

    const handlePhoneChange = () => event => {
        const onlyNums = event.target.value.replace(/[^0-9]/g, '');
        if (onlyNums.length < 10) {
            setState({...state, phone: onlyNums });
        } else if (onlyNums.length === 10) {
            const number = onlyNums.replace(
                /(\d{3})(\d{3})(\d{4})/,
                '($1) $2-$3'
            );
            setState({ ...state,
                phone: number });
        }
    }

    const handleFormChange = (event, value) => {
        setClientsSelect(value);
        if(value != null){
            setState({
            ...state,
            email : value.email,
            phone : value.number
        });
        } else {
            setState({
                ...state,
                email : "",
                phone : ""
            });
        }
        if (!hasRole("CORPORATIVE")) {
            setOptions([]);
        }
    };

    const handleFormUserChange = (event, value) => {
        if (value != null) {
            setUserSelect(value);
            if (!hasRole("CORPORATIVE")) {
                setOptions(value.companyNames);
                setUsers([]);
            }
        } else {
            setUserSelect({});
            if (!hasRole("CORPORATIVE")) {
                setOptions([]);
                setUsers([]);
            }
        }
    };

    if (state.redirect) {
        return <Redirect push to={"/admin/request"} push={true} />
    }

    function userForm() {
        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        name="name"
                        value={state.name}
                        onChange={handleChange}
                        label="Nombre"
                        type="text"
                        fullWidth
                        required
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                    <TextField
                        margin="dense"
                        id="lastname"
                        name="lastname"
                        value={state.lastname}
                        onChange={handleChange}
                        label="Apellidos"
                        type="text"
                        fullWidth
                    />
                </GridItem>

                <GridItem xs={12} sm={12} md={3}>
                    <FormControl fullWidth required>
                        <TextField
                            margin="dense"
                            id="email"
                            name="email"
                            value={state.email}
                            onChange={handleChange}
                            label="Correo electrónico"
                            type="email"
                            InputLabelProps={{ shrink: true }}
                            required
                            fullWidth
                        />
                    </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                    <TextField
                        margin="dense"
                        id="phone"
                        name="phone"
                        value={state.phone}
                        onChange={handlePhoneChange()}
                        label="Teléfono"
                        type="text"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        required
                    />
                </GridItem >
                {(hasRole("ADMIN") || hasRole("SUPER_USER") || hasRole("CORPORATIVE")) &&
                    <GridItem xs={12} sm={12} md={6}>
                        <FormControl fullWidth required={hasRole("CORPORATIVE")}>
                            <Autocomplete
                                loading={loadingAutocomplete}
                                noOptionsText={"No se encontraron coincidencias"}
                                options={options}
                                loadingText={"Buscando..."}
                                onKeyUp={!hasRole("CORPORATIVE") ? handleAutocompleteChange : null}
                                value={clientsSelect}
                                getOptionLabel={option => option.fiscalName + " (" + (option.tradeName) + ")"}
                                onChange={handleFormChange}
                                autoSelect
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        margin="normal"
                                        placeholder={hasRole('CORPORATIVE') ? "Razón social *" : "Razón social"}
                                        fullWidth
                                        required={hasRole("CORPORATIVE")}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {loadingAutocomplete ?
                                                        <CircularProgress color="inherit" size={20}/> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </FormControl>
                    </GridItem>
                }

                    <GridItem xs={12} sm={12} md={6}>
                        <br/><br/>
                        {
                            !hasRole("CORPORATIVE") &&
                        "Nota: Recuerde informar al cliente que se le registró en la aplicación y que en caso de intentar ingresar a la misma deberá recuperar la contraseña con el correo proporcionado."
                        }
                        </GridItem>


                <GridItem xs={12} sm={12} md={6}>
                    <br/>
                <div align={"right"}>
                    <Button  variant="contained" color="primary" className={classes.button} onClick={() => setNewUser(false)}>
                        {
                            hasRole("CORPORATIVE") ? "REGRESAR" :
                        "REGRESAR A BÚSQUEDA DE CLIENTE"
                        }
                    </Button>
                </div>
                </GridItem>

            </GridContainer>
        );
    }

    function userSearch() {
        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={9}>
                    <FormControl fullWidth required>
                        <Autocomplete
                            loading={loadingAutocomplete}
                            noOptionsText={"No se encontraron coincidencias"}
                            options={users}
                            loadingText={"Buscando..."}
                            onKeyUp={!hasRole("CORPORATIVE") ? handleAutocompleteUserChange : (() => console.log(""))}
                            value={userSelect}
                            getOptionLabel={option => ( option.email && option.email + " (" + option.completeName + ")" )}
                            onChange={handleFormUserChange}
                            autoSelect
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    margin="normal"
                                    placeholder="Buscar usuario registrado"
                                    fullWidth
                                    required={true}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loadingAutocomplete ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </FormControl>
                    <FormControl fullWidth required={hasRole("CORPORATIVE")}>
                        <Autocomplete
                            loading={loadingAutocomplete}
                            noOptionsText={"No se encontraron coincidencias"}
                            options={options}
                            loadingText={"Buscando..."}
                            onKeyUp={handleAutocompleteChange}
                            value={clientsSelect}
                            getOptionLabel={option => (option.fiscalName + " (" + (option.tradeName) + ")")}
                            onChange={handleFormChange}
                            autoSelect
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    margin="normal"
                                    placeholder={hasRole('CORPORATIVE') ? "Razón social *" : "Razón social"}
                                    fullWidth
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loadingAutocomplete ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                    required={true}
                                />
                            )}
                        />
                    </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                    <Button fullWidth={true}  variant="contained" color="primary" className={classes.button} onClick={() => setNewUser(true)}>
                        {
                            hasRole("CORPORATIVE") ? "Alta de Usuario" : "Alta de Cliente"
                        }

                    </Button>
                </GridItem>
            </GridContainer>
        )
    }

    const getAutocompleteSugestion = (query) => {
        setLoadingAutocomplete(true);
        if(!(/^\s*$/m.test(query))){
        const url = API_BASE_URL + "/api/user/clients?query=" + query ;
        const headers = API_AUTENTICATION_HEADERS;
        axios.get(url, { headers } ).then(respuesta => {
            if (respuesta.data.success) {
                setOptions(respuesta.data.data.clients);
            }else {
                Alert.warning(respuesta.data.data);
            }
            setLoadingAutocomplete(false);
        } ).catch(error => {
            setLoadingAutocomplete(false);
        });
        } else {
            setOptions([]);
            setLoadingAutocomplete(false)
            return undefined;
        }

    };
    
    const getAutocompleteUserSugestion = (query) => {
        setLoadingAutocomplete(true);
        const url = API_BASE_URL + "/api/users?query=" + query ;
        const headers = API_AUTENTICATION_HEADERS;
        axios.get(url, { headers } ).then(respuesta => {
            if (respuesta.data.success) {
                setUsers(respuesta.data.data);
            }

            setLoadingAutocomplete(false);
        } ).catch(error => {

            setLoadingAutocomplete(false);
        });

    };

    return (
      <div>
        {loading ? (
          <div style={{
                position: 'absolute', left: '50%', top: '50%',
                transform: 'translate(-50%, -50%)'
            }}>
            <TraceSpinner
                size={80}
                frontColor={primaryColor[0]}
                backColor={secondaryColor[0]}
                loading={loading}/>
            </div>
        ) : (
          <form onSubmit={createReport}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="primary">
                    <div align="center">
                        {
                            hasRole("CORPORATIVE") ? "Información del usuario" : "Información del cliente"
                        }

                    </div>
                  </CardHeader>
                  <CardBody>
                    {
                      newUser && userForm()
                    }
                    {
                      !newUser &&  userSearch()
                    }
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="primary">
                    <div align="center">
                    Información de la solicitud
                      </div>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={9}>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <FormControl fullWidth required>
                              <InputLabel htmlFor="typeReport">Área</InputLabel>
                              <NativeSelect
                                native="true"
                                fullWidth
                                value={state.departmentSelected}
                                onChange={handleDepartementChange()}
                                required={true}
                                inputProps={{
                                  required: true,
                                  name: 'departmentSelected',
                                  id: 'typeReport'
                                }}>
                                <option value=""/>
                                {departments.map(function(value) { return (<option key={value.id} value={value.id} > {value.name}</option> );})}
                              </NativeSelect>
                            </FormControl >
                          </GridItem>
                            { !hasRole('CORPORATIVE') &&
                                <GridItem xs={12} sm={12} md={6}>
                                    <FormControl fullWidth required>
                                        <InputLabel htmlFor="asesorReport">Asesor asignado</InputLabel>
                                        <NativeSelect
                                            native="true"
                                            fullWidth
                                            required={!hasRole('CORPORATIVE')}
                                            value={state.ownerSelected}
                                            onChange={handleChange}
                                            inputProps={{
                                                required: true,
                                                name: 'ownerSelected',
                                                id: 'asesorReport'
                                            }}>
                                            <option value=""/>
                                            {owners.map(function(value) { return (<option key={value.id} value={value.id} > {value.completeName} </option> );})}
                                        </NativeSelect>
                                    </FormControl >
                                </GridItem>
                            }
                            { !hasRole('CORPORATIVE') &&
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormControl fullWidth required={true}>
                                        <InputLabel htmlFor="priorityReport">Prioridad</InputLabel>
                                        <NativeSelect
                                            native="true"
                                            fullWidth
                                            required={!hasRole('CORPORATIVE')}
                                            value={state.prioritySelected}
                                            onChange={handleChange}
                                            inputProps={{
                                                required: true,
                                                name: 'prioritySelected',
                                                id: 'priorityReport'
                                            }}>
                                            <option value=""/>
                                            {prioritys.map(function(value) { return (<option key={value.id} value={value.id} > {value.name}</option> );})}
                                        </NativeSelect>
                                    </FormControl>
                                </GridItem>
                            }
                            { !hasRole('CORPORATIVE') &&
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="categoryReport">Categoría</InputLabel>
                                        <NativeSelect
                                            native="true"
                                            fullWidth
                                            value={state.categoryAsigned}
                                            onChange={handleChange}
                                            inputProps={{
                                                name: 'categoryAsigned',
                                                id: 'categoryReport'
                                            }}>
                                            <option value=""/>
                                            {categorys.map(function(value) { return (<option key={value.id} value={value.id} > {value.name}</option> );})}
                                        </NativeSelect>
                                    </FormControl >
                                </GridItem>
                            }

                          <GridItem xs={12} sm={12} md={6}>
                            <FormControl fullWidth required>
                            <TextField
                                required
                                margin="dense"
                                id="branch_office"
                                name="branch_office"
                                value={state.branch_office}
                                onChange={handleChange}
                                label="Nombre Comercial"
                                type="text"
                                fullWidth
                              />
                            </FormControl >
                          </GridItem>
                        </GridContainer>

                        <FormControl fullWidth required>
                          <TextField
                            margin="dense"
                            id="content"
                            name="content"
                            value={state.content}
                            onChange={handleChange}
                            label="Escribe aquí la solicitud"
                            type="text"
                            fullWidth
                            required
                            multiline
                          />
                        </FormControl>

                      </GridItem>
                      <GridItem xs={12} sm={12} md={3} >
                        <FilePond
                          id="contained-button-file"
                          files={files}
                          allowMultiple={false}
                          maxFiles={1}
                          onupdatefiles={(files) => setFiles(files.map(fileItem => fileItem.file))}
                          acceptedFileTypes= {['image/*']}
                          labelIdle='<i class="material-icons" style="font-size: 50px">add_photo_alternate</i>'
                        />
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <div align="center">
                  <Button type="submit" fullWidth={true}  variant="contained" color="primary" className={classes.button} >
                    Enviar
                    <Icon className={classes.rightIcon}>send</Icon>
                  </Button>
                </div>
              </GridItem>
            </GridContainer>
          </form>
        )}
      </div>
    );
}