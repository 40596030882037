import React, {useEffect} from "react";
import {Redirect} from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import QueueAnim from "rc-queue-anim";
import DataGrid, {
    Column, Export, FilterRow,
    GroupPanel, HeaderFilter,
    Pager, Paging, Lookup,
    SearchPanel, Sorting, Summary, TotalItem, Scrolling, StateStoring
} from "devextreme-react/data-grid";
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.material.blue.light.compact.css';
import {
    Button, Dialog,
    DialogActions,
    DialogContent, FormControl,
    DialogTitle, InputLabel, Select, TextField, MenuItem
} from "@material-ui/core";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {handleError} from "../../../util/Contexts/ErrorContext";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Alert from "react-s-alert";
import {hasRole} from "../../../util/Contexts/UserService";
import {
    API_BASE_URL, API_AUTENTICATION_HEADERS, COMPLETE_NAME, PICKER_LOCATION, PICKER_Date_LOCALE, PICKER_DATE_LOCALE
} from "../../../assets/constants";
import axios from 'axios';
import {makeStyles} from "@material-ui/core/styles";
import {primaryColor, secondaryColor} from "../../../assets/jss/material-dashboard-react";
import esMessages from 'devextreme/localization/messages/es.json';
import {locale, loadMessages} from 'devextreme/localization';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import {getAllTicketsFilterBy, getOwnersList, getTikcetPDF} from "../../../util/Contexts/TicketContext";
import {getAllPrioritysEnabled} from "../../../util/Contexts/PriorityContext";
import {getMyDepartmets} from "../../../util/Contexts/DepartmentContext";
import moment from "moment";
import DatetimeRangePicker from 'react-bootstrap-datetimerangepicker';
import {getAllItems} from "../../../util/util";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: primaryColor,
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "2"
        }
    },
    formControl: {
        margin: 1,
        minWidth: 140,
        maxWidth: "100%",
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    margin: {
        margin: 1,
    }
};

const useStyles = makeStyles(styles);

var cancelTokenSource = axios.CancelToken.source();
var ticketIdsList = []
export default function TicketList() {
    const classes = useStyles();
    const [detail, setDetail] = React.useState(false);
    const [id, setId] = React.useState(0);
    const [data, setData] = React.useState();
    const [state, setState] = React.useState({
        priorityAsigned: 0,
        reportId: 0,
        department: 0,
        ownerAsigned: 0,
        newCheckBox: false,
        openTicketBox: false,
        closeCheckBox: false,
        serviceSheetCheckbox: false,
        allCheckBox: true,
        displayRole: hasRole("ADMIN") || hasRole("SUPER_USER"),
        isAsesor: hasRole("OPERATOR"),
        startDate: moment().subtract(1, 'month').startOf('month'),
        endDate: moment().endOf('month'),
        ranges: {
            'Hoy': [moment(), moment()],
            'Ayer': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Útimos 7 días': [moment().subtract(6, 'days'), moment()],
            'Últimos 30 días': [moment().subtract(29, 'days'), moment()],
            'Este Mes': [moment().startOf('month'), moment().endOf('month')],
            'Ultimo Mes': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            'Desde el inicio': [moment("01-06-2020", "DD-MM-YYYY"), moment()]
        },
        statusAsigned: (hasRole("ADMIN")) ? 3 : 1,
    });
    const [openOwners, setOpenOwners] = React.useState(false);
    const [departments, setDepartments] = React.useState([]);
    const [owners, setOwners] = React.useState([]);
    const [openReassingned, setOpenReassingned] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [prioritys, setPrioritys] = React.useState([]);
    const [services, setServices] = React.useState([]);
    const [status] = React.useState(hasRole("OPERATOR") ?
        [
            {id: 0, name: 'Todos'},
            {id: 1, name: 'Activos'},
            {id: 2, name: 'Cerrados'},
            {id: 4, name: 'Hojas de Servicio generadas'},
            {id: 5, name: 'Hojas de Servicio pendientes'}
        ] :
        hasRole('CORPORATIVE') ?
            [
                {id: 0, name: 'Todos'},
                {id: 1, name: 'Activos'},
                {id: 2, name: 'Cerrados'}
            ] :
            [
                {id: 0, name: 'Todos'},
                {id: 1, name: 'Activos'},
                {id: 2, name: 'Cerrados'},
                {id: 3, name: 'Sin asignar'},
                {id: 4, name: 'Hojas de Servicio generadas'},
                {id: 5, name: 'Hojas de Servicio pendientes'}
            ]
    );


    loadMessages(esMessages);
    locale("es");

    function getAll() {
        cancelTokenSource.cancel();
        cancelTokenSource = axios.CancelToken.source()
        getAllTicketsFilterBy(state.statusAsigned, state.startDate, state.endDate, cancelTokenSource).then(respuesta => {
            setData(respuesta.data.data);
            console.log(respuesta.data.data);
        })
    }

    function getServiceType() {
        const url = API_BASE_URL + "/api/serviceSheetData";
        const headers = API_AUTENTICATION_HEADERS;
        axios.get(url, {headers}).then(respuesta => {
            setServices(respuesta.data.data.servicios);
        }).catch(error => {
            Alert.warning("No se pudieron recuperar los tipos de servicio.")
        });
    }

    function openDetail(row) {
        setId(row.id);
        setDetail(true);
    }


    const getAllDepartments = () => {
        getMyDepartmets().then(respuesta => {
            setDepartments(respuesta.data.data);
            console.log('Departamentos');
            console.log(respuesta.data.data);
        }).catch(error => {
            handleError(error);
        });
    };

    const getOwners = (id, type) => {
        getOwnersList(id).then(respuesta => {
            getAllPrioritysEnabled().then(({data}) => {
                setOwners(respuesta.data.data);
                setPrioritys(data.data)
                type === 1 ? setOpenOwners(true) : setOpenReassingned(true);
            })


        }).catch(error => {
            handleError(error);
        });
    };

    const handleChange = (event) => {
        event.preventDefault()
        setState({
            ...state,
            [event.target.name]: event.target.value
        });
    };

    function handleClickOpenReasingnedOwners(rowData) {
        var asignarAsesor = 1;
        if (rowData.row.data.owner != null) {
            asignarAsesor = 2;
        }
        if (rowData.row.data.priority != null) {
            setState({
                ...state,
                priorityAsigned: rowData.row.data.priority.id
            })
        }
        setState({
            ...state,
            reportId: rowData.row.data.id
        });
        getOwners(rowData.row.data.department.id, asignarAsesor);
    }

    function handleClickOpen(rowData) {
        setState({
            ...state,
            reportId: rowData.row.data.id
        });

        setOpen(true);
    }
    function printReport(rowData) {
        getTikcetPDF(rowData.row.data.id).then(({data}) => {
            let pdfWindow = window.open("");
            pdfWindow.document.write("<embed  width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(data.data) + "'/>");
            pdfWindow.document.body.style.margin = "0px";
            pdfWindow.document.title = rowData.row.data.ticketNumber;
        });
    }

    function handleClose() {
        setOpen(false);
    }

    function handleCloseOwners() {
        setOpenOwners(false);
    }

    function handleClickCloseReasingnedOwners() {
        setOpenReassingned(false);
    }

    function reassignedDepartment() {
        const url = API_BASE_URL + "/api/ticket/" + state.reportId + "/assigned/department/" + state.department;
        const headers = API_AUTENTICATION_HEADERS;
        axios.put(url, {}, {headers}).then(respuesta => {
            getAll();
            setOpen(false);
        })
    }

    function reassignedReport() {
        var bodyFormData = new FormData();
        bodyFormData.append("priority", state.priorityAsigned);
        const url = API_BASE_URL + "/api/ticket/" + state.reportId + "/assigned/" + state.ownerAsigned;
        const headers = API_AUTENTICATION_HEADERS;

        axios.put(url, bodyFormData, {headers}).then(respuesta => {
            getAll();
            setOpenReassingned(false);
        }).catch(error => {
            console.log(error)
            handleError(error);
        });
    }

    function assignedReport() {
        const url = API_BASE_URL + "/api/ticket/" + state.reportId + "/data";
        const headers = API_AUTENTICATION_HEADERS;

        axios.put(url, Object.assign({}, {
            priority: state.priorityAsigned,
            category: state.categoryAsigned,
            userAssigned: state.ownerAsigned
        }), {headers}).then(respuesta => {
            getAll();
            setState({
                ...state,
                officeAsigned: 0,
                priorityAsigned: 0,
                categoryAsigned: 0,
                ownerAsigned: 0,
            });
            setOpenOwners(false);
        }).catch(error => {
            handleError(error);
        });
    }

    const renderCell = (cellInfo) => {
        return (
            <div align={"center"} style={{display: 'flex'}}>

                {cellInfo.data.priority &&
                    <FiberManualRecordIcon style={{
                        width: 20, height: 20, color:
                        cellInfo.data.priority.color
                    }}/>
                }

                {cellInfo.data.unreadAdmin ? <VisibilityIcon color="disabled"/>
                    : <VisibilityOffIcon color="disabled"/>}

                {cellInfo.data.closed ? (<LockIcon color="disabled"/>)
                    : (<LockOpenIcon color="disabled"/>)}

                {cellInfo.data.isInternalTicket && <LocalActivityIcon color="disabled"/>}

            </div>
        );
    }
    const renderCellPoliza = (cellInfo) => {
        return (
            <div align={"center"} style={{display: 'flex'}}>
                {cellInfo.data.client == null ? "No" :
                    (
                        (cellInfo.data.client.supportPolicy == true && cellInfo.data.department.supportPolicy == true ? "Si" : "No")
                        || (cellInfo.data.client.systemPolicy == true && cellInfo.data.department.systemPolicy == true ? "Si" : "No")
                        || (cellInfo.data.client.upgradePolicy == true && cellInfo.data.department.upgradePolicy == true ? "Si" : "No")
                    )
                }


                {/* {cellInfo.data.client.supportPolicy == cellInfo.data.client.supportPolicy ? true : false} */}
                {/* if((cellInfo.data.department.supportPolicy == true && cellInfo.data.client.supportPolicy == true)) */}
            </div>
        );
    }

    function getFileTitle() {
        if (state.displayRole) {
            return "Solicitudes"
        } else {
            return "Solicitudes_" + localStorage.getItem(COMPLETE_NAME)
        }
    }

    function customizeExcelCell(options) {
        var gridCell = options.gridCell;
        if (!gridCell) {
            return;
        }

        if (gridCell.rowType === 'data') {
            if (gridCell.column.dataField === 'priority.color') {
                options.backgroundColor = gridCell.data.priority.color;
                options.value = ""
            }
            // options.backgroundColor = gridCell.data.priority.color
        }

    }

    function handleEvent(event, picker) {
        event.preventDefault()
        setState({
            ...state,
            startDate: picker.startDate,
            endDate: picker.endDate,
        });
    }


    useEffect(value => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        if (Object.keys(params).length !== 0) {
            console.log(params)
            setState({
                ...state,
                startDate: moment(params.start),
                endDate: moment(params.end),
                statusAsigned: parseInt(params.status)
            })
        }
        getAllDepartments();
        getServiceType();
    }, []);


    useEffect(value => {
        console.log(window.location.search)
        var url = new URL(window.location.href);
        const urlSearchParams = new URLSearchParams(window.location.search);
        urlSearchParams.set("status", state.statusAsigned)
        urlSearchParams.set("start", state.startDate)
        urlSearchParams.set("end", state.endDate)
        url.search = urlSearchParams;
        url = url.toString();
        window.history.replaceState({url: url}, null, url);
        getAll();
    }, [state.statusAsigned, state.endDate, state.startDate]);


    if (detail) {
        return (
            <Redirect push to={{
                pathname: "/admin/request/" + id,
                state: {ids: ticketIdsList}
            }}/>
        );
    }


    let start = state.startDate.format('DD/MMM/YYYY');
    let end = state.endDate.format('DD/MMM/YYYY');
    let label = start + ' - ' + end;
    if (start === end) {
        label = start;
    }
    return (
        <GridContainer>
            <Dialog open={openOwners} onClose={handleCloseOwners}>
                <DialogTitle id="form-dialog-title">Asignar responsable</DialogTitle>
                <DialogContent>
                    <form className={classes.root} autoComplete="off" onSubmit={assignedReport}>
                        <FormControl fullWidth required>
                            <InputLabel htmlFor="ownerAsigned">Responsable</InputLabel>
                            <Select
                                required
                                native
                                value={state.ownerAsigned}
                                onChange={handleChange}
                                inputProps={{
                                    name: 'ownerAsigned',
                                    id: 'ownerAsigned',
                                }}
                            >
                                <option value=""/>
                                {owners.map(function (value) {
                                    return (<option key={value.id} value={value.id}> {value.completeName}</option>);
                                })}
                            </Select>
                        </FormControl>
                        <br/> <br/>
                        <FormControl fullWidth required>
                            <InputLabel htmlFor="priorityAsigned">Prioridad</InputLabel>
                            <Select
                                native
                                value={state.priorityAsigned}
                                onChange={handleChange}
                                inputProps={{
                                    name: 'priorityAsigned',
                                    id: 'priorityAsigned',
                                }}
                            >
                                <option value=""/>
                                {prioritys.map(function (value) {
                                    return (<option key={value.id} value={value.id}> {value.name}</option>);
                                })}
                            </Select>
                        </FormControl>

                        <DialogActions>
                            <Button onClick={handleCloseOwners} color="primary">
                                Cancelar
                            </Button>

                            <Button type="submit" color="primary">
                                Aceptar
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
            <Dialog open={openReassingned} onClose={handleClickCloseReasingnedOwners}>
                <form onSubmit={reassignedReport} className={classes.root} autoComplete="off">
                    <DialogTitle id="form-dialog-title">Reasignar responsable</DialogTitle>
                    <DialogContent>
                        <FormControl fullWidth required>
                            <InputLabel htmlFor="ownerAsigned">Responsable</InputLabel>
                            <Select
                                required
                                native
                                value={state.ownerAsigned}
                                onChange={handleChange}
                                inputProps={{
                                    name: 'ownerAsigned',
                                    id: 'ownerAsigned',
                                }}
                            >
                                <option value=""/>
                                {owners.map(function (value) {
                                    return (<option key={value.id} value={value.id}> {value.completeName}</option>);
                                })}
                            </Select>
                        </FormControl>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClickCloseReasingnedOwners} color="primary">
                            Cancelar
                        </Button>
                        <Button type={"submit"} color="primary">
                            Aceptar
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
            <Dialog open={open} onClose={handleClose}>
                <form onSubmit={reassignedDepartment} className={classes.root} autoComplete="off">
                    <DialogTitle id="form-dialog-title">Reasignar Área</DialogTitle>
                    <DialogContent>
                        <FormControl fullWidth required>
                            <InputLabel htmlFor="department">Área</InputLabel>
                            <Select
                                required
                                native
                                value={state.department}
                                onChange={handleChange}
                                inputProps={{
                                    name: 'department',
                                    id: 'department',
                                }}
                            >
                                <option value=""/>
                                {departments.map(function (value) {
                                    return (<option key={value.id} value={value.id}> {value.name}</option>);
                                })}                </Select>
                        </FormControl>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancelar
                        </Button>
                        <Button type={"submit"} color="primary">
                            Aceptar
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="secondary" spacing={10}>
                        <GridContainer>
                            <GridItem xl={8} sm={6} xs={12}>
                                <h4 className={classes.cardTitleWhite}>Solicitudes</h4>
                            </GridItem>
                            <GridItem sm={6} xs={12}>

                                <div align={"right"}>
                                    <DatetimeRangePicker

                                        startDate={state.startDate}
                                        endDate={state.endDate}
                                        locale={PICKER_DATE_LOCALE}
                                        ranges={state.ranges}
                                        onApply={handleEvent}
                                    >
                                        <TextField
                                            type={"text"}
                                            size={"small"}
                                            fullWidth
                                            variant="outlined"
                                            value={label}
                                            InputProps={{
                                                style: {color: "white"}
                                            }}
                                            inputProps={{
                                                style: {textAlign: "right"}
                                            }}
                                        />
                                    </DatetimeRangePicker>
                                </div>
                            </GridItem>
                        </GridContainer>
                    </CardHeader>
                    <CardBody>
                        <QueueAnim>
                            <div>
                                <GridContainer>
                                    <GridItem xs={12} sm={6} md={2}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="statusAsigned">Estatus</InputLabel>
                                            <Select
                                                style={{textAlign: 'initial'}}
                                                value={state.statusAsigned}
                                                onChange={handleChange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    name: 'statusAsigned',
                                                    id: 'statusAsigned',
                                                }}
                                            >
                                                <MenuItem value={""}/>
                                                {status.map(function (value) {
                                                        return (
                                                            <MenuItem key={value.id} value={value.id}>
                                                                {value.name}
                                                            </MenuItem>);
                                                    }
                                                )}
                                            </Select>
                                        </FormControl>
                                    </GridItem>
                                </GridContainer>
                                <DataGrid
                                    onRowClick={(e) => {
                                        e.rowType === "data" && openDetail(e.data)
                                    }}
                                    dataSource={data}
                                    keyExpr="id"
                                    showBorders={true}
                                    columnAutoWidth={true}
                                    allowColumnResizing={true}
                                    allowColumnReordering={true}
                                    rowAlternationEnabled
                                    onContentReady={(e) => {
                                        if (e.component.getDataSource()?.items()) {
                                            ticketIdsList = getAllItems(e.component.getDataSource()?.items(), []).map(d => d.id)
                                        }
                                    }}
                                    noDataText={"No tienes solicitudes asignadas"}
                                >
                                    <Export enabled={true} fileName={getFileTitle()}
                                            texts={{exportAll: "Exportar todos lo datos"}}
                                            customizeExcelCell={customizeExcelCell}/>
                                    <StateStoring enabled={true} savingTimeout={10} type="localStorage"
                                                  storageKey="ticketListStorage"/>

                                    <FilterRow visible={true} applyFilter={"auto"}/>
                                    <HeaderFilter visible={true}/>
                                    <GroupPanel visible={true} emptyPanelText={"Arrastre una columna para agrupar"}/>
                                    <SearchPanel visible={true}
                                                 width={240}
                                                 placeholder="Buscar..."/>

                                    <Paging defaultPageSize={20}/>

                                    <Pager
                                        showPageSizeSelector={true}
                                        allowedPageSizes={[10, 20, 50]}
                                        showInfo={true}/>

                                    <Sorting mode="multiple"/>
                                    <Column
                                        // dataField="priority.color"
                                        width={100}
                                        caption="Indicadores"
                                        allowGrouping={false}
                                        allowFiltering={false}
                                        allowSorting={false}
                                        allowSearch={false}
                                        allowExporting={false}
                                        cellRender={renderCell}
                                    />

                                    <Column dataField="ticketNumber"
                                            width={100}
                                            caption="Folio"/>
                                    <Column dataField="createDate"
                                            dataType="date"
                                            caption="Fecha"
                                            allowHeaderFiltering={false}
                                    />

                                    {
                                        state.statusAsigned === 2 &&
                                        <Column dataField="closedDate"
                                                dataType="date"
                                                caption="Fecha cierre"
                                                allowHeaderFiltering={false}
                                                visible={!hasRole("CORPORATIVE")}
                                        />
                                    }
                                    <Column dataField="priority.name"
                                            width={"auto"}
                                            caption="Prioridad"/>
                                    <Column dataField="ticketStatus.name"
                                            width={100}
                                            caption="Estatus"/>
                                    <Column dataField="author.completeName"
                                            width={130}
                                            caption="Cliente"/>

                                    <Column dataField="client.fiscalName"
                                            caption="Razón Social"
                                            width={150}/>
                                    <Column dataField="department.name"
                                            width={80}
                                            caption="Área"/>

                                    <Column dataField="activeDays"
                                            width={100}
                                            caption="Días activa"
                                            visible={!hasRole("CORPORATIVE")}/>

                                    <Column dataField="owner.completeName"
                                            visible={!state.isAsesor}
                                            width={"auto"}
                                            caption="Asesor"/>
                                    <Column dataField="internalText"
                                            width={100}
                                            caption="Interna"
                                            visible={!hasRole("CORPORATIVE")}/>

                                    <Column dataField="hasSaleNumber"
                                            width={100}
                                            caption="Tiene folio de venta"
                                            visible={!hasRole("CORPORATIVE")}>
                                        <Lookup dataSource={[{id: true, name: 'Sí'}, {id: false, name: 'No'}]}
                                                displayExpr="name" valueExpr="id"/>
                                    </Column>

                                    <Column dataField="saleNumber"
                                            width={100}
                                            caption="Folio de venta"
                                            visible={!hasRole("CORPORATIVE")}/>

                                    <Column dataField="serviceSheetTypeText"
                                            width={150}
                                            caption="Tipo hoja de servicio"
                                            visible={!hasRole("CORPORATIVE")}/>

                                    <Column dataField="timeWork"
                                            width={150}
                                            dataType={'number'}
                                            caption="Horas"/>
                                    <Column dataField="amount"
                                            width={150}
                                            dataType={'number'}
                                            caption="Costo"/>

                                    <Column dataField="client.systemPolicy"
                                            width={150}
                                            caption="Póliza de sistema"
                                            visible={!hasRole("CORPORATIVE")}>
                                        <Lookup dataSource={[{id: true, name: 'Sí'}, {id: false, name: 'No'}]}
                                                displayExpr="name" valueExpr="id"/>

                                    </Column>
                                    <Column dataField="client.supportPolicy"
                                            width={150}
                                            caption="Póliza de soporte"
                                            visible={!hasRole("CORPORATIVE")}>
                                        <Lookup dataSource={[{id: true, name: 'Sí'}, {id: false, name: 'No'}]}
                                                displayExpr="name" valueExpr="id"/>

                                    </Column>
                                    <Column dataField="client.upgradePolicy"
                                            width={150}
                                            caption="Póliza de actualización"
                                            visible={!hasRole("CORPORATIVE")}>
                                        <Lookup dataSource={[{id: true, name: 'Sí'}, {id: false, name: 'No'}]}
                                                displayExpr="name" valueExpr="id"/>

                                    </Column>

                                    <Column
                                        // dataField="priority.color"
                                        width={100}
                                        caption="Aplica póliza"
                                        allowGrouping={false}
                                        allowFiltering={false}
                                        allowSorting={false}
                                        allowSearch={false}
                                        allowExporting={false}
                                        cellRender={renderCellPoliza}
                                    />
                                    <Column dataField="location"
                                            caption="Nombre Comercial"
                                            width={"auto"}/>

                                    <Column dataField="serviceId" caption="Servicio"
                                            visible={!hasRole("CORPORATIVE")}>
                                        <Lookup dataSource={services} displayExpr="Des" valueExpr="Cve"/>
                                    </Column>


                                    <Column type="buttons"
                                            visible={state.displayRole}
                                            caption="Acciones"
                                            width={80}
                                            buttons={[{
                                                hint: 'Reasignar solicitud',
                                                icon: 'detailslayout',
                                                onClick: handleClickOpenReasingnedOwners
                                            }, {
                                                hint: 'Cambiar área',
                                                icon: 'paste',
                                                onClick: handleClickOpen
                                            }, {
                                                hint: 'Generar PDF',
                                                icon: 'print',
                                                visible: state.statusAsigned === 4,
                                                onClick: printReport
                                            }]}/>

                                    <Summary recalculateWhileEditing={true}>
                                        <TotalItem
                                            column="ticketNumber"
                                            displayFormat={'Total: {0}'}
                                            summaryType="count"/>
                                        <TotalItem

                                            displayFormat={'Total: {0} Horas'}
                                            column="timeWork"
                                            summaryType="sum"
                                            valueFormat={'decimal'}
                                            precision={2}/>
                                        <TotalItem
                                            displayFormat={'Total: ${0}'}
                                            column="amount"
                                            summaryType="sum"
                                            valueFormat={'decimal'}
                                            precision={0}/>
                                    </Summary>
                                </DataGrid>
                            </div>
                        </QueueAnim>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>

    );
}