import React, { Component } from 'react';
import {
    // GOOGLE_AUTH_URL,
    // FACEBOOK_AUTH_URL,
    ACCESS_TOKEN,
    USER_ID_SESSION,
    USER_ROLES, DEVICE_ID,
    COMPLETE_NAME
} from '../../../../assets/constants';
import { login } from '../../../../util/Contexts/UserService';
import { getUsuarioEmail } from '../../../../util/Contexts/UserService';
import {  Redirect } from 'react-router-dom'
import Alert from 'react-s-alert';
import { TraceSpinner } from "react-spinners-kit";
import {primaryColor, secondaryColor} from "../../../../assets/jss/material-dashboard-react";
import logo from "assets/img/sierra logo 1.svg";
import Swal from "sweetalert2";
// import fbLogo from '../../../../assets/img/fb-logo.png';
// import googleLogo from '../../../../assets/img/google-logo.png';
var loading = false;
class SendEmailForPassword extends Component {




    componentDidMount() {
        // If the OAuth2 login encounters an error, the user is redirected to the /login page with an error.
        // Here we display the error and then remove the error query parameter from the location.
        if(this.props.location.state && this.props.location.state.error) {
            setTimeout(() => {
                Alert.error(this.props.location.state.error, {
                    timeout: 5000
                });
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: {}
                });
            }, 100);
        }



    }

    setLoading(loadingState) {
        loading = loadingState;
    }


    
    render() {
        if(this.props.authenticated) {
            return <Redirect
                to={{
                pathname: "/",
                state: { from: this.props.location }
            }}/>;            
        }

        return (
            <div>
                {loading ? (
                        <div style={{
                            position: 'absolute', left: '50%', top: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}>
                            <TraceSpinner

                        size={80}
                        frontColor={primaryColor[0]}
                        backColor={secondaryColor[0]}
                        loading={loading}/>
                        </div>
                    ) : (
                        <div className="login-container">
                            <div className="login-content">
                                {/*<SocialLogin />
                                <div className="or-separator">
                                <span className="or-text">OR</span>
                                 </div>*/}
                                 <ResetPasswordForm {...this.props} setLoading={this.setLoading} />
                                 {/*<span className="signup-link">New user? <Link to="/signup">Sign up!</Link></span>*/}
                            </div>
                        </div>
                    ) }
            </div>
        );
    }
}

class ResetPasswordForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            checked: false
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;

        this.setState({
            [inputName] : inputValue
        });        
    }
    handleCheckedChange(checked) {
        this.setState({ checked });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.props.setLoading(true);
        getUsuarioEmail(this.state.email).then(response => {
            if (response.data.success) {
                this.handleCheckedChange(true);
            } else {
                Alert.error(response.data.message);
                var txtEmail= document.getElementById("userEmail");
                txtEmail.value = "";
            }
        })
    }
    
    render() {
        return (
            <div>
                    <img src={logo} alt="logo" className="img" />
                    
                    {this.state.checked ? (
                            <div className="form-item">
                            <p>Se envió un correo para recuperar su contraseña exitosamente, favor de revisar la bandeja principal y la de spam.</p>
                        </div>
                        ) : 
                        <div>
                            <p>Se le enviará un enlace a su correo para restaurar su contraseña.</p>
                <form onSubmit={this.handleSubmit}>
                        <div className="form-item">
                            <input type="email" name="email" id="userEmail"
                                   className="form-control" placeholder="Correo electrónico"
                                   value={this.state.email} onChange={this.handleInputChange} required/>
                        </div>
                        <div className="form-item">
                            <button type="submit" className="btn btn-block btn-primary">Enviar</button>
                        </div>

                </form>
                        </div>
                    }

            </div>
        );
    }
}

export default SendEmailForPassword
