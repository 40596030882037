import React, {useEffect} from "react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import { CirclePicker } from 'react-color';
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import MaterialTable from 'material-table';
import Alert from "react-s-alert";
import {primaryColor, secondaryColor} from "../../../assets/jss/material-dashboard-react";
import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Slider,
    TextField, Typography, Chip, Input, FormControl, InputAdornment, IconButton, FormHelperText
} from "@material-ui/core";
import DataGrid, {
  Column, Export, FilterRow,
  GroupPanel, HeaderFilter,
  Pager, Paging,
  SearchPanel, Sorting
} from "devextreme-react/data-grid";
import QueueAnim from "rc-queue-anim";
import {TraceSpinner} from "react-spinners-kit";
import {addPriority, getAllPrioritys, updatePriority, enablePriority} from "../../../util/Contexts/PriorityContext";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import {handleError} from "../../../util/Contexts/ErrorContext";
import esMessages from 'devextreme/localization/messages/es.json';
import { locale, loadMessages } from 'devextreme/localization';

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: primaryColor,
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    root: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        '& > *': {
            margin: 1,
        },
    }
};

const useStyles = makeStyles(styles);


export default function PriorityList() {
  const classes = useStyles();
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [state, setState] = React.useState({
    stateSelected: 0,
    filter: "",
    name: "",
    id: 0,
    description: "",
    hourSilder: [12,50],
    edit: false,
    color: "#cddc39",
    enabled: true,
    tags: [],
    emails: [],
    email: ""
  });

  loadMessages(esMessages);
  locale("es");

  const handleChangeComplete = (color) => {
    setState({ ...state, color: color.hex });
  };

  function handleClickOpen(row) {
    console.log(row)
    var item = null;
    if(row.row != null){
      for(let i = 0; i < data.length; i++){
        if(row.row.data.id === data[i].id){
          item = data[i]
        }
      }
    }
    item ? setState({
      ...state,
      name: item.name,
      id: item.id,
      description: item.description,
      hourSilder: [item.lowHour, item.highHour],
      edit: true,
      color: item.color,
      emails: item.emails,
      enabled: item.enabled,
      email: ""
    }) : setState({
        ...state,
        name: "",
        description: "",
        hourSilder: [12,50],
        edit: false,
        color: "#cddc39",
        emails: [],
        email: ""
      });
      setError(false);
      setOpen(true);
  }

  const handleChangeSilder = (event, newValue) => {
    setState({
      ...state,
      hourSilder: newValue
    });
  };

  function handleClose() {
    setOpen(false);
  }

  const handleChange = event => {
    setState( {
      ...state,
      [event.target.name] : event.target.value
    });
  };

  function enable(rowData) {
    rowData.row.data.enabled = !rowData.row.data.enabled;

    enablePriority(rowData.row.data).then(respuesta => {
      Alert.success(respuesta.data.message);
      setLoading(false);
      handleClose();
      getAllData();
    } ).catch(error => {
      setLoading(false);
      handleError(error);
    });
  }

  function valuetext(value) {
    return `${value} hr`;
  }

  const updateData = async (event) => {
    setLoading(true);
    event.preventDefault();
    console.log(state)
    updatePriority(state).then(respuesta => {
      Alert.success(respuesta.data.message);
      setLoading(false);
      handleClose();
      getAllData();
    } ).catch(error => {
      setLoading(false);
      handleError(error);
    });
  };

  const getAllData = () => {
    setLoading(true);
    getAllPrioritys().then(respuesta => {
      setLoading(false);
      setData(respuesta.data.data);
    } ).catch(error => {
      setLoading(false);
      handleError(error);
    });
  };

  useEffect(value => {
    getAllData();
  }, []);

  const addData = (event) => {
    event.preventDefault();
    setLoading(true);
    console.log(state.emails === null)
    if(state.emails.length == 0){
      console.log("no hay nada");
      state.emails.push(state.email);
    }
    console.log(state)
    addPriority(state).then(respuesta => {
      Alert.success(respuesta.data.message);
      handleClose();
      getAllData();
      setError(false);
      setLoading(false);
    } ).catch(error => {
      setLoading(false);
      handleError(error);
    });
  };

  const handleDelete = chipToDelete => () => {
    var {emails} = state;
    emails = emails.filter(chip => chip !== chipToDelete);
    setState({
      ...state,
      emails: emails
    });
  };

  const addEmail = (event) => {
    event.preventDefault();
    var {email} = state;
    if (validateEmail(email)) {
      setError(false);
      var {emails} = state;
      emails.push(email);
      setState({
        ...state,
        emails: emails,
        email: ""
      });
    }else {
      setError(true);
    }
  };

  function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'plus',
        onClick: handleClickOpen.bind()
      }
    });
  }

  const renderStatus = (cellInfo) => {
    if(cellInfo.data.enabled){
      return(
        <div align={"center"}>
          Activo
        </div>
        );
    } else {
      return(
        <div align={"center"}>
          Inactivo
        </div>
        );
    }
  }

  const renderHours = (cellInfo) => {
    var lowHour = cellInfo.row.data.lowHour;
    var highHour = cellInfo.row.data.highHour;
    return(
      <div align={"center"}>
          De {lowHour} a {highHour} horas
      </div>
    );
  }

  const renderColor = (cellInfo) => {
    return(
      <div align={"center"}>
          <Avatar style={{ backgroundColor: cellInfo.row.data.color}}/>
      </div>
    );
  }

  return (
    <div>
      {
        loading ? (
          <div style={{
            position: 'absolute', left: '50%', top: '50%',
            transform: 'translate(-50%, -50%)'
          }}>
            <TraceSpinner
              size={80}
              frontColor={primaryColor[0]}
              backColor={secondaryColor[0]}
              loading={loading}/>
          </div>
        ) : (
         <GridContainer>
            <Dialog open={open} onClose={handleClose} maxWidth={'sm'}>
              <DialogTitle id="form-dialog-title">{state.edit ? "Editar prioridad" : "Agregar prioridad"}</DialogTitle>
              <form onSubmit={state.edit ? updateData : addData} autoComplete="off" >
                <DialogContent>
                  <TextField
                    required={true}
                    autoFocus
                    margin="dense"
                    id="name"
                    name="name"
                    value={state.name}
                    onChange={handleChange}
                    label="Nombre"
                    type="text"
                    fullWidth
                  />
                  <TextField
                    margin="dense"
                    id="description"
                    name="description"
                    value={state.description}
                    onChange={handleChange}
                    label="Descripción"
                    type="text"
                    fullWidth
                  />
                  <br/><br/>
                  <Typography id="range-slider" gutterBottom>
                    Rango de horas
                  </Typography>
                  <br/><br/>
                  <Slider
                    max={250}
                    min={1}
                    step={1}
                    value={state.hourSilder}
                    getAriaValueText={valuetext}
                    onChange={handleChangeSilder}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                    name="hourSilder"
                  />
                  <Typography  gutterBottom>
                    Color
                    <CirclePicker 
                      required={true}
                      color={ state.color }
                      onChangeComplete={ handleChangeComplete }   />
                    <br/>
                    Correos
                  </Typography>
                  <div className={classes.root}>
                    {state.emails.map(value => ( <Chip
                      key={value}
                      size="small"
                      label={value}
                      onDelete={handleDelete(value)}
                    />))}
                  </div>
                  <FormControl fullWidth className={classes.margin}>
                    <Input
                      autoComplete={"off"}
                      id="email"
                      name={"email"}
                      type={'email'}
                      placeholder={"Añadir correo electrónico"}
                      value={state.email}
                      onChange={handleChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton onClick={addEmail}
                            aria-label="toggle password visibility"
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {error && <FormHelperText error={true} id="email">Correo electrónico invalido</FormHelperText>}
                  </FormControl>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancelar
                  </Button>
                  <Button type={"submit"} color="primary">
                    Guardar
                  </Button>
                </DialogActions>
              </form>
            </Dialog>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="secondary">
                  <h4 className={classes.cardTitleWhite}>Listado de prioridades</h4>
                  <p className={classes.cardCategoryWhite}>

                  </p>
                </CardHeader>
                <CardBody>
                  <QueueAnim>
                    <DataGrid
                      dataSource={data}
                      showBorders={true}
                      key="id"
                      allowColumnReordering={true}
                      allowColumnResizing={true}
                      onToolbarPreparing={onToolbarPreparing}>

                      {/* <Export enabled={true} fileName={"Tickets"} texts={{exportAll: "Exportar todos lo datos"}}/>   */}
                           
                      <FilterRow visible={true} applyFilter={"auto"} />
                           
                      <HeaderFilter visible={true} />
                            
                      <GroupPanel visible={true} emptyPanelText={"Arrastre una columna para agrupar"} />
                            
                      <SearchPanel visible={true}
                        width={240}
                        placeholder="Buscar..." />
                            
                      <Paging defaultPageSize={10} />
                            
                      <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 20]}
                        showInfo={true} />
                              
                      <Sorting mode="multiple" />

                      <Column
                        dataField="name"
                        caption="Nombre"/>

                      <Column
                        dataField="description"
                        caption="Descripción"/>

                      <Column
                        dataField="highHour"
                        cellRender={renderHours}
                        caption="Respuesta"/>

                      <Column
                        dataField="color"
                        cellRender={renderColor}
                        caption="Color"/>

                      <Column
                        dataField="enabled"
                        cellRender={renderStatus}
                        caption="Estatus"/>

                      <Column type="buttons"
                        visible={state.displayRole}
                        caption="Acciones"
                        width={80}
                        buttons={[{
                          hint: 'Habilitar/Deshabilitar',
                          icon: 'isblank',
                          onClick: enable
                        }, {
                          hint: 'Editar',
                          icon: 'edit',
                          onClick: handleClickOpen
                        }]}/>
                    </DataGrid>
                  </QueueAnim>
                                    {/* <MaterialTable
                                        columns={[
                                            { title: 'Nombre', field: 'name' },
                                            { title: 'Descripción', field: 'description' },
                                            { title: 'Respuesta', field: 'description', render: rowData => <div> De {rowData.lowHour} a {rowData.highHour} horas </div> },
                                            { title: 'Color', field: 'color', render: rowData => rowData.color ? <Avatar style={{ backgroundColor: rowData.color}}/> : "Sin asignar", editable: 'never' },
                                            { title: 'Estatus', field: 'enabled', render: rowData => rowData.enabled ? "Activo" : "Inactivo", editable: 'never' }

                                        ]}
                                        data={data}
                                        title=""
                                        localization={{
                                            body: {
                                                emptyDataSourceMessage: "No se encontraron registros",
                                                editRow: {
                                                    saveTooltip: "Guardar",
                                                    cancelTooltip: "Cancelar"
                                                },
                                                editTooltip: "Editar"
                                            },
                                            header: {
                                                actions: "Acciones"
                                            },
                                            toolbar:{
                                                searchPlaceholder:"Buscar"
                                            }

                                        }}
                                        actions={[
                                            rowData =>  (rowData.enabled ?
                                                    ({
                                                        icon: 'visibility_off',
                                                        tooltip: 'Deshabilitar',
                                                        onClick: (event, rowData) => disable(rowData.id, rowData)
                                                    }) :
                                                    ({
                                                        icon: 'visibility',
                                                        tooltip: 'Habilitar',
                                                        onClick: (event, rowData) => enable(rowData.id, rowData)
                                                    })
                                            ),
                                            {
                                                icon: 'add',
                                                tooltip: 'Agregar prioridad',
                                                isFreeAction: true,
                                                onClick: (event) => handleClickOpen()
                                            },
                                            rowData => ({
                                                icon: 'edit',
                                                tooltip: 'Editar prioridad',
                                                onClick: (event) => handleClickOpen(rowData)
                                            })
                                        ]}


                                        options={{
                                            actionsColumnIndex: -1,
                                            sorting: true,
                                            showTitle: true,
                                        }}

                                    /> */}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        )
      }
    </div>
  );
}