import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Icon from "@material-ui/core/Icon";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import {Chip, withStyles} from "@material-ui/core";
import CardBody from "../../../components/Card/CardBody";
import {CHART_DATA, CHART_OPTION} from "../../../assets/constants";
import CardHeader from "../../../components/Card/CardHeader";
import Card from "../../../components/Card/Card";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Chart from "react-apexcharts";
import {makeStyles} from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import CardIcon from "components/Card/CardIcon.js";
import {getAllOffices, getAllOfficesEnabled} from "../../../util/Contexts/OfficeContext";
import {getDataAnswer} from "../../../util/Contexts/DashboardService";
import {getAllDepartmets} from "../../../util/Contexts/DepartmentContext";
import {getAllAdmins} from "../../../util/Contexts/UserService";
import {getAllCategory} from "../../../util/Contexts/CategoryContext";


class AnswerChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 1,
            data: [],
            categorys: [],
            departments: [],
            admins: []
        }
        this.getAllData = this.getAllData.bind(this)
        this.onChangeChartType = this.onChangeChartType.bind(this)
    }

    componentDidMount() {

        getAllDepartmets().then((departments) => {
            getAllCategory().then((categorys) => {
                getAllAdmins().then(({data}) => {
                    this.setState({
                        departments: departments.data.data,
                        categorys: categorys.data.data,
                        admins: data.data
                    })
                    this.getAllData(0)
                })
            })
        })
    }

    getAllData(id) {
        const type = id === 0 ? this.state.type : id
        getDataAnswer(type).then(({data}) => {
            switch (type) {
                case 1:
                    var dataArray = []
                    var yesArray = []
                    var parcialArray = []
                    var noArray = []
                    console.log(this.state.departments)
                    console.log(this.state.departments)
                    this.state.departments.forEach(department => {
                        yesArray.push(data.data.find(d => d.id === department.id && d.answare === '1')?.total || 0)
                        parcialArray.push(data.data.find(d => d.id === department.id && d.answare === '2')?.total || 0)
                        noArray.push(data.data.find(d => d.id === department.id && d.answare === '3')?.total || 0)
                    })
                    dataArray.push({name: `Sí`, data: yesArray})
                    dataArray.push({name: `Parcialmente`, data: parcialArray})
                    dataArray.push({name: `No`, data: noArray})
                    console.log(dataArray)
                    this.setState({data: dataArray, type: type})

                    break;
                case 2:
                    var dataArray = []
                    var yesArray = []
                    var parcialArray = []
                    var noArray = []
                    console.log(this.state.departments)
                    console.log(this.state.departments)
                    this.state.categorys.forEach(category => {
                        yesArray.push(data.data.find(d => d.id === category.id && d.answare === '1')?.total || 0)
                        parcialArray.push(data.data.find(d => d.id === category.id && d.answare === '2')?.total || 0)
                        noArray.push(data.data.find(d => d.id === category.id && d.answare === '3')?.total || 0)
                    })
                    dataArray.push({name: `Sí`, data: yesArray})
                    dataArray.push({name: `Parcialmente`, data: parcialArray})
                    dataArray.push({name: `No`, data: noArray})
                    console.log(dataArray)
                    this.setState({data: dataArray, type: type})

                    break;
                case 3:
                    var dataArray = []
                    var yesArray = []
                    var parcialArray = []
                    var noArray = []
                    console.log(this.state.departments)
                    console.log(this.state.departments)
                    this.state.admins.forEach(admin => {
                        yesArray.push(data.data.find(d => d.id === admin.id && d.answare === '1')?.total || 0)
                        parcialArray.push(data.data.find(d => d.id === admin.id && d.answare === '2')?.total || 0)
                        noArray.push(data.data.find(d => d.id === admin.id && d.answare === '3')?.total || 0)
                    })
                    dataArray.push({name: `Sí`, data: yesArray})
                    dataArray.push({name: `Parcialmente`, data: parcialArray})
                    dataArray.push({name: `No`, data: noArray})
                    console.log(dataArray)
                    this.setState({data: dataArray, type: type})

                    break;
                default:
                    break;
            }
        })
    }


    onChangeChartType(type) {
        this.getAllData(type)
    }

    render() {
        const {classes} = this.props;

        return (
            <Card chart>
                <CardHeader color="info" stats icon>
                    <CardIcon color="info">
                        <Icon>
                            <EqualizerIcon/>
                        </Icon>
                    </CardIcon>
                    <p className={classes.cardCategory}>
                        <GridContainer spacing={1} justifyContent="center" alignItems="center">
                            <GridItem md={3}>
                                <div align={"center"}>
                                    <Chip label="Departamento" onClick={() => this.onChangeChartType(1)} clickable
                                          color={this.state.type === 1 ? "primary" : "default"}/>
                                </div>
                            </GridItem>
                            <GridItem md={3}>
                                <div align={"center"}>
                                    <Chip label="Categoría" onClick={() => this.onChangeChartType(2)} clickable
                                          color={this.state.type === 2 ? "primary" : "default"}/>
                                </div>
                            </GridItem>
                            <GridItem md={3}>
                                <div align={"center"}>
                                    <Chip label="Asesor" onClick={() => this.onChangeChartType(3)} clickable
                                          color={this.state.type === 3 ? "primary" : "default"}/>
                                </div>
                            </GridItem>
                        </GridContainer>
                    </p>

                </CardHeader>
                <CardBody>
                    {
                        this.state.data.length !== 0 &&
                        <Chart
                            options={CHART_OPTION(this.state.type === 1 ? this.state.departments.map(p => p.name) : this.state.type === 2 ? this.state.categorys.map(p => p.name) : this.state.admins.map(p => p.name))}
                            series={this.state.data} type="bar"
                            height={350}/>

                    }
                </CardBody>
            </Card>
        );
    }
}

AnswerChart.propTypes = {};

export default withStyles(styles)(AnswerChart);
