import {API_AUTENTICATION_HEADERS, API_BASE_URL} from "../../assets/constants";
import axios from "axios";

const headers = API_AUTENTICATION_HEADERS;

function updateCategory(id, newData){
    console.log(newData)
    const url = API_BASE_URL + "/api/category/" + id;

    return axios.put(url,newData,{ headers } );
}

function getAllCategory() {
    const url = API_BASE_URL + "/api/categorys/all";
    return axios.get(url, {headers});
}
function getAllCategoryByDepartment(id) {
    const url = API_BASE_URL + "/api/categorys/department/" + id;
    return axios.get(url, {headers});
}

function addCategory(state) {
    const url = API_BASE_URL + "/api/category";
    return axios.post(url, Object.assign({}, {name: state.name, description: state.description, enabled: true, departmentId: state.officeAsigned})  ,{ headers } );
}

export {  getAllCategory, updateCategory, addCategory, getAllCategoryByDepartment};