import React, {useEffect} from "react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import QueueAnim from "rc-queue-anim";
import Alert from "react-s-alert";
import {primaryColor, secondaryColor} from "../../../assets/jss/material-dashboard-react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl, InputLabel, Select,
    TextField
} from "@material-ui/core";
import DataGrid, {
    Column, Export, FilterRow,
    GroupPanel, HeaderFilter,
    Pager, Paging, Editing,
    SearchPanel, Sorting
} from "devextreme-react/data-grid";
import {TraceSpinner} from "react-spinners-kit";
import {addCategory, getAllCategory, updateCategory} from "../../../util/Contexts/CategoryContext";
import {handleError} from "../../../util/Contexts/ErrorContext";
import {getAllDepartmets} from "../../../util/Contexts/DepartmentContext";
import esMessages from 'devextreme/localization/messages/es.json';
import { locale, loadMessages } from 'devextreme/localization';

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: primaryColor,
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const useStyles = makeStyles(styles);


export default function CategoryList() {

    const classes = useStyles();
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [lookup, setLookup] = React.useState({});
    const [state, setState] = React.useState({
        stateSelected: 0,
        filter: "",
        name: "",
        id: 0,
        description: "",
        edit: false,
        officeAsigned: 1
    });
    const [offices, setOffices] = React.useState([]);

    loadMessages(esMessages);
    locale("es");

    function handleClickOpen(row) {
      var item = null;
      if(row.row != null){
        for(let i = 0; i < data.length; i++){
          if(row.row.key === data[i].id){
            item = data[i]
          }
        }
      }
      console.log(item)
      item ? setState({
        ...state,
        name: item.name,
        id: item.id,
        description: item.description,
        officeAsigned: item.department.id,
        edit: true
      }) :  setState({
        ...state,
        name: "",
        description: "",
        officeAsigned: 1,
        edit: false
      });
      setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    const handleChange = event => {
        setState( {
            ...state,
            [event.target.name] : event.target.value
        });
    };

    function enable(rowData) {
      rowData.row.data.enabled = !rowData.row.data.enabled
      updateCategory(rowData.row.data.id, rowData.row.data).then(respuesta => {
        Alert.success(respuesta.data.message);
        handleClose();
        getAllData();
    } ).catch(error => {
        handleError(error);
    });
    }


    function getDepartments() {
        getAllDepartmets().then(respuesta => {
            setLookup(respuesta.data.data.reduce(function(result, item) {
                var key = Object.values(item)[0];
                result[key] = item.name;
                return result;
            }, {}));
            setOffices(respuesta.data.data);
        }).catch(error => {
            handleError(error);
        });

    }

    const renderStatus = (cellInfo) => {
      if(cellInfo.data.enabled){
        return(
          <div align={"center"}>
            Activo
          </div>
          );
      } else {
        return(
          <div align={"center"}>
            Inactivo
          </div>
          );
      }
    }

    const updateData =  (event)  => {
      event.preventDefault();
      var item = null;
      for(let i = 0; i < offices.length; i++){
        if(state.officeAsigned == offices[i].id){
          console.log("Adentro de If")
          item = offices[i]
        }
      }
      let newData = {
        name: state.name,
        description: state.description,
        enabled: true,
        department: item
      }
        updateCategory(state.id,newData).then(respuesta => {
            Alert.success(respuesta.data.message);
            handleClose();
            getAllData();
        } ).catch(error => {
            handleError(error);
        });
    };

    const getAllData = () => {
        setLoading(true);
        getAllCategory().then(respuesta => {
            setLoading(false);
            setData(respuesta.data.data);
        } ).catch(error => {
            setLoading(false);
            handleError(error);
        });
    };

    useEffect(value => {
        getAllData();
        getDepartments();
    }, []);

    const addData = (event) => {
        setLoading(true);
        event.preventDefault();
        addCategory(state).then(respuesta => {
            Alert.success(respuesta.data.message);
            setState({
                ...state,
                name: "",
                description: ""
            });
            handleClose();
            getAllData();
            setLoading(false);
        } ).catch(error => {
            setLoading(false);
            handleError(error);
        });
    };

    function onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'plus',
          onClick: handleClickOpen.bind()
        }
      });
    }

    return (
        <div>
            {
              loading ? (
                <div style={{
                  position: 'absolute', left: '50%', top: '50%',
                  transform: 'translate(-50%, -50%)'
                }}>
                  <TraceSpinner
                    size={80}
                    frontColor={primaryColor[0]}
                    backColor={secondaryColor[0]}
                    loading={loading}/>
                </div>
              ) : (
                <GridContainer>
                  <Dialog open={open} onClose={handleClose}>
                    <DialogTitle id="form-dialog-title">{state.edit? "Editar categoría" : "Agregar categoría"}</DialogTitle>
                    <form onSubmit={state.edit? updateData: addData}>
                      <DialogContent>
                        <TextField
                          required={true}
                          autoFocus
                          margin="dense"
                          id="name"
                          name="name"
                          value={state.name}
                          onChange={handleChange}
                          label="Nombre"
                          type="text"
                          fullWidth
                        />
                        <TextField
                          autoFocus
                          margin="dense"
                          id="description"
                          name="description"
                          value={state.description}
                          onChange={handleChange}
                          label="Descripción"
                          type="text"
                          fullWidth
                        />
                        <FormControl fullWidth required>
                          <InputLabel htmlFor="officeAsigned">Área</InputLabel>
                          <Select
                            required
                            native
                            value={state.officeAsigned}
                            onChange={handleChange}
                            inputProps={{
                              name: 'officeAsigned',
                              id: 'officeAsigned',
                            }}
                          >
                            <option value="" />
                              {offices.map(function(value) { return (<option key={value.name} value={value.id} > {value.name}</option> );})}
                          </Select>
                        </FormControl>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose} color="primary">
                          Cancelar
                        </Button>
                        <Button type={"submit"}  color="primary">
                          Guardar
                        </Button>
                      </DialogActions>
                    </form>
                  </Dialog>
                  <GridItem xs={12} sm={12} md={12}>
                    <Card>
                      <CardHeader color="secondary">
                        <h4 className={classes.cardTitleWhite}>Listado de categorías</h4>
                        <p className={classes.cardCategoryWhite}>

                        </p>
                      </CardHeader>
                      <CardBody>
                        <QueueAnim>
                          <DataGrid
                            dataSource={data}
                            showBorders={true}
                            keyExpr="id"
                            allowColumnReordering={true}
                            allowColumnResizing={true}
                            onToolbarPreparing={onToolbarPreparing}>

                            {/* <Export enabled={true} fileName={"Tickets"} texts={{exportAll: "Exportar todos lo datos"}}/>   */}
                            
                            <FilterRow visible={true} applyFilter={"auto"} />
                            
                            <HeaderFilter visible={true} />
                            
                            <GroupPanel visible={true} emptyPanelText={"Arrastre una columna para agrupar"} />
                            
                            <SearchPanel visible={true}
                              width={240}
                              placeholder="Buscar..." />
                            
                            <Paging defaultPageSize={10} />
                            
                            <Pager
                              showPageSizeSelector={true}
                              allowedPageSizes={[5, 10, 20]}
                              showInfo={true} />
                              
                            <Sorting mode="multiple" />

                            <Column
                              dataField="name"
                              caption="Nombre"/>

                            <Column
                              dataField="description"
                              caption="Descripción"/>

                            <Column
                              dataField="department.name"
                              caption="Departamento"/>

                            <Column
                              dataField="enabled"
                              cellRender={renderStatus}
                              caption="Estatus"/>

                            <Column type="buttons"
                              visible={state.displayRole}
                              caption="Acciones"
                              width={80}
                              buttons={[{
                                hint: 'Habilitar/Deshabilitar',
                                icon: 'isblank',
                                onClick: enable
                              }, {
                                hint: 'Editar',
                                icon: 'edit',
                                onClick: handleClickOpen
                            }]}/>
                          </DataGrid>
                        </QueueAnim>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
                )
            }

        </div>


    );
}