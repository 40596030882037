import React, { Component } from 'react';
import './Login.css';
import {
    // GOOGLE_AUTH_URL,
    // FACEBOOK_AUTH_URL,
    ACCESS_TOKEN,
    USER_ID_SESSION,
    USER_ROLES, DEVICE_ID,
    COMPLETE_NAME
} from '../../../../assets/constants';
import { login } from '../../../../util/Contexts/UserService';
import {  Redirect } from 'react-router-dom'
import Alert from 'react-s-alert';
import { TraceSpinner } from "react-spinners-kit";
import {primaryColor, secondaryColor} from "../../../../assets/jss/material-dashboard-react";
import logo from "assets/img/sierra logo 1.svg";
import Swal from "sweetalert2";
// import fbLogo from '../../../../assets/img/fb-logo.png';
// import googleLogo from '../../../../assets/img/google-logo.png';
var loading = false;
class Login extends Component {




    componentDidMount() {
        // If the OAuth2 login encounters an error, the user is redirected to the /login page with an error.
        // Here we display the error and then remove the error query parameter from the location.
        if(this.props.location.state && this.props.location.state.error) {
            setTimeout(() => {
                Alert.error(this.props.location.state.error, {
                    timeout: 5000
                });
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: {}
                });
            }, 100);
        }



    }

    setLoading(loadingState) {
        loading = loadingState;
    }


    
    render() {
        if(this.props.authenticated) {
            return <Redirect
                to={{
                pathname: "/",
                state: { from: this.props.location }
            }}/>;            
        }

        return (
            <div>
                {loading ? (
                        <div style={{
                            position: 'absolute', left: '50%', top: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}>
                            <TraceSpinner

                        size={80}
                        frontColor={primaryColor[0]}
                        backColor={secondaryColor[0]}
                        loading={loading}/>
                        </div>
                    ) : (
                        <div className="login-container">
                            <div className="login-content">
                                {/*<SocialLogin />
                                <div className="or-separator">
                                <span className="or-text">OR</span>
                                 </div>*/}
                                 <LoginForm {...this.props} setLoading={this.setLoading} />
                                 {/*<span className="signup-link">New user? <Link to="/signup">Sign up!</Link></span>*/}
                            </div>
                        </div>
                    ) }
            </div>
        );
    }
}

// class SocialLogin extends Component {
//     render() {
//         return (
//             <div className="social-login">
//                 <a className="btn btn-block social-btn google" href={GOOGLE_AUTH_URL}>
//                     <img src={googleLogo} alt="Google" /> Log in with Google</a>
//                 <a className="btn btn-block social-btn facebook" href={FACEBOOK_AUTH_URL}>
//                     <img src={fbLogo} alt="Facebook" /> Log in with Facebook</a>
//             </div>
//         );
//     }
// }


class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: ''
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;

        this.setState({
            [inputName] : inputValue
        });        
    }

    handleSubmit(event) {
        event.preventDefault();
        this.props.setLoading(true);
        const loginRequest = Object.assign({}, {email: this.state.email, password: this.state.password, webDeviceId: localStorage.getItem(DEVICE_ID)});

        login(loginRequest)
        .then(response => {
            this.props.setLoading(false);
            console.log(response.user.roles)
            if (response.user.roles.map((value => value.name)).includes("USER")){
                this.props.setLoading(false);
                localStorage.setItem(ACCESS_TOKEN, null);
                Alert.error('El usuario y/o la contraseña no coincide.');
            } else {
                localStorage.setItem(ACCESS_TOKEN, response.accessToken);
                localStorage.setItem(USER_ID_SESSION, response.user.id);
                localStorage.setItem(COMPLETE_NAME, response.user.completeName);
                localStorage.setItem(USER_ROLES, response.user.roles.map(value => value.name));
                if (response.user.roles.map(value => value.name).includes("CORPORATIVE")){
                    this.props.history.push("/admin/request");
                }else {
                    this.props.history.push("/");
                }

                window.location.reload();
            }

        }).catch(error => {
            this.props.setLoading(false);
            console.log(error.message);
            if (error.message === "Failed to fetch") {
                Swal.fire({
                    type: 'error',
                    title: 'Oops...',
                    text: 'Algo salío mal, intenta mas tarde!',
                    animation: false,
                    customClass: {
                        popup: 'animated pulse'
                    }
                });
            }else {
                Alert.error((error && error.message));
            }
        });
    }
    
    render() {
        return (
            <div>
                    <img src={logo} alt="logo" className="img" />
                <form onSubmit={this.handleSubmit}>
                        <div className="form-item">
                            <input type="email" name="email"
                                   className="form-control" placeholder="Correo electrónico"
                                   value={this.state.email} onChange={this.handleInputChange} required/>
                        </div>
                        <div className="form-item">
                            <input type="password" name="password"
                                   className="form-control" placeholder="Contraseña"
                                   value={this.state.password} onChange={this.handleInputChange} required/>
                        </div>
                        <div className="form-item">
                            <button type="submit" className="btn btn-block btn-primary">Acceder</button>
                        </div>
                    </form>
                <a href='/sendEmail'><p>¿Olvido la contraseña?</p></a>

            </div>
        );
    }
}

export default Login
