import {API_BASE_URL, ACCESS_TOKEN, USER_ROLES, API_AUTENTICATION_HEADERS} from '../../assets/constants';
import { request } from '../Network/Network';
import axios from "axios";

    export function getCurrentUser(){
        if(!localStorage.getItem(ACCESS_TOKEN)) {
            return Promise.reject("No access token set.");
        }
    
        return request({
            url: API_BASE_URL + "/api/user/session",
            method: 'GET'
        });
    }

    export function login(loginRequest) {
        return request({
            token: false,
            url: API_BASE_URL + "/auth/login",
            method: 'POST',
            body: JSON.stringify(loginRequest)
        });
    }

    export function signup(signupRequest){
        return request({
            url: API_BASE_URL + "/auth/signup",
            method: 'POST',
            body: JSON.stringify(signupRequest)
        });
    }


    export function hasRole(rol) {
        let includesRole = localStorage.getItem(USER_ROLES) ? localStorage.getItem(USER_ROLES).includes(rol) : false;
        return includesRole;
    }
    export function getAllAdmins() {
        const url = API_BASE_URL + "/api/users/admins";
        const headers = API_AUTENTICATION_HEADERS;
        return axios.get(url, {headers});

    }

    export function getUsuarioEmail(email) {
        const url = API_BASE_URL + "/auth/emailValidation"
        const headers = API_AUTENTICATION_HEADERS;
        const userPrincipal = Object.assign({}, {id: null, email: email, password: null, authorities: null});

        return axios.post(url, userPrincipal, {headers});
    }

    export function recoverPassword(newPassword, tokenPassword) {
        const url = API_BASE_URL + "/auth/recoverPassword"
        const headers = API_AUTENTICATION_HEADERS;
        const recoverPasswordRequest = Object.assign({}, {token: tokenPassword, newPassword: newPassword});

        return axios.post(url, recoverPasswordRequest, {headers});
    }


